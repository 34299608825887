import { createGlobalStyle } from "styled-components";

export default createGlobalStyle<{ blockScroll: boolean }>`
  html, body, #root {
    margin: 0;
    height: 100%;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    font-variant-numeric: lining-nums;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }

`;
