import { useController, useFormContext } from "react-hook-form";
import { useFormItem } from "../../../Form";
import Masked, { MaskedInputProps } from "../Masked";

export interface ControlledInputProps extends MaskedInputProps {}

/**
 * A controlled implementation of masked input that uses `react-input-mask`.
 */
const InputMaskedControlled = ({ ...rest }: ControlledInputProps) => {
  const form = useFormContext();
  const { name, rules, setValueAs } = useFormItem();

  const { field, fieldState } = useController({
    name: name,
    control: form.control,
    rules,
    defaultValue: rest.defaultValue || "",
  });

  return (
    <Masked
      id={field.name}
      {...rest}
      {...field}
      onChange={(e) => field.onChange(setValueAs(e.target.value))}
      className={`${fieldState.invalid ? "invalid" : ""}`}
    />
  );
};

export default InputMaskedControlled;
