import React from "react";
// @ts-ignore
import InputMask from "@mona-health/react-input-mask";
import Input, { InputProps } from "../Input";

export interface MaskedInputProps extends InputProps {
  /**
   * The input mask to be applied to this component.
   * [Docs](https://github.com/sanniassin/react-input-mask#mask)
   */
  mask: string | (RegExp | string)[];
  /**
   * Allows manipulating values needing to be masked.
   */
  beforeMaskedStateChange?: (props: {
    currentState: {
      value: string;
    } & MaskSelection;
    nextState: {
      value: string;
      enteredString: string;
    } & MaskSelection;
    previousState: {
      value: string;
    } & MaskSelection;
  }) => {
    value: string;
    enteredString?: string;
  } & MaskSelection;
}

type MaskSelection = {
  selection: {
    start: number;
    end: number;
    length: number;
  };
};

/**
 * An implementation of masked input that uses `react-input-mask`.
 */
const Masked = React.forwardRef<
  HTMLInputElement & {
    Controlled: JSX.Element;
  },
  MaskedInputProps
>(
  (
    {
      mask,
      onBlur,
      onChange,
      beforeMaskedStateChange,
      value,
      ...rest
    }: MaskedInputProps,
    ref: any
  ) => {
    return (
      <>
        <InputMask
          mask={mask}
          alwaysShowMask={false}
          maskPlaceholder=""
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          beforeMaskedStateChange={beforeMaskedStateChange}
          ref={ref}
        >
          <Input {...rest} />
        </InputMask>
      </>
    );
  }
);

export default Masked;
