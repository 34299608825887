import { Event } from "@mojo/analytics";

/**
 * Given an enum of Variants, will return one of them based on an accompanying array of distributions
 * and set a localstorage item to track the result. If a localstorage item already exists, it will return the result
 * stored.
 *
 * This function also fires off an event to GA4 when bucketing is determined.
 *
 * Can be used for bucketing users into experiments in the browser.
 *
 * @example - An 20 / 80 split between 'Control' and 'VariantA'
 * Bucket<Experiment>('experimentId', [Experiment.Control, Experiment.VariantA], [20, 80])
 *
 * @param experimentId An identifier for the experiment, used to set the localStorage key
 * @param variants Array of possible variants
 * @param distribution Array of distribution percentages for the array of variants
 * @constructor
 */
export function Bucket<T extends number>(
  experimentId: string,
  variants: T[],
  distribution: number[]
): T {
  let currentExperiment = GetExperimentState<T>(experimentId);

  if (currentExperiment === null) {
    currentExperiment = DistributeVariants<T>(variants, distribution);
    SetExperimentState(experimentId, currentExperiment);

    Event("experiment_set", {
      experiment_name: experimentId,
      experiment_variant: currentExperiment,
    });
  }

  return currentExperiment;
}

/**
 * Works exactly like the `Bucket` function, but accepts a fourth argument to only run bucketing if it's true.
 */
export function BucketConditionally<T extends number>(
  // @ts-ignore
  ...args: [...Parameters<typeof Bucket>, active: boolean]
): T | null {
  const bucketArgs = args.slice(0, 3) as Parameters<typeof Bucket>;
  const active = args.slice(3, 4)[0] as boolean;

  if (active) {
    return Bucket(...bucketArgs) as T;
  }

  return null;
}

/**
 * Distributes variants based on the given distribution weights and returns a selected variant
 * Unlike `Bucket`, state is not tracked with cookies and can be used for calculating distributions
 * in a backend (e.g. where an API handles a split)
 *
 * @param variants  An array of variants to be distributed
 * @param distribution An array of weights corresponding to the variants' distribution
 * @constructor
 */
export function DistributeVariants<T extends number>(
  variants: T[],
  distribution: number[]
) {
  const totalWeight = distribution.reduce((sum, weight) => sum + weight);
  const randomNum = Math.random() * totalWeight;

  let cumulativeWeight = 0;

  for (let i = 0; i < variants.length; i++) {
    cumulativeWeight += distribution[i];

    if (randomNum < cumulativeWeight) {
      return variants[i];
    }
  }

  // Default variant
  return variants[0];
}

/**
 * Returns the current bucketing state of an experiment. If a user has been bucketed into experiment 'X', it will
 * return the templated Variant value for that experiment. If a user has not been bucketed, it will return null.
 * @param experimentId The experiment Id to check the bucketing state for
 */
export function GetExperimentState<T extends number>(experimentId: string): T | null {
  const experimentCookie = localStorage.getItem(`experiment_${experimentId}`);
  if (!experimentCookie) {
    return null;
  }

  return parseInt(experimentCookie) as T;
}

function SetExperimentState(experimentId: string, variant: number) {
  localStorage.setItem(`experiment_${experimentId}`, String(variant));
}
