import React from "react";
import styled, { css } from "styled-components";
import { BrandProp, Brands } from "@mojo/types";
import { getBrand } from "../Theme/brand";
import { mojoCheckButtonStyles } from "./checkButtonStyles/mojo";
import { uswitchCheckButtonStyles } from "./checkButtonStyles/uswitch";
import { moneyCheckButtonStyles } from "./checkButtonStyles/money";
import { confusedCheckButtonStyles } from "./checkButtonStyles/confused";
import { mojoCheckboxStyles } from "./checkboxStyles/mojo";
import { Guid } from "@mojo/utils";
import { uswitchCheckboxStyles } from "./checkboxStyles/uswitch";
import { confusedCheckboxStyles } from "./checkboxStyles/confused";
import { moneyCheckboxStyles } from "./checkboxStyles/money";

export type CheckProps = React.InputHTMLAttributes<HTMLInputElement> &
  BrandProp & {
    /**
     * Whether the checkbox should be hidden.
     * @default false
     */
    hideControl?: boolean;
    /**
     * Displays the checkbox as a button
     * @default true
     */
    asCheckButton?: boolean;
    value: string;
    children: React.ReactNode;
  };

const CheckButton = React.forwardRef<
  HTMLInputElement & {
    Controlled: JSX.Element;
  },
  CheckProps
>(
  (
    {
      children,
      brand = "Mojo",
      hideControl = false,
      asCheckButton = true,
      name = Guid(),
      ...props
    }: CheckProps,
    ref: any
  ) => {
    const id = props.id ?? `${name}-${props.value}`;
    return (
      <StyledCheck
        $brand={brand}
        $hideControl={hideControl}
        $checkButton={asCheckButton}
      >
        <input type="checkbox" {...props} id={id} ref={ref} />
        <label htmlFor={id} id={`${id}-label`}>
          <span className="check__checkmark" />
          <div className="check__text">{children}</div>
        </label>
      </StyledCheck>
    );
  }
);

export const CheckButtonBrandVariantStyles = (brand: Brands) =>
  getBrand(brand, {
    Mojo: mojoCheckButtonStyles,
    Uswitch: uswitchCheckButtonStyles,
    Money: moneyCheckButtonStyles,
    Confused: confusedCheckButtonStyles,
  });

export const CheckboxBrandVariantStyles = (brand: Brands) =>
  getBrand(brand, {
    Mojo: mojoCheckboxStyles,
    Uswitch: uswitchCheckboxStyles,
    Money: moneyCheckboxStyles,
    Confused: confusedCheckboxStyles,
  });

const StyledCheck = styled.div<{
  $brand: Brands;
  $checkButton: boolean;
  $hideControl: boolean;
}>`
  & {
    position: relative;
  }

  & input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  & label {
    display: flex;
    position: relative;
    align-items: start;
    cursor: pointer;
    height: 100%;

    & .check__checkmark {
      align-self: center;
    }

    & .check__text {
      flex: 1 1 0%;
      line-height: normal;
    }
  }

  ${({ $brand, $checkButton }) =>
    $checkButton
      ? CheckButtonBrandVariantStyles($brand)
      : CheckboxBrandVariantStyles($brand)};

  ${({ $hideControl }) =>
    $hideControl
      ? css`
          & .check__checkmark {
            display: none;
          }
        `
      : ""}
`;

export default CheckButton;
