import useSWR from "swr"
import type { ApiAddress } from "@mojo/types/src/discovery"

import { fetcher } from "./axios"

/**
 * Search for addresses based on postcode
 * @param postcode The postcode to search for
 */
export const usePostcodeAutocomplete = (postcode?: string) => {
  const swr = useSWR<{ addresses: ApiAddress[] }>(() => {
    if (!postcode) throw Error("Postcode is not defined")
    const query = new URLSearchParams({ postcode })
    return `${import.meta.env.VITE_INSURANCE_API_URL}/funnel/address?${query}`
  }, fetcher)

  return { ...swr, data: swr.data?.addresses }
}
