import { css } from "styled-components";

// TODO: finish styling once we have designs
export const uswitchBannerStyles = css`
  & {
    background: ${(props) => props.theme.colors.Uswitch.gray[0]};
    padding:
      ${(props) => props.theme.spacing.md}
      ${(props) => props.theme.spacing.md}
      ${(props) => props.theme.spacing.md}
      ${(props) => props.theme.spacing.base};
    border: 3px solid ${(props) => props.theme.colors.Uswitch.semantic.warning};
    border-left: 9px solid ${(props) => props.theme.colors.Uswitch.semantic.warning};

    .banner__leftIcon {
      color: ${(props) => props.theme.colors.Uswitch.semantic.warning};
    }
  }
`;
