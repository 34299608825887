import "./App.css"
import React, { useEffect } from "react"
import Template, { BasicNav, Main } from "@mojo/blueprint/Template"
import Form from "@mojo/blueprint/Form"
import * as Sentry from "@sentry/react"
import { useForm } from "react-hook-form"
import { css } from "styled-components"
import { lifeInsuranceConfig } from "config/life-insurance"
import FormBuilder from "components/molecules/formBuilder"
import { Route, Switch } from "react-router-dom"
import { useHistory, useLocation } from "react-router-dom"
import { useBrand } from "components/atoms/brandContext"
import { ProtectionFactFind } from "@mojo/types/src/protection"
import FactFindContext from "components/atoms/factFindContext"
import { usePostLifeInsuranceData } from "hooks/data/usePostLifeInsuranceData"
import ResultsPage from "pages/results/results"
import { Analytics } from "@mojo/analytics"
import { insuranceConfig } from "insurance.config"
import Meta from "components/atoms/meta"
import FallbackComponent from "components/atoms/fallbackComponent"
import { CookieBanner } from "components/molecules/cookieBanner/cookieBanner"
import SmartlookClient from "smartlook-client"
import { BucketConditionally } from "@mojo/bucketing"
import {
  OutOfHoursExperiment,
  OutOfHoursExperimentId,
  ShortFactFindExperiment,
  ShortFactFindExperimentId,
} from "./Experiments"
import { outOfHours } from "./utils/outOfHours"

Analytics(insuranceConfig.MEASUREMENT_ID)

function App() {
  const utm_term = new URL(window.location.href).searchParams.get("utm_term") ?? undefined

  const history = useHistory()
  const { brand } = useBrand()
  const form = useForm({
    defaultValues: {
      analytics: {
        utm_term: utm_term,
      },
    } as ProtectionFactFind,
    mode: "onChange",
  })

  const config = React.useMemo(
    () =>
      lifeInsuranceConfig.sections
        .map((section, sectionProgress) =>
          section.steps.map((step, stepProgress) => ({
            sectionName: section.name,
            sectionProgress,
            sectionProgressTotal: lifeInsuranceConfig.sections.filter((s) => s.name !== undefined)
              .length,
            stepProgress,
            stepProgressTotal: section.steps.length - 1,
            onSectionStart: section.onSectionStart,
            onSectionEnd: section.onSectionEnd,
            ...step,
          }))
        )
        .flat(),
    []
  )

  const location = useLocation()

  /**
   * When deployed, results are accessed under `/insurance-discovery`
   * but we don't want that route locally.
   */
  const basePath = React.useMemo(() => {
    if (location.pathname.startsWith("/insurance-discovery")) {
      return "/insurance-discovery"
    }
    return ""
  }, [location])

  const mutation = usePostLifeInsuranceData(`${basePath}`, async (_key: string, { arg }: any) => {
    return await lifeInsuranceConfig.onSubmit(arg, brand, history)
  })

  /**
   * In anything but development this will send user back to the start of the fact find
   * if they start from halfway through the fact find.
   */
  React.useEffect(() => {
    if (
      basePath !== location.pathname &&
      !location.pathname.includes("/results") &&
      import.meta.env.MODE !== "development"
    ) {
      history.replace(basePath)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function SmartlookInit() {
    if (insuranceConfig.SMARTLOOK_PROJECT_KEY) {
      SmartlookClient.init(insuranceConfig.SMARTLOOK_PROJECT_KEY)
      SmartlookClient.track("protection_insurance", {
        utm_term: utm_term ?? "",
      })
    }
  }

  /**
   * This is a bit ugly, but it's to avoid a flash before people are redirected
   * to the short fact find experiment
   *
   * Remove the whole thing after the short fact find experiment is over
   */
  const shortFactFindExperiment = BucketConditionally<ShortFactFindExperiment>(
    ShortFactFindExperimentId,
    [ShortFactFindExperiment.Default, ShortFactFindExperiment.Short],
    [50, 50],
    !outOfHours()
  )

  const [isBucketed, setIsBucketed] = React.useState(false)

  React.useEffect(() => {
    if (shortFactFindExperiment === ShortFactFindExperiment.Short) {
      history.replace(`${basePath}/results/short` + history.location.search)
    }

    setIsBucketed(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shortFactFindExperiment])

  // If we don't bucket into the Short Fact Find, decide whether we want to bucket into Out of Hours at the start of the
  // full fact find.
  // Users shouldn't be bucketed into both the Short Fact Find and Out of Hours stuff.
  useEffect(() => {
    if (shortFactFindExperiment === null) {
      BucketConditionally<OutOfHoursExperiment>(
        OutOfHoursExperimentId,
        [OutOfHoursExperiment.Calendly, OutOfHoursExperiment.CallYouSoon],
        [50, 50],
        outOfHours()
      )
    }
  }, [shortFactFindExperiment])

  if (!isBucketed) {
    return <></>
  }
  /**
   * Ends here
   */

  return (
    <>
      <Meta />
      <CookieBanner onAllowEssential={() => SmartlookInit()} />
      <Switch>
        <Route path={`${basePath}/results`}>
          <ResultsPage />
        </Route>
        <Template
          brand={brand}
          variant="gray"
          style={{
            flex: "1 1 0%",
          }}
        >
          <BasicNav
            brand={brand}
            variant="white"
            style={{
              marginBottom: 0,
            }}
            rightSlot={
              <img src="https://hosted-assets.mojomortgages.com/images/mojo/in-partnership-with-mojo.svg" />
            }
          />
          <Main
            style={{
              flex: "1 1 0%",
            }}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                flex: 1 1 0%;
              `}
            >
              <FactFindContext {...mutation}>
                <Form
                  form={form}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: "1 1 0%",
                  }}
                  onSubmit={(data) => mutation.trigger(data)}
                  // logs changes to form in the console of in development
                  debug={import.meta.env.MODE === "development"}
                >
                  <Sentry.ErrorBoundary fallback={(props) => <FallbackComponent {...props} />}>
                    {config.map((ff) => {
                      return (
                        <Route path={`${basePath}${ff.identifier}`} key={ff.identifier} exact>
                          <FormBuilder {...ff} />
                        </Route>
                      )
                    })}
                  </Sentry.ErrorBoundary>
                </Form>
              </FactFindContext>
            </div>
          </Main>
        </Template>
      </Switch>
    </>
  )
}

export default App
