import * as Sentry from "@sentry/react"
import Template, { BasicNav, Grid, Main } from "@mojo/blueprint/Template"
import Typography from "@mojo/blueprint/Typography"
import Button from "@mojo/blueprint/Button"
import { css } from "styled-components"
import { useFactfind } from "../factFindContext"
import React from "react"

const FallbackComponent = ({ resetError }: Parameters<Sentry.FallbackRender>[0]) => {
  const ffContext = useFactfind()

  React.useEffect(() => {
    ffContext.reset()
  }, [ffContext])

  return (
    <Template
      variant="gray"
      style={{
        flex: "1",
      }}
    >
      <Main
        style={{
          marginTop: "3rem",
        }}
      >
        <Grid
          css={css`
            gap: 3rem;
          `}
        >
          <div
            css={css`
              grid-column-start: 1;
              grid-column-end: -1;

              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            `}
          >
            <Typography.H1>Something went wrong</Typography.H1>
            <Typography.Body>Our engineers have been notified.</Typography.Body>
            <Button
              onClick={() => {
                resetError()
              }}
            >
              Try again
            </Button>
          </div>
        </Grid>
      </Main>
    </Template>
  )
}

export default FallbackComponent
