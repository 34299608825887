import Form from "@mojo/blueprint/Form"
import Input from "@mojo/blueprint/Input/Controlled"
import Select from "@mojo/blueprint/Select/Controlled"
import Typography from "@mojo/blueprint/Typography"
import { useBrand } from "components/atoms/brandContext"
import styled from "styled-components"

/**
 * Renders a form item which allows selecting title, firstname and lastname
 */
const NameAndTitle = () => {
  const { brand } = useBrand()
  const rules = {
    required: {
      value: true,
      message: "This input is required",
    },
  }
  return (
    <StyledNameAndTitle>
      <Typography.H4 as="span" className="name__heading">
        What’s your name?
      </Typography.H4>
      <Form.Item name="details.title" label="Title" rules={rules}>
        <Select placeholder="Select title" brand={brand}>
          <option value="">Select a title</option>
          <option value="Mr">Mr</option>
          <option value="Mrs">Mrs</option>
          <option value="Miss">Miss</option>
          <option value="Ms">Ms</option>
          <option value="Mx">Mx</option>
          <option value="Dr">Dr</option>
          <option value="Professor">Professor</option>
          <option value="Reverend">Reverend</option>
          <option value="Sir">Sir</option>
          <option value="Lord">Lord</option>
          <option value="Other">Other</option>
        </Select>
      </Form.Item>
      <Form.Item name="details.firstName" label="First name" rules={rules}>
        <Input placeholder="First name" brand={brand} />
      </Form.Item>
      <Form.Item name="details.lastName" label="Last name" rules={rules}>
        <Input placeholder="Last name" brand={brand} />
      </Form.Item>
    </StyledNameAndTitle>
  )
}

const StyledNameAndTitle = styled.div`
  .item__label,
  .item__label label,
  .item__labelcontainer {
    margin-bottom: 0 !important;
    font-size: 0 !important;
  }

  .name__heading {
    margin-bottom: ${({ theme }) => theme.spacing.base};
    display: block;
  }
`

export default NameAndTitle
