import { Typography } from "@mojo/blueprint"
import Form from "@mojo/blueprint/Form"
import InputControlled from "@mojo/blueprint/Input/Controlled"
import { useBrand } from "components/atoms/brandContext"
import styled from "styled-components"

const StoneAndPounds = () => {
  const { brand } = useBrand()
  return (
    <>
      <Typography.H4 as="span">We need your weight including wearing clothes</Typography.H4>
      <StyledStoneAndPounds>
        <Form.Item
          name="details.weight.stone"
          label="Stone"
          rules={{
            required: {
              value: true,
              message: "This input is required",
            },
          }}
          setValueAs={(val) => {
            const parsed = parseFloat(val)
            if (Number.isNaN(parsed)) {
              return 0
            }
            return parsed
          }}
        >
          <InputControlled placeholder="6" rightIcon="St" brand={brand} />
        </Form.Item>
        <Form.Item
          name="details.weight.lbs"
          label="Lbs"
          rules={{
            required: {
              value: true,
              message: "This input is required",
            },
          }}
          setValueAs={(val) => {
            const parsed = parseFloat(val)
            if (Number.isNaN(parsed)) {
              return 0
            }
            return parsed
          }}
        >
          <InputControlled placeholder="5" rightIcon="Lbs" brand={brand} />
        </Form.Item>
      </StyledStoneAndPounds>
    </>
  )
}

const StyledStoneAndPounds = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm};

  .form__item {
    flex: 1 1 0%;
  }

  .item__label,
  .item__labelcontainer {
    line-height: 0 !important;
    margin-bottom: 0 !important;
    label {
      font-size: 0 !important;
    }
  }
`

export default StoneAndPounds
