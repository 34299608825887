import { css } from "styled-components";
import RadioIcon from "../assets/money/radio-inactive.svg";
import RadioIconHover from "../assets/money/radio-hover.svg";
import RadioIconActive from "../assets/money/radio-active.svg";

export const moneyRadioButtonStyles = css`
  & {
    position: relative;
  }

  & input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;

    &:checked {
      + label {
        background: ${(props) => props.theme.colors.white};
        box-shadow: 0 0 0 3px
          ${({ theme }) => theme.colors.Money.brand.primary.plum[100]};
        font-weight: 500;

        .radio__checkmark {
          &:after {
            background-image: url("${RadioIconActive}");
          }
        }
      }

      &:focus + label {
        box-shadow: 0 0 0 3px
            ${({ theme }) => theme.colors.Money.brand.primary.plum[100]},
          0 0 0 5px #fea500;
      }
    }

    &:focus + label {
      box-shadow: 0 0 0 2px #fea500;
    }

    &:disabled + label {
      background-color: ${(props) => props.theme.colors.white};
      opacity: 0.5;
    }
  }

  & label {
    display: flex;
    position: relative;
    padding: 16px;
    background: ${(props) => props.theme.colors.white};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.Money.gray[30]};
    align-items: center;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.Money.gray[60]};
    height: 100%;
    border-radius: ${(props) => props.theme.radius.xxs};

    &:hover {
      box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.Money.gray[60]};
      color: ${({ theme }) => theme.colors.black};

      & .radio__checkmark {
        &:after {
          background-image: url("${RadioIconHover}");
        }
      }
    }
  }

  & .radio__checkmark {
    width: 22px;
    height: 22px;
    margin-right: 8px;
    position: relative;
    flex-shrink: 0;

    &:after {
      content: "";
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background-image: url("${RadioIcon}");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .radio__subtext {
    color: ${(props) => props.theme.colors.Money.gray[80]};
    font-weight: initial;
    line-height: 24px;
  }

  & .check__text {
    font-size: 16px;
    font-weight: 500;
  }
`;
