import { css } from "styled-components";

export const uswitchTabsStyles = css`
  & .tabs__list {
    background-color: ${({ theme }) => theme.colors.Uswitch.gray[10]};
  }

  & .tabs__trigger {
    border-radius: 0;
    padding: ${({ theme }) => theme.spacing.sm};

    &[data-state="active"] {
      color: ${({ theme }) => theme.colors.Uswitch.gray[0]};
      background-color: ${({ theme }) => theme.colors.Uswitch.gray[100]};
    }
    &[data-state="inactive"] {
      background-color: transparent;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.Uswitch.gray[80]};
    }
  }
`;
