import { css } from "styled-components";

export const confusedSwitchStyles = css`
  & .switch__label {
    color: rgb(67, 67, 80);
    font-size: 16px;
  }

  & .switch__root {
    all: unset;
    width: 42px;
    height: 25px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: inset 0 0 0 2.5px rgb(20, 20, 36);
    border-radius: 9999px;
    position: relative;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &:focus {
      box-shadow: inset 0 0 0 2.5px rgb(20, 20, 36), 0 0 0 2.5px ${({ theme }) => theme.colors.Confused.primary.darkGray};
      outline: none;
    }

    &[data-state="checked"] {
      background-color: ${({ theme }) => theme.colors.black};
    }

    & .switch__thumb {
      display: block;
      width: 25px;
      height: 25px;
      background-color: ${({ theme }) => theme.colors.white};
      box-shadow: inset 0 0 0 2.5px rgb(20, 20, 36);
      border-radius: 9999px;
      transition: transform 100ms;
      transform: translateX(0px);
      will-change: transform;

      &[data-state="checked"] {
        transform: translateX(19px);
      }
    }

    &:disabled {
      opacity: 0.5;
    }
  }
`;
