import { useController, useFormContext } from "react-hook-form";
import CheckButton, { CheckProps } from "../CheckButton";
import { useFormItem } from "../../Form";

export interface ControlledInputProps extends CheckProps {}

const CheckButtonControlled = ({ ...rest }: ControlledInputProps) => {
  const form = useFormContext();
  const { name, rules, setValueAs } = useFormItem();

  const { field, fieldState } = useController({
    name: name,
    control: form.control,
    rules,
  });

  const isDefaultChecked = (form.getValues(name) || []).includes(
    setValueAs(rest.value)
  );

  return (
    <CheckButton
      {...field}
      onChange={(e) => {
        let value = form.getValues(name) || [];
        const targetValue = setValueAs(e.target.value);

        if (e.target.checked) {
          value.push(targetValue);
        } else {
          value = value.filter((v: string) => {
            return v !== targetValue;
          });
        }

        field.onChange(value);
      }}
      defaultChecked={isDefaultChecked}
      {...rest}
      className={`${fieldState.invalid ? "invalid" : ""}`}
    />
  );
};

export default CheckButtonControlled;
