import { Size, size } from "./size";

export interface Spacing extends Size {
  /**
   * 48px / 3rem
   */
  "2xl": string;
  /**
   * 56px / 3.5rem
   */
  "3xl": string;
  /**
   * 64px / 4rem
   */
  "4xl": string;
  /**
   * 72px / 4.5rem
   */
  "5xl": string;
  /**
   * 96px / 6rem
   */
  "6xl": string;
  /**
   * 120px / 7.5rem
   */
  "7xl": string;
}

export const spacing: Spacing = {
  ...size,
  "2xl": "3rem",
  "3xl": "3.5rem",
  "4xl": "4rem",
  "5xl": "4.5rem",
  "6xl": "6rem",
  "7xl": "7.5rem",
};
