import { css } from "styled-components";

export const confusedInputStyles = css`
  & input {
    & {
      box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.Confused.primary.black};
      color: ${(props) => props.theme.colors.black};
    }

    &:focus {
      box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.Confused.primary.black},
        0 0 0 4px ${({ theme }) => theme.colors.Confused.primary.darkGray};
      outline: none;
    }
  }
`;
