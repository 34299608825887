import { getBrand } from "@mojo/blueprint/Theme"
import { Brands } from "@mojo/types"
import styled, { css } from "styled-components"
import { useBrand } from "../brandContext"

type NoticeProps = {
  children: React.ReactNode
}

const Notice = ({ children }: NoticeProps) => {
  const { brand } = useBrand()
  return <StyledNotice $brand={brand}>{children}</StyledNotice>
}

const BrandVariantStyles = (brand: Brands) =>
  getBrand(brand, {
    Mojo: css`
      border-radius: ${({ theme }) => theme.radius.xs};
      background-color: ${({ theme }) => theme.colors.Mojo.yellow[500]};
    `,
    Uswitch: css`
      background-color: ${({ theme }) => theme.colors.white};
      border: 1px solid ${({ theme }) => theme.colors.Uswitch.gray[10]};
    `,
    Money: css`
      background-color: ${({ theme }) => theme.colors.white};
      border: 1px solid ${({ theme }) => theme.colors.Uswitch.gray[10]};
    `,
  })

const StyledNotice = styled.div<{
  $brand: Brands
}>`
  padding: ${({ theme }) => theme.spacing.md};
  line-height: 150%;

  ${({ $brand }) => BrandVariantStyles($brand)};
`

export default Notice
