import { css } from "styled-components";

export const confusedStepButtonStyles = css`
  & {
    color: ${(props) => props.theme.colors.Confused.primary.white};
    background: ${(props) => props.theme.colors.Confused.primary.black};
    &:disabled {
      background: ${(props) => props.theme.colors.Confused.primary.charcoal};
      cursor: not-allowed;
    }
    &:focus {
      outline: 4px solid ${(props) => props.theme.colors.Confused.primary.darkGray};
    }
  }
`
