import React from "react";
import styled from "styled-components";
import { BrandProp, Brands } from "@mojo/types";
import { getBrand } from "../Theme";
import { mojoBannerStyles } from "./styles/mojo";
import { uswitchBannerStyles } from "./styles/uswitch";
import { moneyBannerStyles } from "./styles/money";
import { confusedBannerStyles } from "./styles/confused";

export type BannerProps = BrandProp & {
  children: React.ReactNode;
  leftIcon?: React.ReactNode | string;
}

export const Banner = ({
  brand = "Mojo",
  children,
  leftIcon,
  ...props
}: BannerProps) => {
  return (
    <StyledBanner
      $brand={brand}
    >
      {leftIcon && (
        <div className="banner__leftIcon">
          {leftIcon}
        </div>
      )}
      <div className="banner__content">
        {children}
      </div>
    </StyledBanner>
  )
}

export const BannerBrandVariantStyles = (brand: Brands) =>
  getBrand(brand, {
    Mojo: mojoBannerStyles,
    Uswitch: uswitchBannerStyles,
    Money: moneyBannerStyles,
    Confused: confusedBannerStyles,
  })

const StyledBanner = styled.div<{
  $brand: Brands;
}>`
  display: flex;
  gap: 12px;
  width: max-content;

  .banner__content {
    > * {
      width: 100%;
    }
  }

  ${({$brand}) => BannerBrandVariantStyles($brand)}
`

export default Banner;
