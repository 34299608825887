import React from "react";
import useMeasure from "react-use-measure";
import styled from "styled-components";
import { useTemplate } from "../useTemplate";

export type MainProps = {
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

/**
 * Main should wrap the content of the page, including grid.
 * Only one Main component should be used per page.
 */
export const Main = (props: MainProps) => {
  const { setMainHeight } = useTemplate();
  const [ref, { height }] = useMeasure();

  React.useLayoutEffect(() => {
    setMainHeight(height);
  }, [height]);

  return <StyledMain ref={ref} {...props} />;
};

const StyledMain = styled.main`
  display: flex;
  justify-content: center;
  width: 100%;
`;
