import { Brands } from "@mojo/types";
import {
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from "styled-components";

export const getBrand = (
  brand: Brands,
  css: Partial<{
    [key in Brands]: FlattenInterpolation<ThemeProps<DefaultTheme>>;
  }>
) => {
  if (!css[brand]) {
    throw new Error(`This component doesn't support the ${brand} brand yet.`);
  }

  return css[brand];
};
