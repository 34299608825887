import { FactFindContextProps, context } from "./context"

/**
 * Provides brand context to its children
 */
const FactFindContext = ({
  children,
  ...props
}: { children: React.ReactNode } & FactFindContextProps) => {
  return <context.Provider value={{ ...props }}>{children}</context.Provider>
}

export default FactFindContext
