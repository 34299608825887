interface ProductVariants {
  finance: string;
  home: string;
  motor: string;
  pet: string;
  life: string;
  travel: string;
  business: string;
  utilities: string;
  carCare: string;
}

export interface ConfusedColors {
  primary: {
    black: string;
    charcoal: string;
    darkGray: string;
    midGray: string;
    lightGray: string;
    white: string;
    red: string;
  };
  product: ProductVariants;
  secondaryProduct: ProductVariants;
}

export const confusedColors: ConfusedColors = {
  primary: {
    black: "#1F1F1F",
    charcoal: "#3C3C3C",
    darkGray: "#8A8A8AFF",
    midGray: "#D4D4D4",
    lightGray: "#EFEFEF",
    white: "#FFFFFF",
    red: "#E61414",
  },
  product: {
    finance: "#FAA73F",
    home: "#F4793B",
    motor: "#A64470",
    pet: "#A64470",
    life: "#A64470",
    travel: "#A64470",
    business: "#A64470",
    utilities: "#A64470",
    carCare: "#A64470",
  },
  secondaryProduct: {
    finance: "#F0B693",
    home: "#82CCBD",
    motor: "#8AC4E9",
    pet: "#C2E8AC",
    life: "#FCDD90",
    travel: "#FFB2B1",
    business: "#BDADE7",
    utilities: "#D2A6DF",
    carCare: "#A7E2F1",
  },
};
