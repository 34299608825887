import React from "react";
import styled, { DefaultTheme, css } from "styled-components";
import { useTemplate } from "../useTemplate";
import useMeasure from "../../utils/useMeasure";
import { BrandProp, Brands } from "@mojo/types";
import { getBrand } from "../../Theme";

type BasicNavProps = {
  variant?: "transparent" | "white";
  /**
   * Will be rendered on the right side, opposite to the logo
   */
  rightSlot?: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement> &
  BrandProp;

/**
 * BasicNav contains simple styles and a logo.
 * It does not include any auth components.
 */
export const BasicNav = ({
  variant = "transparent",
  brand = "Mojo",
  rightSlot,
  ...props
}: BasicNavProps) => {
  const { setNavHeight } = useTemplate();
  const [ref, { height }] = useMeasure();

  React.useLayoutEffect(() => {
    setNavHeight(height);
  }, [height]);

  const logo = React.useMemo(() => {
    switch (brand) {
      default:
      case "Mojo":
        return "https://hosted-assets.mojomortgages.com/images/mojo/mojo-logo.svg";
      case "Uswitch":
        return "https://hosted-assets.mojomortgages.com/images/partners/uswitch-dark.svg";
      case "Money":
        return "https://hosted-assets.mojomortgages.com/images/partners/money-dark.svg";
    }
  }, [brand]);

  return (
    <StyledNav $brand={brand} ref={ref} $variant={variant} {...props}>
      <img src={logo} width={130} />
      {Boolean(rightSlot) && <div>{rightSlot}</div>}
    </StyledNav>
  );
};

const StyledNav = styled.nav<{
  $variant: Required<BasicNavProps["variant"]>;
  $brand: Brands;
}>`
  display: flex;
  justify-content: space-between;

  padding: ${({
    theme: {
      spacing: { base, xl },
    },
  }) => `${base} ${xl}`};
  margin-bottom: ${({ theme }) => theme.spacing.base};

  ${({ $variant, theme, $brand }) => handleBackground($variant, $brand)}
`;

const BrandWhiteBackgroundVariantStyles = (brand: Brands) =>
  getBrand(brand, {
    Mojo: css`
      background-color: ${({ theme }) => theme.colors.white};
      border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[200]};
    `,
    Uswitch: css`
      background-color: ${({ theme }) => theme.colors.white};
      border-bottom: 1px solid ${({ theme }) => theme.colors.Mojo.neutral[200]};
    `,
    Money: css`
      background-color: ${({ theme }) => theme.colors.white};
      border-bottom: 1px solid ${({ theme }) => theme.colors.Mojo.neutral[200]};
    `,
  });

const handleBackground = (
  variant: Required<BasicNavProps["variant"]>,
  $brand: Brands
) => {
  switch (variant) {
    default:
    case "transparent":
      return css`
        background-color: transparent;
      `;
    case "white":
      return css`
        ${BrandWhiteBackgroundVariantStyles($brand)};
      `;
  }
};
