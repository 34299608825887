export interface Breakpoints {
  /**
   * (min-width: 640px)
   */
  sm: string;
  /**
   * (min-width: 768px)
   */
  md: string;
  /**
   * (min-width: 1024px)
   */
  lg: string;
  /**
   * (min-width: 1280px)
   */
  xl: string;
  /**
   * (min-width: 1536px)
   */
  xxl: string;
}

export const sm = "@media (min-width: 640px)";
export const md = "@media (min-width: 768px)";
export const lg = "@media (min-width: 1024px)";
export const xl = "@media (min-width: 1280px)";
export const xxl = "@media (min-width: 1536px)";

export const devices = {
  sm,
  md,
  lg,
  xl,
  xxl,
};
