import React from "react";
import styled from "styled-components";
import { BrandProp, Brands } from "@mojo/types";
import { getBrand } from "../Theme/brand";
import { mojoSelectStyles } from "./styles/mojo";
import { uswitchSelectStyles } from "./styles/uswitch";
import { moneySelectStyles } from "./styles/money";
import { confusedSelectStyles } from "./styles/confused";

export type SelectProps = React.InputHTMLAttributes<HTMLSelectElement> &
  BrandProp & {};

const Select = React.forwardRef<HTMLInputElement, SelectProps>(
  ({ brand = "Mojo", ...props }: SelectProps, ref: any) => (
    <StyledSelect $brand={brand} {...props} ref={ref} />
  )
);

export const BrandVariantStyles = (brand: Brands) =>
  getBrand(brand, {
    Mojo: mojoSelectStyles,
    Uswitch: uswitchSelectStyles,
    Money: moneySelectStyles,
    Confused: confusedSelectStyles,
  });

const StyledSelect = styled.select<{
  $brand: Brands;
}>`
  ${({ $brand }) => BrandVariantStyles($brand)};
`;

export default Select;
