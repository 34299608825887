import {useEffect, useRef, useState} from "react"
import {createPortal} from "react-dom"
import styled from "styled-components"
import {Button} from "@mojo/blueprint";
import Checkbox from "@mojo/blueprint/Checkbox";
import {ButtonType} from "@mojo/blueprint/Button";
import {useBrand} from "components/atoms/brandContext";

export const space = 16
export const mobile = "@media (max-width: 767px)"
export const tablet = "@media (min-width: 768px) and (max-width: 1023px)"
export const desktop = "@media (min-width: 1024px)"

// The padding for the center column elements.
export const horizontalPadding = {
    mobile: 24,
    tablet: 40,
    desktop: 40,
}

const Background = styled.div({
    alignItems: "center",
    backgroundColor: "#00000033",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    transform: "translateZ(0)",
    width: "100%",
    zIndex: 1002,
})

const Banner = styled.div(({ theme }) => ({
    backgroundColor: theme.colors.white,
    bottom: 0,
    display: "grid",
    position: "absolute",
    width: "100%",
    [mobile]: {
        gridTemplateColumns: "repeat(3, 1fr)",
        padding: `0px ${horizontalPadding.mobile}px`,
    },
    [tablet]: {
        gridTemplateColumns: "repeat(6, 1fr)",
        padding: `0px ${horizontalPadding.tablet}px`,
    },
    [desktop]: {
        gridTemplateColumns: "repeat(12, 1fr)",
        padding: `0px ${horizontalPadding.desktop}px`,
    },
}))

const ContentContainer = styled.div({
    padding: "2rem",
    [mobile]: {
        gridColumnStart: 1,
        gridColumnEnd: "span 3",
    },
    [tablet]: {
        gridColumnStart: 2,
        gridColumnEnd: "span 4",
    },
    [desktop]: {
        gridColumnStart: 4,
        gridColumnEnd: "span 6",
    },
})

const CheckBoxContainer = styled.div({
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
})

const ButtonRow = styled.div({
    display: "flex",
    justifyContent: "space-between",
    gap: "2rem",
    [mobile]: {
        flexDirection: "column",
    },
    [tablet]: {
        flexDirection: "column",
    },
    [desktop]: {
        flexDirection: "row",
    },
})

const Content = ({ onSettingsSaved }: { onSettingsSaved: (essential: boolean, marketing: boolean) => void }) => {
    const { brand } = useBrand()
    const [allowMarketing, setAllowMarketing] = useState(false)

    const toggle = () => setAllowMarketing(!allowMarketing)

    return (
        <ContentContainer>
            <h2>Your cookie preferences</h2>
            <p>
                We use cookies and similar technologies. You can use the settings below to accept all
                cookies (which we recommend to give you the best experience) or to enable specific
                categories of cookies as explained below. Find out more by reading our{" "}
                <a
                    href={"https://mojomortgages.com/cookies"}
                    target="_blank" rel="noreferrer"
                >
                    Cookies Policy
                </a>
                .
            </p>
            <ButtonRow>
                <CheckBoxContainer>
                    <Checkbox
                        brand={brand}
                        checked={true}
                        readOnly={true}
                        id="essential-cookies"
                        onChange={() => {
                            /* no-op */
                        }}
                        value={"essential-cookies"}
                        aria-describedby=""
                    >
                        Essential Cookies
                    </Checkbox>
                    <Checkbox
                        brand={brand}
                        checked={allowMarketing}
                        id="marketing-cookies"
                        onChange={toggle}
                        // onEnter={toggle}
                        value={"marketing-cookies"}
                        aria-describedby=""
                    >
                        Allow Marketing Cookies
                    </Checkbox>
                </CheckBoxContainer>
                    <Button
                        brand={brand}
                        onClick={() => onSettingsSaved(true, allowMarketing)}
                        variant={ButtonType.Secondary}
                        id="save-cookie-preferences"
                    >
                        Save Preferences
                    </Button>
                    <Button
                        brand={brand}
                        onClick={() => onSettingsSaved(true, true)}
                        id="accept-cookies"
                    >
                        Accept All
                    </Button>
            </ButtonRow>
        </ContentContainer>
    )
}

const oneYearInSeconds = 31556926

type CookieBannerProps = {
    onAllowEssential?: () => void
}

export const CookieBanner = ({ onAllowEssential }: CookieBannerProps) => {
    const essentialCallbackInvoked = useRef(false);

    const [allowEssential, setAllowEssential] = useState((readCookie('allow-essential') ?? 'false') === 'true');
    const [isOpen, setIsOpen] = useState(!allowEssential);

    useEffect(() => {
        if (isOpen) {
            const html = document.querySelector("html")
            if (html !== null) html.style.overflow = "hidden"
        } else {
            const html = document.querySelector("html")
            if (html !== null) html.style.overflow = "auto"
        }
    }, [isOpen])

    useEffect(() => {
        if (allowEssential) {
            setCookie('allow-essential', 'true', oneYearInSeconds);
            setIsOpen(false);

            if (onAllowEssential && !essentialCallbackInvoked.current) {
                onAllowEssential();
                essentialCallbackInvoked.current = true;
            }
        }
    }, [allowEssential, onAllowEssential]);

    function setAllowMarketing(allowMarketing: boolean) {
        setCookie('allow-marketing', allowMarketing.toString(), oneYearInSeconds);
    }

    return isOpen
        ? createPortal(
            <Background aria-label="Cookie Banner" aria-modal="true" role="dialog" tabIndex={-1}>
                <Banner>
                    <Content onSettingsSaved={(essential, marketing) => {
                        setAllowEssential(essential);
                        setAllowMarketing(marketing)
                    }} />
                </Banner>
            </Background>,
            document.body
        )
        : null
}

const readCookie = (key: string): string | undefined =>
    document.cookie
        .split("; ")
        .find((row) => row.startsWith(`${key}=`))
        ?.split("=")[1]

const setCookie = (key: string, value: string, expirySeconds: number) => {
    const now = new Date()
    const ms = expirySeconds * 1000
    const expireTime = new Date(now.getTime() + ms)
    document.cookie = `${key}=${value}; expires=${expireTime.toUTCString()}`
}
