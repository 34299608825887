import { createGlobalStyle, css } from "styled-components"
import { useBrand } from "../brandContext"
import { getBrand } from "@mojo/blueprint/Theme"
import { Brands } from "@mojo/types"

/**
 * Sets global styles for the application.
 */
const GlobalStyle = () => {
  const { brand } = useBrand()
  return <StyledGlobalStyle $brand={brand} />
}

const BrandVariantStyles = (brand: Brands) =>
  getBrand(brand, {
    Mojo: css`
      @font-face {
        font-family: TT Wellingtons;
        src: url("https://hosted-assets.mojomortgages.com/fonts/TT_Wellingtons_Black.otf")
          format("opentype");
        font-weight: 900;
        font-display: swap;
      }

      @font-face {
        font-family: TT Wellingtons;
        src: url("https://hosted-assets.mojomortgages.com/fonts/TT_Wellingtons_ExtraBold.otf")
          format("opentype");
        font-weight: 800;
        font-display: swap;
      }

      @font-face {
        font-family: TT Wellingtons;
        src: url("https://hosted-assets.mojomortgages.com/fonts/TT_Wellingtons_Bold.otf")
          format("opentype");
        font-weight: 700;
        font-display: swap;
      }

      @font-face {
        font-family: TT Wellingtons;
        src: url("https://hosted-assets.mojomortgages.com/fonts/TT_Wellingtons_DemiBold.otf")
          format("opentype");
        font-weight: 600;
        font-display: swap;
      }

      @font-face {
        font-family: TT Wellingtons;
        src: url("https://hosted-assets.mojomortgages.com/fonts/TT_Wellingtons_Medium.otf")
          format("opentype");
        font-weight: 500;
        font-display: swap;
      }

      @font-face {
        font-family: TT Wellingtons;
        src: url("https://hosted-assets.mojomortgages.com/fonts/TT_Wellingtons_Regular.otf")
          format("opentype");
        font-weight: 400;
        font-display: swap;
      }

      @font-face {
        font-family: TT Wellingtons;
        src: url("https://hosted-assets.mojomortgages.com/fonts/TT_Wellingtons_Light.otf")
          format("opentype");
        font-weight: 300;
        font-display: swap;
      }

      body {
        font-family: "TT Wellingtons", Arial, sans-serif;
        color: ${(props) => props.theme.colors.primary.two};
      }
    `,
    Uswitch: css`
      @font-face {
        font-family: TT Wellingtons;
        src: url("https://hosted-assets.mojomortgages.com/fonts/TT_Wellingtons_Black.otf")
          format("opentype");
        font-weight: 900;
        font-display: swap;
      }

      @font-face {
        font-family: TT Wellingtons;
        src: url("https://hosted-assets.mojomortgages.com/fonts/TT_Wellingtons_ExtraBold.otf")
          format("opentype");
        font-weight: 800;
        font-display: swap;
      }

      @font-face {
        font-family: TT Wellingtons;
        src: url("https://hosted-assets.mojomortgages.com/fonts/TT_Wellingtons_Bold.otf")
          format("opentype");
        font-weight: 700;
        font-display: swap;
      }

      @font-face {
        font-family: TT Wellingtons;
        src: url("https://hosted-assets.mojomortgages.com/fonts/TT_Wellingtons_DemiBold.otf")
          format("opentype");
        font-weight: 600;
        font-display: swap;
      }

      @font-face {
        font-family: TT Wellingtons;
        src: url("https://hosted-assets.mojomortgages.com/fonts/TT_Wellingtons_Medium.otf")
          format("opentype");
        font-weight: 500;
        font-display: swap;
      }

      @font-face {
        font-family: TT Wellingtons;
        src: url("https://hosted-assets.mojomortgages.com/fonts/TT_Wellingtons_Regular.otf")
          format("opentype");
        font-weight: 400;
        font-display: swap;
      }

      @font-face {
        font-family: TT Wellingtons;
        src: url("https://hosted-assets.mojomortgages.com/fonts/TT_Wellingtons_Light.otf")
          format("opentype");
        font-weight: 300;
        font-display: swap;
      }

      .mojo__font,
      .mojo__font h1,
      .mojo__font h2,
      .mojo__font h3,
      .mojo__font h4,
      .mojo__font h5,
      .mojo__font h6 {
        font-family: "TT Wellingtons", Arial, sans-serif;
      }

      body {
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu,
          Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Arial, sans-serif;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: Helvetica Now Display, Helvetica Neue, Helvetica, Arial, -apple-system,
          BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Fira Sans,
          Droid Sans, sans-serif;
      }
    `,
    Money: css`
      @font-face {
        font-family: TT Wellingtons;
        src: url("https://hosted-assets.mojomortgages.com/fonts/TT_Wellingtons_Black.otf")
          format("opentype");
        font-weight: 900;
        font-display: swap;
      }

      @font-face {
        font-family: TT Wellingtons;
        src: url("https://hosted-assets.mojomortgages.com/fonts/TT_Wellingtons_ExtraBold.otf")
          format("opentype");
        font-weight: 800;
        font-display: swap;
      }

      @font-face {
        font-family: TT Wellingtons;
        src: url("https://hosted-assets.mojomortgages.com/fonts/TT_Wellingtons_Bold.otf")
          format("opentype");
        font-weight: 700;
        font-display: swap;
      }

      @font-face {
        font-family: TT Wellingtons;
        src: url("https://hosted-assets.mojomortgages.com/fonts/TT_Wellingtons_DemiBold.otf")
          format("opentype");
        font-weight: 600;
        font-display: swap;
      }

      @font-face {
        font-family: TT Wellingtons;
        src: url("https://hosted-assets.mojomortgages.com/fonts/TT_Wellingtons_Medium.otf")
          format("opentype");
        font-weight: 500;
        font-display: swap;
      }

      @font-face {
        font-family: TT Wellingtons;
        src: url("https://hosted-assets.mojomortgages.com/fonts/TT_Wellingtons_Regular.otf")
          format("opentype");
        font-weight: 400;
        font-display: swap;
      }

      @font-face {
        font-family: TT Wellingtons;
        src: url("https://hosted-assets.mojomortgages.com/fonts/TT_Wellingtons_Light.otf")
          format("opentype");
        font-weight: 300;
        font-display: swap;
      }

      .mojo__font,
      .mojo__font h1,
      .mojo__font h2,
      .mojo__font h3,
      .mojo__font h4,
      .mojo__font h5,
      .mojo__font h6 {
        font-family: "TT Wellingtons", Arial, sans-serif;
      }

      @font-face {
        font-family: Nunito;
        src: url("https://hosted-assets.mojomortgages.com/fonts/Nunito.woff2") format("woff2");
        font-display: swap;
      }

      body {
        font-family: Nunito, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", "Segoe WP",
          Ubuntu, sans-serif;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: Nunito, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", "Segoe WP",
          Ubuntu, sans-serif;
      }
    `,
  })

const StyledGlobalStyle = createGlobalStyle<{
  $brand: Brands
}>`
  ${({ $brand }) => BrandVariantStyles($brand)};

  #root {
    display: flex;
    min-height: 100%;
    height: auto;
  }
`

export default GlobalStyle
