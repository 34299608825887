import * as SwitchPrimitive from "@radix-ui/react-switch";
import React from "react";
import styled from "styled-components";
import { BrandProp, Brands } from "@mojo/types";
import { getBrand } from "../Theme/brand";
import { mojoSwitchStyles } from "./styles/mojo";
import { uswitchSwitchStyles } from "./styles/uswitch";
import { moneySwitchStyles } from "./styles/money";
import { confusedSwitchStyles } from "./styles/confused";

export type SwitchProps = SwitchPrimitive.SwitchProps &
  BrandProp & {
    children: React.ReactNode;
  };

/**
 * An accessible Switch component built with Radix UI.
 * For documentation, read Radix docs https://www.radix-ui.com/docs/primitives/components/switch.
 */
const Switch = ({
  children,
  brand = "Mojo",
  className,
  style,
  ...props
}: SwitchProps) => {
  const id = React.useId();
  return (
    <StyledSwitch
      $brand={brand}
      style={{ display: "flex", alignItems: "center", ...style }}
      className={className}
    >
      <label
        className="switch__label"
        htmlFor={`switch-${props.name || id}`}
        style={{ paddingRight: 16 }}
      >
        {children}
      </label>
      <SwitchPrimitive.Root
        className="switch__root"
        id={`switch-${props.name || id}`}
        {...props}
      >
        <SwitchPrimitive.Thumb className="switch__thumb" />
      </SwitchPrimitive.Root>
    </StyledSwitch>
  );
};

export const BrandVariantStyles = (brand: Brands) =>
  getBrand(brand, {
    Mojo: mojoSwitchStyles,
    Uswitch: uswitchSwitchStyles,
    Money: moneySwitchStyles,
    Confused: confusedSwitchStyles,
  });

export const StyledThumb = styled(SwitchPrimitive.Thumb)``;

const StyledSwitch = styled.div<{
  $brand: Brands;
}>`
  ${({ $brand }) => BrandVariantStyles($brand)};

  .switch__root {
    cursor: pointer;
  }

  .switch__label {
    font-weight: 600;
  }
`;

export default Switch;
