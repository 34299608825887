import Typography from "@mojo/blueprint/Typography"
import Button from "@mojo/blueprint/Button"
import Form from "@mojo/blueprint/Form"
import Input from "@mojo/blueprint/Input/Controlled"
import Checkbox from "@mojo/blueprint/Checkbox/Controlled"
import styled, { css, useTheme } from "styled-components"
import { aig, aviva, legalAndGeneral, lv, royalLondon, vitality } from "assets/insurers"
import React from "react"
import { useBrand } from "components/atoms/brandContext"
import ResultPage from "components/organisms/result"
import BulletPoint from "components/atoms/bulletpoint"
import { useHistory } from "react-router-dom"
import { usePostLifeInsuranceDataShort } from "hooks/data/usePostLifeInsuranceDataShort"
import { useForm } from "react-hook-form"
import { Event } from "@mojo/analytics"

const ShortResultsPage = () => {
  const history = useHistory()
  const form = useForm({
    defaultValues: {
      phoneNumber: "",
    },
  })
  const { brand } = useBrand()
  const { trigger, data, isMutating } = usePostLifeInsuranceDataShort()

  const themeHook = useTheme()
  const getTextColor = () => {
    switch (brand) {
      default:
      case "Uswitch":
        return css`
          color: ${themeHook.colors.Mojo.primary.two};
        `
      case "Money":
        return css`
          color: ${themeHook.colors.Money.gray[100]};
        `
    }
  }

  React.useEffect(() => {
    Event("Journey Started - Short", {
      action: "Journey Started - Short",
      event_category: "Site Interaction",
      event_label: "Journey Started - Short",
    })
  }, [])

  return (
    <ResultPage
      headerContent={
        <div
          css={css`
            margin: 2rem 0;
          `}
        >
          <div className="mainResult__header--heading">
            <Typography.H1>Get a quote now</Typography.H1>
          </div>
          <div className="mainResult__header--headingText">
            <Typography.Body>
              Please provide your phone number. We’ll only contact you about your application.
            </Typography.Body>
            <Form
              form={form}
              onSubmit={async ({ phoneNumber }) => {
                Event("Button Clicked", {
                  action: "Button Clicked",
                  event_category: "Functional Interaction",
                  event_label: "Submit",
                })

                await trigger(phoneNumber)
                history.push(window.location.pathname.split("/").slice(0, -1).join("/") + "/main")
              }}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;

                  & .item__labelcontainer {
                    font-size: 0;
                    height: 0;
                    & label {
                      font-size: 0;
                      height: 0;
                    }
                  }

                  & button {
                    width: 100%;
                  }

                  & a {
                    color: inherit;
                    text-decoration: underline;
                  }

                  ${({ theme }) => theme.breakpoints.lg} {
                    & button {
                      width: auto;
                      align-self: flex-end;
                    }
                  }

                  & .check__text {
                    ${getTextColor()}
                  }
                `}
              >
                <Form.Item
                  label="Phone number"
                  name="phoneNumber"
                  rules={{
                    required: {
                      value: true,
                      message: "This input is required",
                    },
                    pattern: {
                      value: /^(\d{9,11})$/,
                      message: "Please use a valid phone number.",
                    },
                  }}
                >
                  <Input brand={brand} leftIcon={"+44"} type="tel" />
                </Form.Item>
                <Form.Item
                  label="Terms and Conditions"
                  name="termsAndConditions"
                  rules={{
                    required: {
                      value: true,
                      message: "This input is required",
                    },
                  }}
                >
                  <Checkbox brand={brand} value="true">
                    I agree to Mojo's{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://hosted-assets.mojomortgages.com/documents/Terms%20of%20our%20Advisory%20Services.pdf"
                    >
                      Terms of Our Advisory Services
                    </a>{" "}
                    and{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://mojomortgages.com/privacy-policy/"
                    >
                      Privacy Policy
                    </a>
                  </Checkbox>
                </Form.Item>
                <Button brand={brand} type="submit" isLoading={isMutating} disabled={isMutating}>
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </div>
      }
    >
      <StyledModal>
        <ResultPage.Modal>
          <div className="mainResult__modal--illustration">
            <img
              width={160}
              src="https://hosted-assets.mojomortgages.com/images/mojo/mojo-logo.svg"
            />
            <img
              width={"85%"}
              src="https://hosted-assets.mojomortgages.com/images/illustrations/bike.png"
            />
          </div>

          <div className="mainResult__modal--howItWorks">
            <Typography.H3>How it works</Typography.H3>
            <Typography.Body>Your expert will:</Typography.Body>
            <ol>
              <BulletPoint number={1}>Explore your needs and circumstances.</BulletPoint>
              <BulletPoint number={2}>
                Find you the right insurance and make a recommendation.
              </BulletPoint>
              <BulletPoint number={3}>Handle the application for you.</BulletPoint>
            </ol>
          </div>

          <div className="mainResult__modal--weWorkWith">
            <Typography.H3>Who we work with</Typography.H3>
            <Typography.Body>We compare policies from 15 insurers.</Typography.Body>
            <div className="mainResult__modal--insurerGrid">
              <img src={aig} />
              <img src={aviva} />
              <img src={legalAndGeneral} />
              <img src={lv} />
              <img src={royalLondon} />
              <img src={vitality} />
            </div>
          </div>
        </ResultPage.Modal>
      </StyledModal>
    </ResultPage>
  )
}

const StyledModal = styled.div`
  .mainResult__modal--container {
    padding: ${({ theme }) => theme.spacing.md};

    ${({ theme }) => theme.breakpoints.lg} {
      padding: ${({
        theme: {
          spacing: { xl, "3xl": threeXl },
        },
      }) => `${xl} ${threeXl}`};
    }
  }

  .mainResult__modal--illustration {
    grid-column: 1 / 1;
    grid-row: 1 / 3;

    display: none;

    ${({ theme }) => theme.breakpoints.lg} {
      display: flex;
    }

    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    img {
      max-width: 100%;
    }
  }

  .mainResult__modal--howItWorks {
    ol {
      display: flex;
      flex-direction: column;
      gap: ${({ theme }) => theme.spacing.base};

      padding-left: 0;

      li {
        list-style-type: none;
      }
    }
  }

  .mainResult__modal--weWorkWith {
    .mainResult__modal--insurerGrid {
      margin-top: ${({ theme }) => theme.spacing.lg};

      display: grid;
      grid-template-columns: repeat(3, minmax(0px, 1fr));
      gap: ${({ theme }) => theme.spacing.lg};
    }
  }
`

export default ShortResultsPage
