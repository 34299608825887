import React from "react"
import styled from "styled-components"

export type ProgressBarProps = {
  /**
   * Name of the section
   */
  sectionName?: string
  /**
   * Progress through the current section
   */
  sectionProgress?: number
  /**
   * How many sections there are in total
   */
  sectionProgressTotal?: number
  /**
   * Progress through the current section's steps
   */
  stepProgress?: number
  /**
   * How many steps in the current section
   */
  stepProgressTotal?: number
}

const ProgressBar = ({
  sectionName = "",
  sectionProgress = 0,
  sectionProgressTotal = 0,
  stepProgress = 0,
  stepProgressTotal = 0,
}: ProgressBarProps) => {
  const progressPercentage = React.useMemo(() => {
    return Math.trunc((stepProgress / stepProgressTotal) * 100)
  }, [stepProgress, stepProgressTotal])

  if (sectionName === "") {
    return <></>
  }

  return (
    <StyledProgressBar>
      <div className="progressBar__nameAndProgress">
        <div className="progressBar__nameAndProgress--name">
          {sectionProgress}. {sectionName}
        </div>
        <div className="progressBar__nameAndProgress--progress">{progressPercentage}%</div>
      </div>
      <div className="progressBar__bars">
        {Array.from(Array(sectionProgressTotal)).map((_section, sectionIndex) => (
          <div
            data-sectioncompleted={sectionIndex + 1 < sectionProgress}
            data-activesection={sectionIndex + 1 === sectionProgress}
            data-sectionindex={sectionIndex}
            key={sectionIndex}
            className="progressBar__bar"
          >
            {Array.from(Array(stepProgressTotal)).map((_step, stepIndex) => (
              <div
                key={stepIndex}
                className="progressBar__step"
                data-stepcompleted={sectionIndex + 1 < sectionProgress || stepIndex < stepProgress}
                data-stepindex={stepIndex}
                // data-currently-active={i + 1 === sectionProgress}
              />
            ))}
          </div>
        ))}
      </div>
    </StyledProgressBar>
  )
}

const StyledProgressBar = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm};
  padding: ${({ theme }) => theme.spacing.md} 0;

  .progressBar__nameAndProgress {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.neutral[500]};

    .progressBar__nameAndProgress--name {
      text-transform: uppercase;
    }
  }

  .progressBar__bars {
    display: flex;
    gap: ${({ theme }) => theme.spacing.xs};

    .progressBar__bar {
      display: flex;
      overflow: hidden;
      flex: 1 1 0%;
      height: 8px;
      border-radius: 200px;

      background-color: ${({ theme }) => theme.colors.Uswitch.gray[30]};

      &[data-sectioncompleted="true"] {
        background-color: ${({ theme }) => theme.colors.Uswitch.semantic.warning};
      }

      &[data-activesection="true"] {
        .progressBar__step {
          &[data-stepcompleted="true"] {
            margin-left: -5px;
            background-color: ${({ theme }) => theme.colors.Uswitch.semantic.warning};
          }
        }
      }

      .progressBar__step {
        flex: 1 1 0%;
        height: 8px;

        border-radius: 200px;
      }
    }
  }
`

export default ProgressBar
