import { css } from "styled-components";
import Caret from "../assets/caret.svg";

export const confusedSelectStyles = css`
  & {
    border: 1px solid transparent;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.Confused.primary.black};
    color: ${({ theme }) => theme.colors.Confused.primary.black};
    display: block;
    width: 100%;
    height: 100%;
    padding: 1rem 3rem 1rem 1rem;
    font-weight: 500;
    box-sizing: border-box;
    outline: none;
    background-color: ${(props) => props.theme.colors.white};
    background-image: url("${Caret}");
    background-position: right 20px top calc(50% - 2px);
    background-repeat: no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";

    & option:checked + select {
      background-color: black;
    }
  }

  &:disabled {
    opacity: 0.7;
  }

  &.invalid {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.error[500]};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.neutral[500]};
  }

  &:focus {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.Confused.primary.black},
      0 0 0 4px ${({ theme }) => theme.colors.Confused.primary.darkGray};
    outline: none;

    .invalid {
      box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.error[500]};
    }
  }
`;
