import * as Sentry from "@sentry/react"
import { version, name } from "../../package.json"

export default () => {
  if (import.meta.env.VITE_SENTRY_ENVIRONMENT === "production") {
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      release: `${name}@${version}`,
      integrations: [new Sentry.BrowserTracing()],
      tracePropagationTargets: [
        "localhost:4000",
        "mojo-appointments-v2.test.mojo.xyz",
        "mojo-appointments-v2.mojomortgages.com",
      ],
      tracesSampleRate: 0.1,
      environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    })
  }
}
