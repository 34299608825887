import { css } from "styled-components";

export const uswitchButtonStyles = css`
  & {
    padding: 0px;
    box-sizing: border-box;
    text-align: center;
    position: relative;
    transition: width 0.7s;
    cursor: pointer;
    user-select: none;
    border: 0;
    outline: 0;
  }

  &:focus {
    box-shadow: 0 0 0 2px #fea500;
  }

  & .button__internal {
    display: flex;
    align-content: center;
    justify-content: center;
    border-radius: 10px;
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.primary {
    background: rgb(20, 20, 36);
    color: rgb(255, 255, 255);
    font-weight: 550;

    &:hover {
      background: rgb(67, 67, 80);
    }

    &.disabled {
      background: rgb(208, 208, 211);
    }
  }

  &.secondary {
    background: rgb(243, 243, 244);
    color: rgb(20, 20, 36);
    font-weight: 550;

    &:hover {
      background: rgb(208, 208, 211);
    }

    &.disabled {
      color: rgb(161, 161, 167);
      background-color: rgb(243, 243, 244);
    }
  }

  &.tertiary {
    background: rgb(255, 255, 255);
    color: rgb(20, 20, 36);
    font-weight: 550;

    &:hover {
      background: rgb(243, 243, 244);
    }

    &:disabled {
      color: rgb(161, 161, 167);
    }
  }

  &.link {
    background: transparent;
    color: hsla(240, 29%, 11%, 1);
    text-decoration: underline;
    font-weight: 500;

    &:hover {
      color: rgb(96 96 120);
    }

    &:disabled {
      color: rgb(161, 161, 167);
    }
  }

  &.answer {
    background: ${(props) => props.theme.colors.white};
    box-shadow: 0 0 0 1px rgb(208, 208, 211);
    color: rgb(67, 67, 80);
    font-weight: 500;

    &.base .button__internal {
      justify-content: space-between;
      padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.base}`};
    }

    &:hover {
      background: rgb(243, 243, 244);
    }

    &:focus {
      box-shadow: 0 0 0 2px #fea500;
    }

    &:active {
      background: ${(props) => props.theme.colors.white};
      box-shadow: 0 0 0 2px #fea500;
    }
  }

  // Size classes, change paddings and font size depending on prop

  &.base .button__internal {
    font-size: 1rem;
    padding: 1.2rem 2rem;
    padding: 1.2rem 2rem;
  }
  &.small .button__internal {
    font-size: 0.75rem;
    padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.md}`};
  }
`;
