export enum ShortFactFindExperiment {
    Default,
    Short,
}

export enum OutOfHoursExperiment {
    CallYouSoon,
    Calendly,
}

export const ShortFactFindExperimentId = "d2c-short-fact-find";
export const OutOfHoursExperimentId = "d2c-out-of-hours-booking";