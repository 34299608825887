import CheckButtonControlled from "../../CheckButton/Controlled";
import { CheckboxProps } from "../Checkbox";

export interface ControlledInputProps extends CheckboxProps {}

/**
 * A styled and controlled checkbox component.
 */
const CheckboxControlled = (props: ControlledInputProps) => {
  return <CheckButtonControlled asCheckButton={false} {...props} />;
};

export default CheckboxControlled;
