import { css } from "styled-components";

// TODO: add #FB7123 (orange) and it's shades to the colour palette in themes, when it becomes available here:
// https://www.figma.com/file/9Cn1J9n6MbrL5iIM4PqnUV/1.-Core-styles?type=design&node-id=30-7&mode=design&t=oUOTjUaZFlymTFD2-0
export const mojoBannerStyles = css`
  & {
    background: ${(props) => props.theme.colors.Mojo.white};
    padding:
      ${(props) => props.theme.spacing.md}
      ${(props) => props.theme.spacing.md}
      ${(props) => props.theme.spacing.md}
      ${(props) => props.theme.spacing.base};
    border-radius: ${(props) => props.theme.radius.base};
    border: 3px solid #FB7123;
    border-left: 9px solid #FB7123;

    .banner__leftIcon {
      color: #FB7123;
    }
  }
`;
