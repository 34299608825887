import { css } from "styled-components";

// TODO: finish styling once we have designs
export const confusedBannerStyles = css`
& {
  background: ${(props) => props.theme.colors.Confused.primary.white};
  padding:
    ${(props) => props.theme.spacing.md}
    ${(props) => props.theme.spacing.md}
    ${(props) => props.theme.spacing.md}
    ${(props) => props.theme.spacing.base};
  border: 3px solid ${(props) => props.theme.colors.Confused.secondaryProduct.finance};
  border-left: 9px solid ${(props) => props.theme.colors.Confused.secondaryProduct.finance};

  .banner__leftIcon {
    color: ${(props) => props.theme.colors.Confused.secondaryProduct.finance};
  }
}
`;
