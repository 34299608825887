import { css } from "styled-components";
import CheckIcon from "../assets/confused/check-inactive.svg";
import CheckIconActive from "../assets/confused/check-active.svg";

export const confusedCheckboxStyles = css`
  & input {
    &:checked {
      + label {
        font-weight: 600;

        .check__checkmark {
          &:after {
            background-image: url("${CheckIconActive}");
          }
        }
      }
    }
  }

  & label {
    padding: 8px;
    line-height: 180%;
    color: ${(props) => props.theme.colors.black};

    &:hover {
      background: ${(props) => props.theme.colors.Confused.primary.midGray};
    }
  }

  & .check__checkmark {
    width: 22px;
    height: 22px;
    margin-right: 8px;
    position: relative;
    flex-shrink: 0;

    &:after {
      content: "";
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background-image: url("${CheckIcon}");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  & .check__text {
    font-size: 16px;
    font-weight: 600;
  }
`;
