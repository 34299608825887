import { Shades } from "./mojo";

interface ColorTrifecta {
  primary: string;
  secondary: string;
  tertiary: string;
}

export interface UswitchColors {
  gray: {
    0: string;
    5: string;
    10: string;
    20: string;
    30: string;
    40: string;
    50: string;
    60: string;
    70: string;
    80: string;
    90: string;
    100: string;
  };
  semantic: {
    success: string;
    error: string;
    warning: string;
  };
  brand: {
    blue: ColorTrifecta;
    green: ColorTrifecta;
    red: ColorTrifecta;
    yellow: ColorTrifecta;
    orange: ColorTrifecta;
  };
}

export const uswitchColors: UswitchColors = {
  gray: {
    0: "#FFFFFF",
    5: "#F5F5F5",
    10: "#EFEFEF",
    20: "#E3E3E3",
    30: "#D2D2D2",
    40: "#ADADAD",
    50: "#8C8C8C",
    60: "#656565",
    70: "#525252",
    80: "#343434",
    90: "#242424",
    100: "#141414",
  },
  semantic: {
    success: "#36AD59",
    error: "#F94549",
    warning: "#FFBC26",
  },
  brand: {
    blue: {
      primary: "#79C3FF",
      secondary: "#BDDCFF",
      tertiary: "#E4F3FF",
    },
    green: {
      primary: "#50F094",
      secondary: "#BFF8D3",
      tertiary: "#DCFCEA",
    },
    red: {
      primary: "#FF869C",
      secondary: "#FFCBD4",
      tertiary: "#FFE7EB",
    },
    yellow: {
      primary: "#FFF272",
      secondary: "#FFBC26",
      tertiary: "#FFFCE3",
    },
    orange: {
      primary: "#FFA08B",
      secondary: "#FFCBB6",
      tertiary: "#FFA08B",
    },
  },
};
