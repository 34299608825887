import { css } from "styled-components"
import RadioIcon from "../assets/confused/radio-inactive.svg"
import RadioIconActive from "../assets/confused/radio-active.svg"

export const confusedRadioButtonStyles = css`
  & input {
    &:checked {
      + label {
        background: ${(props) => props.theme.colors.white};
        box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.Confused.primary.darkGray};
        font-weight: 600;

        .radio__checkmark {
          &:after {
            background-image: url("${RadioIconActive}");
          }
        }
      }
    }
  }

  & label {
    padding: 16px;
    background: ${(props) => props.theme.colors.white};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.Confused.primary.black};
    font-weight: 600;
    color: rgb(31, 31, 31);

    &:hover {
      background: ${(props) => props.theme.colors.Confused.primary.lightGray};
    }
  }

  & .radio__checkmark {
    width: 22px;
    height: 22px;
    margin-right: 8px;
    position: relative;
    flex-shrink: 0;

    &:after {
      content: "";
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background-image: url("${RadioIcon}");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .radio__subtext {
    color: ${(props) => props.theme.colors.Confused.primary.darkGray};
    font-weight: initial;
    line-height: 24px;
  }

  & .check__text {
    font-size: 16px;
    font-weight: 600;
  }
`
