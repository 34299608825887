import React from "react"
import styled, { css } from "styled-components"
import Form, { FormItemProps } from "@mojo/blueprint/Form"
import { useFormContext } from "react-hook-form"
import Input, {
  ControlledInputProps as ControlledTextInputProps,
} from "@mojo/blueprint/Input/Controlled"
import Masked, {
  ControlledInputProps as ControlledMaskedTextInputProps,
} from "@mojo/blueprint/Input/Masked/Controlled"
import RadioButton, {
  ControlledInputProps as ControlledRadioButtonInputProps,
} from "@mojo/blueprint/RadioButton/Controlled"
import { useHistory, useLocation } from "react-router-dom"
import Button, { ButtonType } from "@mojo/blueprint/Button"
import { Config, FactFindStep } from "config/types"
import Checkbox, {
  ControlledInputProps as ControlledCheckboxInputProps,
} from "@mojo/blueprint/Checkbox/Controlled"
import CheckButton, {
  ControlledInputProps as ControlledCheckButtonInputProps,
} from "@mojo/blueprint/CheckButton/Controlled"
import Select, {
  ControlledInputProps as ControlledSelectInputProps,
} from "@mojo/blueprint/Select/Controlled"
import Stepper, {
  ControlledInputProps as ControlledStepperInputProps,
} from "@mojo/blueprint/Stepper/Controlled"
import get from "lodash/get"
import isFunction from "lodash/isFunction"
import MojoTip from "components/atoms/mojoTip/mojoTip"
import { useTemplate } from "@mojo/blueprint/Template/useTemplate"
import { useWindowSize } from "usehooks-ts"
import useMeasure from "@mojo/blueprint/utils/useMeasure"
import { Grid } from "@mojo/blueprint/Template"
import { useBrand } from "components/atoms/brandContext"
import ProgressBar from "components/atoms/progressBar"
import { ProgressBarProps } from "components/atoms/progressBar/progressBar"
import { useFactfind } from "components/atoms/factFindContext"
import { Event } from "@mojo/analytics"

type FormBuilderProps = FactFindStep<any> & {
  deeplyNested?: boolean
} & ProgressBarProps &
  Pick<Config<any>["sections"]["0"], "onSectionStart" | "onSectionEnd">

/**
 * Recursively builds a form from configuration
 */
const FormBuilder = ({
  deeplyNested = false,
  display: displayFunc,
  ...props
}: FormBuilderProps) => {
  /**
   * Event callback called whenever a new section is started
   */
  React.useEffect(() => {
    props.onSectionStart?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sectionProgress])

  /**
   * Event callback called whenever a new question is shown.
   * Either a custom callback, or a default event.
   */
  React.useEffect(() => {
    if (props.onQuestionShown) {
      props.onQuestionShown(props)
    } else {
      if (!deeplyNested)
        Event("Question Shown", {
          action: "Question Shown",
          event_category: "Site Interaction",
          event_label: props.label || props.legend,
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.stepProgress])

  /**
   * Event callback called whenever the user presses "Next"
   */
  const onNext = () => {
    if (props.stepProgress === props.stepProgressTotal) {
      if (props.onQuestionAnswered) {
        props.onQuestionAnswered(props, formValues)
      } else {
        if (!deeplyNested) {
          Event("Question Answered", {
            action: "Question Answered",
            event_category: "Site Interaction",
            event_label: props.label || props.legend,
          })
        }
      }
      props.onSectionEnd?.()
    } else {
      if (props.onQuestionAnswered) {
        props.onQuestionAnswered(props, formValues)
      } else {
        if (!deeplyNested) {
          Event("Question Answered", {
            action: "Question Answered",
            event_category: "Site Interaction",
            event_label: props.label || props.legend,
          })
        }
      }
    }
  }

  const ffContext = useFactfind()

  /**
   * We throw the error from within the form, so we can gracefully catch it with an error boundary.
   */
  React.useEffect(() => {
    if (ffContext.error) throw ffContext.error
  }, [ffContext.error])

  const { brand } = useBrand()
  const history = useHistory()

  const form = useFormContext()
  const formValues = form.watch()

  // used to hide or display specific parts of a fact find step
  const display = displayFunc ? displayFunc(formValues) : true

  const location = useLocation()

  /**
   * When deployed, results are accessed under `/insurance-discovery`
   * but we don't want that route locally.
   */
  const basePath = React.useMemo(() => {
    if (location.pathname.startsWith("/insurance-discovery")) {
      return "/insurance-discovery"
    }
    return ""
  }, [location])

  // reset if input is hidden
  React.useEffect(() => {
    if (display === false) {
      recursivelyReset(props)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [display])

  /**
   * We need to do some magic pixel counting to get the designs working
   * Nothing too crazy, just get the size of the screen and calculate available space
   */

  const [ref, { height: controlsHeight }] = useMeasure()
  const { height: windowHeight } = useWindowSize()
  const { navHeight } = useTemplate()

  const availableSpace = React.useMemo(() => {
    return windowHeight - navHeight - controlsHeight
  }, [windowHeight, navHeight, controlsHeight])

  /**
   * Fact find steps can be nested, so if we want to reset an entire step we need to do it recursively
   */
  const recursivelyReset = (fieldToReset: FormBuilderProps) => {
    const currentValue = form.getValues(fieldToReset.name)
    // only reset if value is anything but undefined
    if (currentValue !== undefined) {
      form.resetField(fieldToReset.name)
    }
    fieldToReset.extras?.forEach((e) => {
      recursivelyReset(e)
    })
  }

  const renderControl = RenderInput(props)

  const overallError = React.useMemo(() => {
    return get(form.formState.errors, `formBuilder.message`) as unknown as string
  }, [form])

  return (
    <>
      {display && renderControl && (
        <StyledFormBuilder $hideLabel={props.hideLabel || false}>
          <div
            className="factfind__form"
            style={{
              maxHeight: availableSpace,
              overflow: "auto",
            }}
          >
            <InjectGrid inject={!deeplyNested}>
              <div
                className={`factfind__formInternal ${
                  deeplyNested ? "factfind__formInternal--nested" : ""
                } grid__size`}
              >
                {!deeplyNested && <ProgressBar {...props} />}
                {props.type === "custom" ? (
                  <>{renderControl}</>
                ) : (
                  <Form.Item {...(props as unknown as FormItemProps)}>
                    {props.tip !== undefined && <MojoTip {...props.tip} />}
                    <>{renderControl}</>
                  </Form.Item>
                )}
                {props?.extras?.map((e) => (
                  <FormBuilder {...e} key={e.identifier} deeplyNested={true} />
                ))}
                {props.skippable !== undefined && props.controls?.next.onClick !== undefined ? (
                  <div className="factfind__skip">
                    <Button
                      brand={brand}
                      type="button"
                      variant={ButtonType.Link}
                      onClick={() => {
                        Event("Button Clicked", {
                          action: "Button Clicked",
                          event_category: "Functional Interaction",
                          event_label: `${props.skippable} - ${props.label || props.legend}`,
                        })
                        recursivelyReset(props)
                        props.controls?.next.onClick?.(formValues, history, basePath)
                      }}
                    >
                      {props.skippable}
                    </Button>
                  </div>
                ) : (
                  <></>
                )}
                {!deeplyNested && overallError && (
                  <span role="alert" className="factfind__error">
                    {overallError}
                  </span>
                )}
              </div>
            </InjectGrid>
          </div>
          {deeplyNested === false && (
            <div className="factfind__controls" ref={ref}>
              <Grid>
                <div className="factfind__controlsInternal grid__size">
                  <div>
                    {props.controls?.back?.render ? (
                      props.controls?.back.render(formValues, brand, ffContext)
                    ) : (
                      <>
                        {props.controls?.back?.hide ? (
                          <></>
                        ) : (
                          <Button
                            brand={brand}
                            type="button"
                            variant={ButtonType.Secondary}
                            onClick={() => {
                              recursivelyReset(props)
                              history.goBack()
                            }}
                          >
                            Back
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                  <div>
                    {props.controls?.next?.render ? (
                      props.controls.next.render(formValues, brand, ffContext)
                    ) : (
                      <>
                        {props.controls?.next.hide ? (
                          <></>
                        ) : (
                          <Button
                            brand={brand}
                            type="submit"
                            variant={ButtonType.Primary}
                            onClick={async () => {
                              const outcome = await form.trigger(undefined, { shouldFocus: true })
                              if (outcome) {
                                onNext()
                                props.controls?.next.onClick?.(formValues, history, basePath)
                              }
                            }}
                          >
                            {props.stepProgress === props.stepProgressTotal
                              ? "Next section"
                              : "Next"}
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </Grid>
            </div>
          )}
        </StyledFormBuilder>
      )}
    </>
  )
}

const InjectGrid = ({ inject, children }: { inject: boolean; children: React.ReactNode }) => {
  if (inject) {
    return <Grid>{children}</Grid>
  }
  return <>{children}</>
}

/**
 * The fact find can use custom components, but the majority of it is standard inputs.
 * This is where components are rendered depending on type assigned
 */
const RenderInput = (inputProps: FormBuilderProps) => {
  const { brand } = useBrand()
  const form = useFormContext()
  const formValues = form.watch()

  // fun with renderprops
  const renderProps = isFunction(inputProps.inputProps)
    ? inputProps.inputProps(formValues, brand)
    : inputProps.inputProps

  switch (inputProps.type) {
    case "text-input":
      return (
        <div className="factfind__textInput">
          <Input {...(renderProps as ControlledTextInputProps)} brand={brand} />
        </div>
      )
    case "radio-button-input":
      return (
        <div className="factfind__radioButtonInput">
          {(renderProps as ControlledRadioButtonInputProps[]).map((i) => (
            <RadioButton {...i} key={i.name} brand={brand} />
          ))}
        </div>
      )
    case "checkbox-input":
      return (
        <div className="factfind__checkboxInput">
          {(renderProps as ControlledCheckboxInputProps[]).map((i) => (
            <Checkbox {...i} key={i.name} brand={brand} />
          ))}
        </div>
      )
    case "check-button-input":
      return (
        <div className="factfind__checkButtonInput">
          {(renderProps as ControlledCheckButtonInputProps[]).map((i) => (
            <CheckButton {...i} key={i.name} brand={brand} />
          ))}
        </div>
      )
    case "select-input":
      return (
        <div className="factfind__selectInput">
          <Select {...(renderProps as ControlledSelectInputProps)} brand={brand} />
        </div>
      )
    case "stepper-input":
      return (
        <div className="factfind__stepperInput">
          <Stepper
            {...(renderProps as ControlledStepperInputProps)}
            allowEditing={true}
            brand={brand}
          />
        </div>
      )
    case "masked-input":
      return (
        <div className="factfind__maskedInput">
          <Masked {...(renderProps as ControlledMaskedTextInputProps)} brand={brand} />
        </div>
      )
    case "custom":
      if (inputProps.render)
        return (
          <>
            {isFunction(inputProps.render)
              ? inputProps.render(inputProps, formValues)
              : inputProps.render}
          </>
        )
      return null
  }
}

const StyledFormBuilder = styled.div<{ $hideLabel: boolean }>`
  flex: 1 1 0%;

  ${({ theme }) => theme.breakpoints.lg} {
    flex: none;
  }

  display: flex;
  flex-direction: column;

  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.md} {
    justify-content: flex-start;
  }

  .grid__size {
    grid-column: 1 / -1;

    ${({ theme }) => theme.breakpoints.md} {
      grid-column: 2 / 6;
    }

    ${({ theme }) => theme.breakpoints.lg} {
      grid-column: 3 / 11;
    }
  }

  .factfind__form {
    display: flex;
    justify-content: center;
    .factfind__formInternal {
      display: flex;
      flex: 1 1 0%;
      flex-direction: column;
      gap: ${({ theme }) => theme.spacing.sm};
      padding: ${({ theme }) => theme.spacing.sm} 2px;

      ${({ theme }) => theme.breakpoints.md} {
        padding: ${({ theme }) => theme.spacing.lg} 2px;
      }
    }

    .factfind__formInternal.factfind__formInternal--nested {
      padding: ${({ theme }) => theme.spacing.sm} 2px;

      ${({ theme }) => theme.breakpoints.md} {
        padding: 0 2px;
      }
    }
  }

  ${({ $hideLabel }) =>
    $hideLabel
      ? css`
          .item__labelcontainer {
            margin-bottom: 0;
          }

          .item__label {
            line-height: 0;
            margin-bottom: 0;
            label {
              font-size: 0;
            }
          }
        `
      : css`
          .item__labelcontainer {
            margin-bottom: ${({ theme }) => theme.spacing.base};
          }

          .item__label label,
          fieldset legend {
            font-size: 1.4375rem;
            line-height: 135%;
          }
        `}

  a {
    color: ${({ theme }) => theme.colors.Mojo.primary.two};
  }

  .factfind__skip {
    button {
      min-height: auto;
      text-decoration: none;
      border-bottom: 2px solid;
      border-radius: 0;
    }

    button.brand__Money {
      border-color: ${({ theme }) => theme.colors.Money.brand.primary.plum[100]};
    }

    .button__internal {
      padding: ${({ theme }) => theme.spacing.xxs} 0 !important;
    }
  }

  .factfind__controls {
    display: flex;
    justify-content: center;
    padding: ${({ theme }) => theme.spacing.sm} 0;

    .factfind__controlsInternal {
      display: flex;
      justify-content: space-between;

      .button__icon--leftIcon {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  .factfind__radioButtonInput {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.sm};
  }

  .factfind__error {
    color: ${({ theme }) => theme.colors.error[600]};
  }
`

export default FormBuilder
