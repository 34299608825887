import React from "react";
import * as PopoverPrimitive from "@radix-ui/react-popover";
import { IconStyleable, IconType } from "../Icon";
import styled, { css } from "styled-components";
import useWindowSize from "../utils/useWindowSize";
import { BrandProp, Brands } from "@mojo/types";
import { getBrand } from "../Theme/brand";
import { Guid } from "@mojo/utils";
import { Cross } from "../Icon";

export type TooltipProps = Omit<
  PopoverPrimitive.PopoverContentProps,
  "content"
> & {
  /**
   * Content to be displayed in the popover. If null is passed, popover won't display.
   */
  content: React.ReactNode | null;
  /**
   * The component that the user will hover over to display the popover.
   * @default "A button containing a question mark icon."
   */
  children?: React.ReactNode;
  /**
   * Event handler called when the state of the tooltip changes from open to closed or from closed to open.
   */
  onOpenChange?: PopoverPrimitive.PopoverProps["onOpenChange"];
  /**
   * Props that will be passed to the default button rendered whenever `children` is undefined.
   */
  defaultButtonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
} & BrandProp;

/**
 * A reusable, accessible tooltip component built with Radix UI.
 * For documentation, read Radix docs https://www.radix-ui.com/docs/primitives/components/popover.
 *
 * Technically more of a popover, but internally referred as Tooltip.
 */
const Tooltip = (
  {
    content,
    children,
    defaultButtonProps,
    onOpenChange,
    brand = "Mojo",
    ...props
  }: TooltipProps = {
    content: null,
    side: "top",
  }
): JSX.Element => {
  const { width } = useWindowSize();
  /**
   * We display a fixed tooltip only if the window width is between 0 and 768px.
   */
  const displayAsMobile = width > 0 && width < 768;

  const id = Guid();

  return (
    <PopoverPrimitive.Root
      // this will lock scrolling if we're displaying the tooltip as a modal on mobile, it lookes better
      modal={displayAsMobile}
      onOpenChange={onOpenChange}
    >
      <StyledTooltipTrigger
        $brand={brand}
        aria-describedby={id}
        asChild
        {...defaultButtonProps}
      >
        {children || (
          <button
            className="tooltip__defaultButton"
            data-testid="default-icon-button"
          >
            <QuestionMark width={20} height={20} />
          </button>
        )}
      </StyledTooltipTrigger>
      {content !== null && (
        <PopoverPrimitive.Portal>
          <TooltipContainer $displayAsMobile={displayAsMobile}>
            <TooltipContent $brand={brand} id={id} {...props} role="tooltip">
              {content}
              {!displayAsMobile && (
                <PopoverPrimitive.Arrow
                  className="tooltip__arrow"
                  offset={20}
                />
              )}
              <PopoverPrimitive.Close
                className="tooltip__close"
                aria-label="Close tooltip"
              >
                <Cross width={24} height={24} />
              </PopoverPrimitive.Close>
            </TooltipContent>
          </TooltipContainer>
        </PopoverPrimitive.Portal>
      )}
    </PopoverPrimitive.Root>
  );
};

const QuestionMark = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="150.881 125.796 32 32"
    width={24}
    height={24}
    {...props}
  >
    <path
      fill="currentColor"
      d="M182.881 141.796c0 8.837-7.163 16-16 16s-16-7.163-16-16 7.163-16 16-16 16 7.163 16 16Zm-14.784 6.834c.327-.327.491-.733.491-1.216 0-.484-.164-.889-.491-1.216a1.653 1.653 0 0 0-1.216-.491c-.484 0-.889.164-1.216.491a1.651 1.651 0 0 0-.491 1.216c0 .483.164.889.491 1.216.327.327.732.491 1.216.491.483 0 .889-.164 1.216-.491Zm.277-4.309c0-.427.1-.79.299-1.088.213-.313.469-.598.768-.854a13.82 13.82 0 0 1 1.003-.746 6.476 6.476 0 0 0 1.002-.854c.313-.313.569-.682.768-1.109.214-.427.32-.939.32-1.536 0-.583-.128-1.138-.384-1.664a4.047 4.047 0 0 0-1.045-1.408c-.455-.398-1.017-.711-1.685-.939-.655-.242-1.394-.362-2.219-.362-.541 0-1.031.049-1.472.149a5.678 5.678 0 0 0-2.133.981 8.449 8.449 0 0 0-.726.64 5.383 5.383 0 0 0-1.109 1.75l2.773 1.28c.157-.313.363-.598.619-.854.213-.213.483-.405.811-.576.341-.185.753-.277 1.237-.277.583 0 1.045.156 1.387.469.355.313.533.654.533 1.024 0 .285-.093.541-.277.768-.185.228-.42.448-.704.662a45.07 45.07 0 0 1-.896.64 6.21 6.21 0 0 0-.896.746c-.271.27-.498.583-.683.939-.185.355-.277.775-.277 1.259 0 .27.035.497.106.682.029.1.064.192.107.278Z"
    />
  </svg>
);

export const TriggerBrandVariantStyles = (brand: Brands) =>
  getBrand(brand, {
    Mojo: css`
      color: #666b7e;
      background: transparent;

      &:hover {
        color: ${({ theme }) => theme.colors.Mojo.vivid[500]};
      }
    `,
    Uswitch: css`
      color: #666b7e;
      background: transparent;

      &:hover {
        color: ${({ theme }) => theme.colors.black};
      }
    `,
    Money: css`
      color: #666b7e;
      background: transparent;

      &:hover {
        color: ${({ theme }) => theme.colors.Money.brand.primary.fuchsia[100]};
      }
    `,
    Confused: css`
      color: ${({ theme }) => theme.colors.black};
      background: transparent;

      &:hover {
        color: #666b7e;
      }
    `,
  });

const StyledTooltipTrigger = styled(PopoverPrimitive.Trigger)<{
  $brand: Brands;
}>`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  ${({ $brand }) => TriggerBrandVariantStyles($brand)}
`;

export const BrandVariantStyles = (brand: Brands) =>
  getBrand(brand, {
    Mojo: css`
      background-color: #000928;
      color: white;

      box-shadow: 0px 16.25px 97.5px rgba(0, 0, 0, 0.12);
      border-top-left-radius: ${(props) => props.theme.radius.base};
      border-top-right-radius: ${(props) => props.theme.radius.base};

      ${({ theme }) => theme.breakpoints.md} {
        border-radius: ${(props) => props.theme.radius.base};
      }

      & .tooltip__close {
        color: ${(props) => props.theme.colors.white};
      }
    `,
    Uswitch: css`
      color: white;
      background-color: #141424;

      & .tooltip__close {
        color: ${(props) => props.theme.colors.white};
      }
    `,
    Money: css`
      color: ${(props) =>
        props.theme.colors.Money.brand.primary.blueberry[100]};
      background-color: ${(props) => props.theme.colors.Money.gray[0]};

      border-top-left-radius: ${(props) => props.theme.radius.xs};
      border-top-right-radius: ${(props) => props.theme.radius.xs};

      box-shadow: 0px 16px 87px 0px rgba(0, 0, 0, 0.12);

      ${({ theme }) => theme.breakpoints.md} {
        border-radius: ${(props) => props.theme.radius.xs};
      }

      & .tooltip__arrow > polygon {
        fill: ${(props) => props.theme.colors.Money.gray[0]};
      }

      & .tooltip__close {
        color: ${(props) =>
          props.theme.colors.Money.brand.primary.blueberry[100]};
      }
    `,
    Confused: css`
      color: white;
      background-color: #141424;

      & .tooltip__close {
        color: ${(props) => props.theme.colors.white};
      }
    `,
  });

/**
 * Main body of the tooltip
 */
const TooltipContent = styled(PopoverPrimitive.Content)<{
  $brand: Brands;
}>`
  z-index: 1000;
  line-height: 150%;

  max-width: 100vw;

  padding: 1.5rem;

  ${(props) => props.theme.breakpoints.md} {
    margin: initial;
    max-width: 440px;
  }

  & .tooltip__close {
    position: absolute;
    right: 0;
    top: 0;

    cursor: pointer;

    background-color: transparent;
    border: 0px;
    padding: 0.5rem;
  }

  ${({ $brand }) => BrandVariantStyles($brand)}
`;

/**
 * Hack to fix the tooltip container to the bottom of the screen on mobile
 */
const TooltipContainer = styled.div<{
  $displayAsMobile: boolean;
}>`
  ${(props) =>
    props.$displayAsMobile
      ? `& div[data-radix-popper-content-wrapper] {
            transform: translate3d(0px, 0, 0px)!important;
            width: 100%;
            bottom: 0;
            top: auto !important;
            max-width: 768px;
        }`
      : ""}
`;

export default Tooltip;
