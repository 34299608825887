import { css } from "styled-components";

export const moneyButtonStyles = css`
  & {
    border-radius: 100px;
    padding: 0px;
    box-sizing: border-box;
    text-align: center;
    position: relative;
    transition: width 0.7s;
    cursor: pointer;
    user-select: none;
    border: 0;
    outline: 0;
  }

  & .button__internal {
    display: flex;
    align-content: center;
    justify-content: center;
    border-radius: 10px;
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.primary {
    background: ${({ theme }) =>
      theme.colors.Money.brand.primary.sunshine[100]};
    color: ${({ theme }) => theme.colors.Money.brand.primary.blueberry[100]};
    stroke: ${({ theme }) => theme.colors.Money.brand.primary.blueberry[100]};
    transition: background-color 200ms ease 0s;
    font-weight: 550;

    &:hover {
      background: rgb(248, 179, 27);
    }

    &:disabled {
      opacity: 0.5;
    }

    &:focus {
      background-color: ${({ theme }) =>
        theme.colors.Money.brand.primary.fuchsia[100]};
      color: ${({ theme }) => theme.colors.white};
      outline: none;
    }
  }

  &.secondary {
    background: ${({ theme }) =>
      theme.colors.Money.brand.primary.blueberry[100]};
    color: ${({ theme }) => theme.colors.white};
    stroke: ${({ theme }) => theme.colors.white};
    font-weight: 550;

    &:hover {
      background: #5d6a72;
    }

    &:disabled {
      opacity: 0.5;
    }

    &:focus {
      background-color: ${({ theme }) =>
        theme.colors.Money.brand.primary.fuchsia[100]};
      color: ${({ theme }) => theme.colors.white};
      outline: none;
    }
  }

  &.tertiary {
    background: rgb(255, 255, 255);
    color: rgb(20, 20, 36);
    font-weight: 550;

    &:hover {
      background: rgb(243, 243, 244);
    }

    &:disabled {
      color: rgb(161, 161, 167);
    }
  }

  &.link {
    background: transparent;
    font-weight: 500;

    & .button__internal {
      color: ${({ theme }) => theme.colors.Money.brand.primary.plum[100]};
    }

    &:disabled {
      color: rgb(161, 161, 167);
    }
  }

  &.answer {
    background: ${(props) => props.theme.colors.white};
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.Money.gray[30]};
    border-radius: ${({ theme }) => `${theme.spacing.xxs}`};

    color: ${({ theme }) => theme.colors.Money.gray[60]};
    font-size: 1rem;
    font-weight: 500;

    &.base .button__internal {
      justify-content: space-between;
      padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.base}`};
    }

    &:hover {
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.Money.gray[100]};
      color: ${({ theme }) => theme.colors.black};
    }

    &:focus {
      box-shadow: 0 0 0 2px #fea500;
      color: ${({ theme }) => theme.colors.black};
    }

    &:active {
      box-shadow: 0 0 0 3px
        ${({ theme }) => theme.colors.Money.brand.primary.plum[100]},
      0 0 0 5px #fea500;
    }
  }

  // Size classes, change paddings and font size depending on prop
  &.base .button__internal {
    font-size: 1rem;
    padding: 1.2rem 2rem;
    padding: 1.2rem 2rem;
  }

  &.small .button__internal {
    font-size: 0.75rem;
    padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.md}`};
  }
`;
