import { css } from "styled-components";

export const confusedComboboxButtonStyles = css`
  & .combobox__button {
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.Confused.primary.black};
    color: ${({ theme }) => theme.colors.Confused.primary.black};

    &:disabled {
      background-color: ${({ theme }) => theme.colors.Confused.primary.midGray};
      cursor: not-allowed;
    }

    &:focus {
      box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.Confused.primary.black},
        0 0 0 4px ${({ theme }) => theme.colors.Confused.primary.darkGray};
      outline: none;
    }
  }

  & .combobox__placeholder {
    color: ${({ theme }) => theme.colors.neutral[500]};
  }

  & .combobox__pill {
    background-color: #f3f3f4;
    color: #72727c;
    border-radius: ${({ theme }) => theme.radius.xxs};
  }
`;

export const confusedComboboxPopoverStyles = css`
  border: 2px solid ${({ theme }) => theme.colors.Confused.primary.darkGray};
  background-color: ${({ theme }) => theme.colors.white};
  overflow: hidden;

  & .combobox__commandinput {
    border: none;

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  & [cmdk-item]:not(:first-child),
  & [cmdk-list] {
    border-top: 2px solid ${({ theme }) => theme.colors.Confused.primary.darkGray};
  }

  & .combobox__commanditem[aria-selected="true"] {
    background-color: ${({ theme }) => theme.colors.Confused.primary.midGray};
  }
`;
