"use client"
import React, { ReactElement, ReactNode } from "react";
import styled, { keyframes } from "styled-components";
import { BrandProp, Brands } from "@mojo/types";
import { getBrand } from "../Theme/brand";
import { mojoButtonStyles } from "./styles/mojo";
import { uswitchButtonStyles } from "./styles/uswitch";
import { moneyButtonStyles } from "./styles/money";
import { confusedButtonStyles } from "./styles/confused";

export enum ButtonType {
  Primary = 0,
  Secondary = 1,
  Tertiary = 2,
  Danger = 3,
  Link = 4,
  Answer = 5,
}

type ButtonSizes = "base" | "small";

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  BrandProp & {
    /**
     * What style of button should this be displayed as. Import `ButtonType` and pass it as a prop.
     */
    variant?: ButtonType;
    /**
     * The button's content, same as `children`
     */
    text?: string | ReactElement;
    /**
     * The button's content, same as `text`
     */
    children?: ReactNode;
    /**
     * The size of the button, will change the padding amount.
     */
    size?: ButtonSizes;
    /**
     * Display an icon (as a react component or string) left of the button
     */
    leftIcon?: React.ReactNode | string;
    /**
     * Display an icon (as a react component or string) right of the button
     */
    rightIcon?: React.ReactNode | string;
    /**
     * Displays a loader icon to the right of the button text. If the button has a `rightIcon` prop, it replaces the icon.
     */
    isLoading?: boolean;
  };

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children = "",
      variant = ButtonType.Primary,
      disabled = false,
      brand = "Mojo",
      size = "base",
      leftIcon,
      rightIcon,
      isLoading,
      ...props
    }: ButtonProps,
    ref
  ) => {
    const classNames = [];

    if (disabled) {
      classNames.push("disabled");
    }

    switch (variant) {
      case ButtonType.Primary:
        classNames.push("primary");
        break;
      case ButtonType.Secondary:
        classNames.push("secondary");
        break;
      case ButtonType.Tertiary:
        classNames.push("tertiary");
        break;
      case ButtonType.Danger:
        classNames.push("danger");
        break;
      case ButtonType.Link:
        classNames.push("link");
        break;
      case ButtonType.Answer:
        classNames.push("answer");
        break;
    }

    const renderIconOrSpinner = () => {
      if (rightIcon) {
        return (
          <>
            {isLoading ? (
              <>{isLoading && <LoadIcon />}</>
            ) : (
              <div className="button__icon button__icon--rightIcon">
                {rightIcon}
              </div>
            )}
          </>
        );
      } else {
        return <>{isLoading && <LoadIcon />}</>;
      }
    };

    return (
      <StyledButton
        ref={ref}
        $size={size}
        disabled={disabled}
        $brand={brand}
        {...props}
        className={`${classNames.join(" ")} ${size} ${props.className || ""}}`}
      >
        <span className="button__internal">
          {leftIcon && (
            <div className="button__icon button__icon--leftIcon">
              {leftIcon}
            </div>
          )}
          {children}
          {renderIconOrSpinner()}
        </span>
      </StyledButton>
    );
  }
);

const LoadIcon = () => (
  <div className="button__icon button__icon--spinner">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      className="spinner"
    >
      <rect width="256" height="256" fill="none" />
      <path
        d="M168,40a97,97,0,0,1,56,88,96,96,0,0,1-192,0A97,97,0,0,1,88,40"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="24"
      />
    </svg>
  </div>
);

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const BrandVariantStyles = (brand: Brands) =>
  getBrand(brand, {
    Mojo: mojoButtonStyles,
    Uswitch: uswitchButtonStyles,
    Money: moneyButtonStyles,
    Confused: confusedButtonStyles,
  });

const StyledButton = styled.button<{
  $brand: Brands;
  $size: ButtonSizes;
}>`
  align-self: center;

  & .button__internal {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.xs};
  }

  & .button__icon {
    display: flex;
    align-items: center;
  }

  &.base {
    min-height: 51px;
    svg {
      height: 18.5px;
      width: 18.5px;
    }
  }
  &.small {
    min-height: 32px;

    svg {
      height: 14px;
      width: 14px;
    }
  }

  & .spinner {
    animation: ${rotate} 1.3s linear infinite;
  }

  ${({ $brand }) => BrandVariantStyles($brand)};
`;

export default Button;
