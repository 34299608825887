import { BrandProp, Brands } from "@mojo/types";
import React from "react";
import styled from "styled-components";
import Masked from "./Masked";
import { getBrand } from "../Theme/brand";
import useMeasure from "../utils/useMeasure";
import { mojoInputStyles } from "./styles/mojo";
import { uswitchInputStyles } from "./styles/uswitch";
import { moneyInputStyles } from "./styles/money";
import { confusedInputStyles } from "./styles/confused";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> &
  BrandProp & {
    /**
     * Display an icon (as a react component or string) left of the input
     */
    leftIcon?: React.ReactNode | string;
    /**
     * Display an icon (as a react component or string) right of the input
     */
    rightIcon?: React.ReactNode | string;
  };

// eslint-disable-next-line react/display-name
const Input = React.forwardRef<
  HTMLInputElement & {
    Masked: JSX.Element;
  },
  InputProps
>(({ brand = "Mojo", leftIcon, rightIcon, ...props }: InputProps, ref: any) => {
  const [leftIconRef, { width: leftIconWidth }] = useMeasure();
  const [rightIconRef, { width: rightIconWidth }] = useMeasure();

  return (
    <StyledInput
      $brand={brand}
      $extraPaddingLeft={leftIconWidth}
      $extraPaddingRight={rightIconWidth}
    >
      {leftIcon && (
        <div className="input__leftIcon" ref={leftIconRef}>
          {leftIcon}
        </div>
      )}
      <input {...props} ref={ref} />
      {rightIcon && (
        <div className="input__rightIcon" ref={rightIconRef}>
          {rightIcon}
        </div>
      )}
    </StyledInput>
  );
});

const InputNamespace = Object.assign(Input, { Masked });

export const BrandVariantStyles = (brand: Brands) =>
  getBrand(brand, {
    Mojo: mojoInputStyles,
    Uswitch: uswitchInputStyles,
    Money: moneyInputStyles,
    Confused: confusedInputStyles,
  });

const StyledInput = styled.div<{
  $brand: Brands;
  $extraPaddingLeft: number;
  $extraPaddingRight: number;
}>`
  position: relative;

  & .input__leftIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1 / 1;
    z-index: 1;
    position: absolute;
    left: -1px;
    top: -1px;
    bottom: -1px;
    padding: 1rem;
  }

  & .input__rightIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1 / 1;
    z-index: 1;
    position: absolute;
    right: -1px;
    top: -1px;
    bottom: -1px;
    padding: 1rem;
  }

  & input {
    padding: 1rem
      calc(1rem + ${({ $extraPaddingRight }) => $extraPaddingRight}px) 1rem
      calc(1rem + ${({ $extraPaddingLeft }) => $extraPaddingLeft}px);
    width: 100%;
    border: 1px solid transparent;
    font-weight: 500;
    -webkit-appearance: none;
    font-variant-numeric: lining-nums;

    &.invalid {
      box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.error[500]};
    }

    &::placeholder {
      font-weight: medium;
      color: ${({ theme }) => theme.colors.neutral[500]};
      opacity: 1;
    }

    &:focus {
      .invalid {
        box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.error[500]};
      }
    }
  }

  ${({ $brand }) => BrandVariantStyles($brand)};
`;

export default InputNamespace;
