import React, { useState } from "react";
import { ThemeProvider } from "styled-components";
import { Normalize } from "styled-normalize";
import GlobalStyles from "./GlobalStyles";
import { RootThemeContext } from "./root-theme-context";
import { defaultTheme } from "./DefaultTheme";

interface ThemeProps {
  children: React.ReactNode;
}

export const Theme = ({ children }: ThemeProps) => {
  const [blockScroll, setBlockScroll] = useState<boolean>(false);

  return (
    <RootThemeContext.Provider value={{ setBlockScroll }}>
      <ThemeProvider theme={defaultTheme}>
        <Normalize />
        <GlobalStyles blockScroll={blockScroll} />
        {children}
      </ThemeProvider>
    </RootThemeContext.Provider>
  );
};
