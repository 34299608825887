import { css } from "styled-components";

export const uswitchCookieBannerStyles = css`
  a {
    color: ${({ theme }) => theme.colors.black};
    text-decoration: underline;
    transition: color 0.3s ease;

    &:hover { color: purple; }
    &:active { color: purple; }
    &:visited { color: purple; }
  }

  /* Alternative Uswitch secondary button styling used here. Same as in Discovery */
  .cookieBanner__essentialButton {
    align-items: center;
    background: ${({ theme }) => theme.colors.white};
    border: 2px solid ${({ theme }) => theme.colors.Uswitch.gray[100]};
    color: ${({ theme }) => theme.colors.Uswitch.gray[100]};
    fill: ${({ theme }) => theme.colors.Uswitch.gray[100]};
    cursor: pointer;
    display: flex;
    font-size: 1rem;
    font-weight: 500;
    gap: space;
    justify-content: center;

    &:disabled {
      border: 2px solid ${({ theme }) => theme.colors.Uswitch.gray[50]};
      color: ${({ theme }) => theme.colors.Uswitch.gray[50]};
      cursor: not-allowed;
      fill: ${({ theme }) => theme.colors.Uswitch.gray[50]};
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.Uswitch.gray[10]};
    }

    &:focus {
      border-color: ${({ theme }) => theme.colors.Uswitch.brand.orange.primary};
      box-shadow: 0 0 2px ${({ theme }) => theme.colors.Uswitch.brand.orange.primary};
      outline: none;
    }
  }
`;
