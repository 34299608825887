import axios from "axios";
import * as Sentry from "@sentry/browser";

export const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Log the error to Sentry
    Sentry.captureException(error);
    // Re-throw the error so the promise is rejected
    throw error;
  }
);
