import { css } from "styled-components";

export const confusedTabsStyles = css`
  & .tabs__list {
    background-color: ${({ theme }) => theme.colors.Confused.primary.lightGray};
  }

  & .tabs__trigger {
    padding: ${({ theme }) => theme.spacing.sm};

    &[data-state="active"] {
      color: ${({ theme }) => theme.colors.Confused.primary.white};
      background-color: ${({ theme }) => theme.colors.Confused.primary.black};
    }
    &[data-state="inactive"] {
      background-color: transparent;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.Confused.primary.charcoal};
    }
  }
`;
