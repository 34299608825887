import { css } from "styled-components";

export const mojoRadioButtonStyles = css`
  & input {
    &:checked {
      + label {
        background: ${(props) => props.theme.colors.primary.two};
        box-shadow: 0 0 0 1px ${(props) => props.theme.colors.primary.two};
        color: ${(props) => props.theme.colors.white};
        font-weight: 500;

        .radio__checkmark {
          background: ${(props) => props.theme.colors.primary.two};
          box-shadow: 0 0 0 2px ${(props) => props.theme.colors.white};

          &:after {
            transform: scale(1);
          }
        }
      }

      &:focus + label {
        box-shadow: 0 0 0 2px ${(props) => props.theme.colors.neutral[50]},
          0 0 0 4px ${(props) => props.theme.colors.primary.one};
      }
    }

    &:focus + label {
      box-shadow: 0 0 0 2px ${(props) => props.theme.colors.primary.one};
    }

    &:disabled + label {
      background-color: ${(props) => props.theme.colors.neutral[50]};
    }

    &:checked + label {
      .radio__subtext {
        color: ${(props) => props.theme.colors.white};
      }
    }

    &:not(:checked) + label {
      .radio__subtext {
        color: ${(props) => props.theme.colors.Mojo.neutral[500]};
      }
    }
  }

  & label {
    padding: 16px;
    background: ${(props) => props.theme.colors.white};
    box-shadow: 0 0 0 1px ${(props) => props.theme.colors.neutral[200]};
    border-radius: ${(props) => props.theme.radius.xs};
    transition: 0.2s;
    color: ${(props) => props.theme.colors.primary.two};

    &:hover {
      background: ${(props) => props.theme.colors.neutral[50]};
    }

    &:focus {
      box-shadow: 0 0 0 2px ${(props) => props.theme.colors.primary.one};
    }
  }

  .radio__subtext {
    font-weight: 500;
    line-height: 25px;
  }

  & .radio__checkmark {
    width: 16px;
    height: 16px;
    background: ${(props) => props.theme.colors.white};
    box-shadow: 0 0 0 1.5px ${(props) => props.theme.colors.primary.two};
    margin-right: 16px;
    border-radius: 100%;
    position: relative;
    transition: 0.2s;
    flex-shrink: 0;

    &:after {
      content: "";
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: ${(props) => props.theme.colors.white};
      transform: scale(0);
      transform-origin: center;
      transition: 0.2s;
    }
  }

  & .radio__text {
    font-size: 16px;
    font-weight: 500;
  }
`;
