import * as TabsPrimitive from "@radix-ui/react-tabs";
import { opacify, transparentize } from "polished";
import styled from "styled-components";
import { getBrand } from "../Theme/brand";
import { BrandProp, Brands } from "@mojo/types";
import { mojoTabsStyles } from "./styles/mojo";
import { uswitchTabsStyles } from "./styles/uswitch";
import { moneyTabsStyles } from "./styles/money";
import {confusedTabsStyles} from "./styles/confused";

export type TabsProps = TabsPrimitive.TabsProps & {
  tabs: (Omit<
    TabsPrimitive.TabsTriggerProps & TabsPrimitive.TabsContentProps,
    "title" | "value"
  > & {
    /**
     * The tab's title
     */
    title: React.ReactNode;
    /**
     * The tab's content
     */
    content: React.ReactNode;
    /**
     * The tab's unique id
     */
    value: string;
  })[];
} & BrandProp;

/**
 * An accessible tab component built with Radix-ui
 * Docs https://www.radix-ui.com/docs/primitives/components/tabs
 */
const Tabs = ({ tabs, brand = "Mojo", ...props }: TabsProps) => {
  // default tab is first one unless specified
  const defaultValue = props.defaultValue || tabs[0].value;

  return (
    <StyledTabsRoot {...props} defaultValue={defaultValue} $brand={brand}>
      <TabsPrimitive.List
        className="tabs__list"
        aria-label="Manage your account"
      >
        {tabs.map((t) => (
          <TabsPrimitive.Trigger
            key={t.value}
            className="tabs__trigger"
            value={t.value}
            disabled={t.disabled}
          >
            {t.title}
          </TabsPrimitive.Trigger>
        ))}
      </TabsPrimitive.List>
      {tabs.map((t) => (
        <TabsPrimitive.Content
          key={t.value}
          className="tabs__content"
          value={t.value}
        >
          {t.content}
        </TabsPrimitive.Content>
      ))}
    </StyledTabsRoot>
  );
};

export const BrandVariantStyles = (brand: Brands) =>
  getBrand(brand, {
    Mojo: mojoTabsStyles,
    Uswitch: uswitchTabsStyles,
    Money: moneyTabsStyles,
    Confused: confusedTabsStyles
  });

const StyledTabsRoot = styled(TabsPrimitive.Root)<{
  $brand: Brands;
}>`
  & .tabs__list {
    display: flex;
  }

  & .tabs__trigger {
    font-size: 0.75rem;
    border: none;
    padding: ${({ theme }) => `${theme.spacing.base} ${theme.spacing.xs}`};
    flex: 1 1 0%;
    cursor: pointer;
    font-weight: bold;
  }

  & .tabs__content {
    padding: ${({ theme }) =>
      `${theme.spacing.md} ${theme.spacing.base} ${theme.spacing.md} ${theme.spacing.base}`};
  }

  ${({ $brand }) => BrandVariantStyles($brand)};
`;

export default Tabs;
