import { css } from "styled-components";

export const uswitchInputStyles = css`
  & input {
    & {
      box-shadow: 0 0 0 1px rgb(161, 161, 167);
      color: ${(props) => props.theme.colors.black};
      color: rgb(20, 20, 36);
    }

    &:focus {
      box-shadow: 0 0 0 2px rgb(20, 20, 36), 0 0 0 4px #fea500;
      outline: none;
    }
  }
`;
