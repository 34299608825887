import Form from "@mojo/blueprint/Form"
import InputControlled from "@mojo/blueprint/Input/Controlled"
import styled from "styled-components"
import Typography from "@mojo/blueprint/Typography"
import { useBrand } from "components/atoms/brandContext"

const FeetAndInches = () => {
  const { brand } = useBrand()
  return (
    <>
      <Typography.H4 as="span">We need your height without shoes on</Typography.H4>
      <StyledFeetAndInches>
        <Form.Item
          name="details.height.feet"
          label="Feet"
          rules={{
            required: {
              value: true,
              message: "This input is required",
            },
          }}
          setValueAs={(val) => {
            const parsed = parseFloat(val)
            if (Number.isNaN(parsed)) {
              return 0
            }
            return parsed
          }}
        >
          <InputControlled placeholder="6" rightIcon="ft" brand={brand} />
        </Form.Item>
        <Form.Item
          name="details.height.inches"
          label="Inches"
          rules={{
            required: {
              value: true,
              message: "This input is required",
            },
          }}
          setValueAs={(val) => {
            const parsed = parseFloat(val)
            if (Number.isNaN(parsed)) {
              return 0
            }
            return parsed
          }}
        >
          <InputControlled placeholder="2" rightIcon="in" brand={brand} />
        </Form.Item>
      </StyledFeetAndInches>
    </>
  )
}

const StyledFeetAndInches = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm};

  .form__item {
    flex: 1 1 0%;
  }

  .item__label,
  .item__labelcontainer {
    line-height: 0 !important;
    margin-bottom: 0 !important;
    label {
      font-size: 0 !important;
    }
  }
`

export default FeetAndInches
