import { Brands } from "@mojo/types"
import { Helmet } from "react-helmet"
import { useBrand } from "../brandContext"

const Meta = () => {
  const { brand } = useBrand()

  return (
    <>
      <Helmet>
        <title>{`${getTitle(brand)} | ${brand}`}</title>
        <link rel="icon" type="image/ico" href={getFavicon(brand)} sizes="32x32" />
      </Helmet>
    </>
  )
}

const getFavicon = (brand: Brands) => {
  switch (brand) {
    case "Mojo":
      return "https://hosted-assets.mojomortgages.com/favicons/mojo.ico"
    case "Uswitch":
      return "https://hosted-assets.mojomortgages.com/favicons/uswitch.ico"
    case "Money":
      return "https://hosted-assets.mojomortgages.com/favicons/money.ico"
    case "Confused":
      return "https://www.confused.com/static/content/images/redesign/v1.0/favicon/fav.ico"
  }
}
const getTitle = (brand: Brands) => {
  switch (brand) {
    default:
    case "Mojo":
      return "Insurance"
    case "Uswitch":
      return "Insurance"
    case "Money":
      return "Insurance"
  }
}

export default Meta
