import { css } from "styled-components";

export const mojoInputStyles = css`
  & .input__leftIcon {
    background-color: ${({ theme }) => theme.colors.primary.two};
    color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => theme.radius.xs} 0 0
      ${({ theme }) => theme.radius.xs};
  }

  & .input__rightIcon {
    background-color: ${({ theme }) => theme.colors.primary.two};
    color: ${({ theme }) => theme.colors.white};
    border-radius: 0 ${({ theme }) => theme.radius.xs}
      ${({ theme }) => theme.radius.xs} 0;
  }

  & input {
    & {
      box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.neutral[200]};
      border-radius: ${({ theme }) => theme.radius.xs};
      color: ${({ theme }) => theme.colors.primary.two};
    }

    &:focus {
      box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primary.one};
      outline: none;
    }
  }
`;
