import { css } from "styled-components";

export const moneyStepButtonStyles = css`
  & {
    color: ${(props) => props.theme.colors.Money.gray[0]};
    background: ${(props) => props.theme.colors.Money.brand.primary.blueberry[100]};
    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
    &:hover {
      background: ${(props) => props.theme.colors.Money.brand.primary.fuchsia[100]};
    }
    &:focus {
      background: ${(props) => props.theme.colors.Money.brand.primary.fuchsia[100]};
      outline: 3px solid ${(props) => props.theme.colors.Money.brand.primary.plum[100]};
    }
  }
`
