import { css } from "styled-components";

export const moneyChainedListStyles = css`
  .bullet__item::before {
    background: ${(props) => props.theme.colors.Money.brand.primary.sunshine[100]};
  }

  .bullet__point::after {
    color: ${(props) => props.theme.colors.black};
    font-size: 0.75rem;
    line-height: 24px;
  }

  .bullet__point::before {
    background: ${(props) => props.theme.colors.Money.brand.primary.sunshine[100]};
  }

  .bullet__title {
    font-weight: 600;
    line-height: 19px;
    color: #000928;
  }
  .bullet__text {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 25.2px;
    color: #6C6F7A;
  }
`;
