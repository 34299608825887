import { css } from "styled-components";

export const mojoStepButtonStyles = css`
  & {
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.Mojo.neutral[900]};
    &:disabled {
      background: ${(props) => props.theme.colors.Mojo.neutral[700]};
      cursor: not-allowed;
    }
    &:focus {
      box-shadow: 0 0 0 2px ${(props) => props.theme.colors.neutral[50]}, 0 0 0 4px ${(props) => props.theme.colors.primary.one};
      outline: none;
    }
  }
`
