import { Route, Switch, useRouteMatch } from "react-router-dom"
import MainResultsPage from "./main"
import BookResultsPage from "./main/book"
import ShortResultsPage from "./short"

const ResultsPage = () => {
  const match = useRouteMatch()
  return (
    <Switch>
      <Route path={`${match.path}/main`}>
        <Switch>
          <Route path={`${match.path}/main/book`}>
            <BookResultsPage />
          </Route>
          <Route>
            <MainResultsPage />
          </Route>
        </Switch>
      </Route>
      <Route path={`${match.path}/short`}>
        <ShortResultsPage />
      </Route>
    </Switch>
  )
}

export default ResultsPage
