import { css } from "styled-components"

export const moneyInputStyles = css`
  & .input__leftIcon {
    border-radius: ${({ theme }) => theme.radius.xxs} 0 0 ${({ theme }) => theme.radius.xxs};
  }

  & .input__rightIcon {
    border-radius: 0 ${({ theme }) => theme.radius.xxs} ${({ theme }) => theme.radius.xxs} 0;
  }

  & input {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.Money.gray[40]};
    color: ${({ theme }) => theme.colors.Money.gray[80]};
    border-radius: ${({ theme }) => theme.radius.xxs};

    &:focus {
      box-shadow: 0px 0px 0px 3px ${({ theme }) => theme.colors.Money.brand.primary.plum[100]};
      outline: none;
    }
  }
`
