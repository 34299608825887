import customParseFormat from "dayjs/plugin/customParseFormat"
import utc from "dayjs/plugin/utc"
import dayjs from "dayjs"

dayjs.extend(customParseFormat)
dayjs.extend(utc)

export const outOfHours = () => {
  const now = dayjs().local()
  const start = dayjs().local().hour(9).minute(0).second(0)
  const end = dayjs().local().hour(18).minute(0).second(0)

  if (now.isAfter(start) && now.isBefore(end)) {
    return false
  } else {
    return true
  }
}
