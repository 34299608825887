import { Brands, BrandProp } from "@mojo/types";
import React from "react";
import styled, { css } from "styled-components";
import { getBrand } from "../Theme/brand";
import { mojoRadioButtonStyles } from "./styles/mojo";
import { uswitchRadioButtonStyles } from "./styles/uswitch";
import { moneyRadioButtonStyles } from "./styles/money";
import { confusedRadioButtonStyles } from "./styles/confused";
import { Guid } from "@mojo/utils";

export type RadioProps = React.InputHTMLAttributes<HTMLInputElement> &
  BrandProp & {
    /**
     * Whether the radio control should be hidden.
     * @default false
     */
    hideControl?: boolean;
    subText?: React.ReactNode | undefined;
    value: string;
    children: React.ReactNode;
  };

const RadioButton = React.forwardRef<HTMLInputElement, RadioProps>(
  (
    {
      children,
      brand = "Mojo",
      hideControl = false,
      subText = undefined,
      name = Guid(),
      ...props
    }: RadioProps,
    ref: any
  ) => {
    const id = props.id ?? `${name}-${props.value}`;
    return (
      <StyledRadio $brand={brand} $hideControl={hideControl}>
        <input type="radio" {...props} name={name} id={id} ref={ref} />
        <label htmlFor={id} id={`${id}-label`} className="radio__label">
          <div className="radio__checkmark" />
          <div className="radio__textContainer">
            <div className="radio__text">{children}</div>
            {subText && <div className="radio__subtext">{subText}</div>}
          </div>
        </label>
      </StyledRadio>
    );
  }
);

export const BrandVariantStyles = (brand: Brands) =>
  getBrand(brand, {
    Mojo: mojoRadioButtonStyles,
    Uswitch: uswitchRadioButtonStyles,
    Money: moneyRadioButtonStyles,
    Confused: confusedRadioButtonStyles,
  });

const StyledRadio = styled.div<{
  $brand: Brands;
  $hideControl: boolean;
}>`
  & {
    position: relative;
  }

  & input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  & label {
    display: flex;
    position: relative;
    align-items: center;
    cursor: pointer;
    height: 100%;

    & .radio__text {
      flex: 1 1 0%;
    }

    & .radio__subtext {
      flex: 1 1 100%;
    }

    & .radio__textContainer {
      flex: 1 1 0%;
      display: flex;
      flex-direction: column;
      gap: ${({ theme }) => theme.spacing.xxs};
    }

    &:has(.radio__subtext) .radio__checkmark {
      align-self: flex-start;
    }
  }

  ${({ $brand }) => BrandVariantStyles($brand)};

  ${({ $hideControl }) =>
    $hideControl
      ? css`
          & .radio__checkmark {
            display: none;
          }
        `
      : ""}
`;

export default RadioButton;
