import { useController, useFormContext } from "react-hook-form";
import RadioButton, { RadioProps } from "../RadioButton";
import { useFormItem } from "../../Form";

export interface ControlledInputProps extends RadioProps {}

const RadioButtonControlled = ({ ...rest }: ControlledInputProps) => {
  const form = useFormContext();
  const { name, rules, setValueAs } = useFormItem();

  const { field, fieldState } = useController({
    name: name,
    control: form.control,
    rules,
  });

  const isDefaultChecked =
    field.value ===
    (setValueAs ? setValueAs(rest.value as string) : rest.value);

  return (
    <RadioButton
      {...field}
      defaultChecked={isDefaultChecked}
      {...rest}
      onChange={(e) => field.onChange(setValueAs(e.target.value))}
      className={`${fieldState.invalid ? "invalid" : ""}`}
    />
  );
};

export default RadioButtonControlled;
