import { context } from "./context"
import React from "react"
import { Brands } from "@mojo/types"

/**
 * Provides brand context to its children
 */
const BrandContext = ({ children }: { children: React.ReactNode }) => {
  const host = window.location.host

  /**
   * Depending on the domain we're accessing this from, we switch the brand
   */
  const brand: Brands = React.useMemo(() => {
    const brandSubdomain = host.split(".")[0] as "money" | "uswitch" | "mymojo"
    switch (brandSubdomain) {
      case "money":
        return "Money"
      default:
      case "uswitch":
        return "Uswitch"
      case "mymojo":
        return "Mojo"
    }
  }, [host])

  return <context.Provider value={{ brand }}>{children}</context.Provider>
}

export default BrandContext
