import * as React from "react";
import type { SVGProps } from "react";
const SvgCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 10 9"
    {...props}
  >
    <path stroke="currentColor" strokeWidth={1.692} d="M1 4.176 3.56 7 9 1" />
  </svg>
);
export default SvgCheck;
