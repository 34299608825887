import React from "react"
import { Heading, HeadingProps } from "./Headings"
import { Paragraph, ParagraphProps } from "./Paragraph"

const Typography = () => <></>

/**
 * A 32px H1.
 */
Typography.H1 = (props: HeadingProps) => <Heading size="h1" as="h1" {...props} />

/**
 * A 28px H2.
 */
Typography.H2 = (props: HeadingProps) => <Heading size="h2" as="h2" {...props} />

/**
 * A 26px H3.
 */
Typography.H3 = (props: HeadingProps) => <Heading size="h3" as="h3" {...props} />

/**
 * A 23px H4.
 */
Typography.H4 = (props: HeadingProps) => <Heading size="h4" as="h4" {...props} />

/**
 * A 20px H5.
 */
Typography.H5 = (props: HeadingProps) => <Heading size="h5" as="h5" {...props} />

/**
 * A 26px H6.
 */
Typography.H6 = (props: HeadingProps) => <Heading size="h6" as="h6" {...props} />

/**
 * An 18px Paragraph.
 */
Typography.LargeBody = (props: ParagraphProps) => <Paragraph size="large" {...props} />

/**
 * A 16px Paragraph.
 */
Typography.Body = (props: ParagraphProps) => <Paragraph size="default" {...props} />

/**
 * An 14px Paragraph.
 */
Typography.SmallBody = (props: ParagraphProps) => <Paragraph size="small" {...props} />

/**
 * An 12px Paragraph.
 */
Typography.Caption = (props: ParagraphProps) => <Paragraph size="caption" {...props} />

/**
 * Text wrapped in a span, weight and size can be customised.
 */
Typography.Text = (props: ParagraphProps) => <Paragraph size="default" {...props} as="span" />

export default Typography
