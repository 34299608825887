import styled from "styled-components";

export interface GridProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

/*
Template is for placing content in the main body of the page. 
It has 12 columns on desktop, 6 on tablet, 3 on mobile.
┌─┬─┬─┬─┬─┬─┬─┬─┬─┬─┬─┬─┐  ┌─┬─┬─┬─┬─┬─┐  ┌──┬──┬──┐
│ │ │ │ │ │ │ │ │ │ │ │ │  │ │ │ │ │ │ │  │  │  │  │
│ │ │ │ │ │ │ │ │ │ │ │ │  │ │ │ │ │ │ │  │  │  │  │
│ │ │ │ │ │ │ │ │ │ │ │ │  │ │ │ │ │ │ │  │  │  │  │
│ │ │ │ │ │ │ │ │ │ │ │ │  │ │ │ │ │ │ │  │  │  │  │
└─┴─┴─┴─┴─┴─┴─┴─┴─┴─┴─┴─┘  └─┴─┴─┴─┴─┴─┘  └──┴──┴──┘
*/
export const Grid = (props: GridProps) => <StyledGrid {...props} />;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: ${({ theme }) => theme.spacing.base};

  width: 100%;
  padding: 0 ${({ theme }) => theme.spacing.base};

  ${({ theme }) => theme.breakpoints.md} {
    grid-template-columns: repeat(6, minmax(0, 1fr));
    padding: 0 40px;
  }

  ${({ theme }) => theme.breakpoints.lg} {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    padding: 0 104px;
  }

  @media (min-width: 1232px) {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    max-width: 1232px;
    padding: 0 104px;
  }
`;
