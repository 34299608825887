import { axiosInstance } from "@mojo/utils"

export const fetcher = async (url: string) => {
  const res = await axiosInstance.get(url)

  return res.data
}

export const updater = async (url: string, { arg }: any) => {
  const res = await axiosInstance.put(url, arg)

  return res.data
}

export const poster = async (url: string, { arg }: any) => {
  const res = await axiosInstance.post(url, arg)

  return res.data
}
