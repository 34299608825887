import React from "react";
import { AccordionContext, useAccordion } from "./useAccordion";
import { useSpring, animated } from "@react-spring/web";
import styled, { css, DefaultTheme } from "styled-components";
import Typography from "../Typography";
import useMeasure from "../utils/useMeasure";
import { ChevronDown } from "../Icon";
import { ChevronUp } from "../Icon";

export enum AccordionTypes {
  dark = "dark",
  light = "light",
  white = "white",
  transparent = "transparent",
}

type AccordionItemType = {
  /**
   * Will be rendered as a clickable button
   */
  header: React.ReactNode;
  /**
   * The content that will hide and show when clicking on the header
   */
  content: React.ReactNode;
  /**
   * Whether this should be open by default on first render
   */
  defaultOpen?: boolean;
};

export type AccordionProps = {
  /**
   * What color the accordion should be
   */
  variant?: AccordionTypes;
  /**
   * The items of the accordion
   */
  items: AccordionItemType[];
  /**
   * Callback when an item is opened
   */
  onOpen?: (item: Omit<AccordionItemType, "defaultOpen">) => void;
} & React.HTMLAttributes<HTMLDivElement>;

/**
 * An accessible, animated Accordion powered by React context and hooks.
 * @returns
 */
const Accordion = ({
  items,
  variant = AccordionTypes.dark,
  onOpen,
  className = "",
  ...rest
}: AccordionProps) => {
  // each item needs a unique id or the accordion will
  // get confused if there are multiple accordions components on the page
  const id = React.useId();
  const contextState = React.useState(
    items.map((i, index) => ({
      // if undefined we assume the accordion should be closed
      open: i.defaultOpen === undefined ? false : i.defaultOpen,
      id: `accordion-item-${id}-${index}`,
    }))
  );

  return (
    <AccordionContext.Provider
      value={{
        contextState,
        onOpen,
      }}
    >
      <StyledAccordionContainer
        $variant={variant}
        {...rest}
        className={`blueprint__accordion ${className}`}
      >
        {items.map((i, index) => (
          <AccordionItem
            {...i}
            key={index}
            id={`accordion-item-${id}-${index}`}
          />
        ))}
      </StyledAccordionContainer>
    </AccordionContext.Provider>
  );
};

export type AccordionItemsProps = {
  id: string;
} & Omit<AccordionProps["items"][0], "defaultOpen">;

const AccordionItem = ({ header, content, id }: AccordionItemsProps) => {
  const { isOpen, openAccordionItem, closeAccordionItem, onOpen } =
    useAccordion();
  const open = isOpen(id);

  const [ref, { height }] = useMeasure();

  const props = useSpring({
    height: open ? height : 0,
  });

  const dataStateString = open ? "open" : "closed";

  return (
    <div data-state={dataStateString}>
      <Typography.H6
        id="accordion-item-heading"
        data-testid="accordion-item-heading"
        data-state={dataStateString}
        style={{
          display: "flex",
          margin: 0,
        }}
      >
        <StyledAccordionButton
          type="button"
          aria-expanded={open}
          data-testid="accordion-item-button"
          id={`${id}-button`}
          aria-controls={`${id}-element`}
          data-state={dataStateString}
          onClick={() => {
            if (open) {
              closeAccordionItem(id);
            } else {
              onOpen?.({ header, content });
              openAccordionItem(id);
            }
          }}
        >
          {header}
          {open ? (
            <ChevronUp width={16} height={16} />
          ) : (
            <ChevronDown width={16} height={16} />
          )}
        </StyledAccordionButton>
      </Typography.H6>
      {/* @ts-ignore */}
      <animated.div
        data-testid="accordion-item-element"
        id={`${id}-element`}
        aria-labelledby={`${id}-button`}
        data-state={dataStateString}
        role="region"
        style={{ ...props, overflow: "hidden" }}
      >
        <StyledAccordionItemContent
          ref={ref}
          id="accordion-item-content"
          data-testid="accordion-item-content"
        >
          {/* @ts-ignore */}
          <Typography.Body as="div">{open && content}</Typography.Body>
        </StyledAccordionItemContent>
      </animated.div>
    </div>
  );
};

// All styled components should be kept here

const StyledAccordionContainer = styled.div<{
  $variant: AccordionTypes;
}>`
  ${({ theme, $variant }) => ColorVariantStyles(theme, $variant)};
  padding: 2rem;
  border-radius: ${({ theme }) => theme.radius.base};

  & #accordion-item-heading > button {
    transition: background 200ms;
  }
`;

const StyledAccordionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: transparent;
  border: none;
  flex: 1;
  text-align: left;
  cursor: pointer;
  font-weight: 600;
  padding: 1rem;

  // stops icons from being resized when text is long
  svg {
    flex-shrink: 0;
  }
`;

const StyledAccordionItemContent = styled.div`
  padding: 1rem;
`;

export const ColorVariantStyles = (
  theme: DefaultTheme,
  variant: AccordionTypes
) =>
  ({
    dark: css`
      & {
        background: ${theme.colors.primary.two};
      }

      & #accordion-item-heading {
        border-bottom: 1px solid ${theme.colors.neutral[600]};
      }

      & #accordion-item-heading > button {
        color: ${theme.colors.white};

        &:hover {
          background: ${theme.colors.neutral[700]};
        }

        &:focus {
          outline: 2px solid ${theme.colors.white};
        }
      }

      & #accordion-item-content > div {
        color: ${theme.colors.white};
      }
    `,
    light: css`
      & {
        background: ${theme.colors.secondary.two};
      }

      & #accordion-item-heading {
        border-bottom: 1px solid ${theme.colors.peach[900]};
      }

      & #accordion-item-heading > button {
        color: ${theme.colors.primary.two};

        &:hover {
          background: ${theme.colors.peach[300]};
        }

        &:focus {
          outline: 2px solid ${theme.colors.primary.one};
        }
      }

      & #accordion-item-content > div {
        color: ${theme.colors.primary.two};
      }
    `,
    white: css`
      & {
        background: ${theme.colors.white};
      }

      & #accordion-item-heading {
        border-bottom: 1px solid ${theme.colors.neutral[400]};
      }

      & #accordion-item-heading > button {
        color: ${theme.colors.primary.two};

        &:hover {
          background: ${theme.colors.neutral[100]};
        }

        &:focus {
          outline: 2px solid ${theme.colors.primary.one};
        }
      }

      & #accordion-item-content > div {
        color: ${theme.colors.primary.two};
      }
    `,
    transparent: css`
      & {
        background: transparent;
      }

      & #accordion-item-heading {
        border-bottom: 1px solid ${theme.colors.neutral[400]};
      }

      & #accordion-item-heading > button {
        color: ${theme.colors.primary.two};

        &:hover {
          background: ${theme.colors.neutral[100]};
        }

        &:focus {
          outline: 2px solid ${theme.colors.primary.one};
        }
      }

      & #accordion-item-content > div {
        color: ${theme.colors.primary.two};
      }
    `,
  }[variant]);

export default Accordion;
