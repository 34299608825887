import Typography from "@mojo/blueprint/Typography"
import Template, { Grid, Main } from "@mojo/blueprint/Template"
import styled, { css } from "styled-components"
import { useLocation } from "react-router-dom"
import { Check } from "@mojo/blueprint/Icon"
import woman from "assets/People-2023-Victoria-Pleased-Blueberry.png"
import React from "react"
import wave from "assets/background-wave.svg"
import moneyCompare from "assets/Money-why-compare.png"
import { useBrand } from "components/atoms/brandContext"
import { Brands } from "@mojo/types"

interface ResultPageProps {
  /**
   * Content rendered in the header
   */
  headerContent: React.ReactNode
  children?: React.ReactNode
}

const ResultPage = ({ headerContent, children }: ResultPageProps) => {
  const { brand } = useBrand()

  const location = useLocation()
  const name = new URLSearchParams(location.search).get("name")

  const RenderLogo = () => {
    switch (brand) {
      default:
      case "Uswitch":
        return (
          <img
            className="uswitch__logo"
            src={"https://hosted-assets.mojomortgages.com/images/partners/uswitch-dark.svg"}
          />
        )
      case "Money":
        return (
          <img
            className="money__logo"
            src={"https://hosted-assets.mojomortgages.com/images/partners/money-dark.svg"}
          />
        )
    }
  }

  return (
    <Template
      css={css`
        background-color: #f3f3f3;
        flex: 1 1 0%;
      `}
    >
      <Main>
        <StyledResultPage $brand={brand}>
          <div className="mainResult__header">
            <Grid
              css={css`
                max-width: 1440px;
              `}
            >
              <div className="mainResult__header--left">
                <div className="mainResult__header--logo">
                  <RenderLogo />
                  <div className="mainResult__header--partnership mojo__font">
                    In partnership with
                    <img
                      className="mojo__logo"
                      src={"https://hosted-assets.mojomortgages.com/images/mojo/mojo-logo.svg"}
                    />
                  </div>
                </div>

                {Boolean(name) && (
                  <div className="mainResult__header--name">
                    <div className="check-icon">
                      <Check width={16} height={16} />
                    </div>
                    <Typography.Body as="span" weight={700}>
                      Thanks, {name}
                    </Typography.Body>
                  </div>
                )}

                <div className="mainResult__header--text">{headerContent}</div>
              </div>
              <div className="mainResult__header--right" />
            </Grid>
          </div>

          {children}

          <div className="mainResult__partners mojo__font">
            <Grid>
              <div className="mainResult__partners--container">
                <div className="mainResult__partners--text">
                  <Typography.H3>You're in good company</Typography.H3>
                  <Typography.Body>
                    We’re not some random third party. Mojo is an award-winning broker, who are a
                    part of RVU and Houseful, a collection of some of the UK’s most well-known
                    brands.
                  </Typography.Body>
                </div>
                <div className="mainResult__partners--logos">
                  <img
                    height={30}
                    className="logos__mojo"
                    src="https://hosted-assets.mojomortgages.com/images/mojo/mojo-logo-white.svg"
                  />
                  <img
                    height={28}
                    className="logos__uswitch"
                    src="https://hosted-assets.mojomortgages.com/images/partners/uswitch-light.svg"
                  />
                  <img
                    height={25}
                    className="logos__confused"
                    src="https://hosted-assets.mojomortgages.com/images/partners/confused.svg"
                  />
                  <img
                    height={21}
                    className="logos__zoopla"
                    src="https://hosted-assets.mojomortgages.com/images/partners/zoopla-light.svg"
                  />
                  <img
                    height={26}
                    className="logos__money"
                    src="https://hosted-assets.mojomortgages.com/images/partners/money-white.svg"
                  />
                  <img
                    height={29}
                    className="logos__primelocation"
                    src="https://hosted-assets.mojomortgages.com/images/partners/primelocation-light.svg"
                  />
                </div>
              </div>
            </Grid>
          </div>
          <footer className="mainResult__footer">
            <Grid
              css={css`
                max-width: 1440px;
              `}
            >
              <div className="mainResult__footer--container">
                <img
                  width={85}
                  src="https://hosted-assets.mojomortgages.com/images/mojo/mojo-logo-white.svg"
                />
                <Typography.SmallBody>
                  Mojo is a trading style of Life's Great Limited which is registered in England and
                  Wales (06246376). We are authorised and regulated by the Financial Conduct
                  Authority and are on the Financial Services Register (478215). Our registered
                  office is The Cooperage, 5 Copper Row, London, SE1 2LH, and head office is Mojo
                  Mortgages, WeWork No. 1 Spinningfields, Quay Street, Manchester, M3 3JE.
                </Typography.SmallBody>
                <ul className="mainResult__footer--links">
                  <li>
                    <a
                      href="https://mojomortgages.com/app/terms-and-conditions/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms and Conditions
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://mojomortgages.com/privacy-policy/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://hosted-assets.mojomortgages.com/documents/Terms%20of%20our%20Advisory%20Services.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms of Advisory Service
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://hosted-assets.mojomortgages.com/documents/How%20We%20Help%20You%20Protection.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      How We Help You
                    </a>
                  </li>
                </ul>
              </div>
            </Grid>
          </footer>
        </StyledResultPage>
      </Main>
    </Template>
  )
}

/**
 * A modal like container that fits in the main body of the page
 */
const ResultPageModal = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="mainResult__modal mojo__font">
      <Grid>
        <div className="mainResult__modal--container">{children}</div>
      </Grid>
    </div>
  )
}

ResultPage.Modal = ResultPageModal

const StyledResultPage = styled.div<{
  $brand: Brands
}>`
  flex: 1 1 0%;

  & > div,
  & > footer {
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mainResult__header {
    background-color: ${({ theme, $brand }) => {
      switch ($brand) {
        case "Uswitch":
          return theme.colors.Uswitch.brand.blue.primary
        case "Money":
          return theme.colors.white
      }
    }};
    padding-top: ${({ theme }) => theme.spacing.lg};

    ${({ theme }) => theme.breakpoints.md} {
      padding-top: ${({ theme }) => theme.spacing["4xl"]};
    }

    .mainResult__header--left {
      display: flex;
      flex-direction: column;
      gap: ${({ theme }) => theme.spacing.base};
      padding-bottom: ${({ theme }) => theme.spacing["6xl"]};

      grid-column: 1 / -1;

      ${({ theme }) => theme.breakpoints.md} {
        grid-column: 1 / 6;
      }

      ${({ theme }) => theme.breakpoints.lg} {
        grid-column: 1 / 8;
      }

      .mainResult__header--text {
        display: flex;
        flex-direction: column;

        .mainResult__header--freeHighlight {
          padding: ${({ theme }) => theme.spacing.xxs} ${({ theme }) => theme.spacing.base};
          font-weight: 700;

          ${({ $brand, theme }) => {
            switch ($brand) {
              case "Uswitch":
                return css`
                  background-color: rgba(80, 240, 148, 1);
                `
              case "Money":
                return css`
                  background-color: ${theme.colors.Money.brand.primary.plum[100]};
                  border-radius: 200px;
                  color: white;
                `
            }
          }}
        }
      }

      .mainResult__header--name {
        ${({ $brand, theme }) => {
          switch ($brand) {
            case "Uswitch":
              return css`
                color: ${theme.colors.Mojo.primary.two};
              `
            case "Money":
              return css`
                color: ${theme.colors.Money.brand.primary.blueberry[100]};
              `
          }
        }}

        display: flex;
        align-items: center;
        gap: ${({ theme }) => theme.spacing.xs};

        .check-icon {
          display: inline-flex;
          align-items: center;
          border-radius: ${({ theme }) => theme.radius.lg};
          padding: ${({ theme }) => theme.spacing.xxs};

          ${({ $brand, theme }) => {
            switch ($brand) {
              case "Uswitch":
                return css`
                  background-color: ${({ theme }) => theme.colors.primary.two};
                `
              case "Money":
                return css`
                  background-color: ${theme.colors.Money.brand.primary.blueberry[100]};
                `
            }
          }}

          color: white;
        }
      }

      .mainResult__header--logo {
        display: flex;
        align-items: center;
        gap: ${({ theme }) => theme.spacing.xl};
        justify-content: space-between;

        ${({ theme }) => theme.breakpoints.md} {
          justify-content: baseline;
        }

        .uswitch__logo,
        .money__logo {
          width: 120px;
        }

        .mojo__logo {
          width: 66px;
        }

        ${({ theme }) => theme.breakpoints.md} {
          .uswitch__logo,
          .money__logo {
            width: 190px;
          }

          .mojo__logo {
            width: 87px;
          }
        }

        .mainResult__header--partnership {
          display: inline-flex;
          flex-direction: column;

          ${({ theme }) => theme.breakpoints.md} {
            flex-direction: row;
          }

          align-items: center;
          gap: ${({ theme }) => theme.spacing.xs};
          border-radius: ${({ theme }) => theme.radius.xl};

          font-size: 14px;

          ${({ $brand, theme }) => {
            switch ($brand) {
              case "Uswitch":
                return css`
                  background-color: white;
                `
              case "Money":
                return css`
                  background-color: ${theme.colors.Mojo.secondary.one};
                `
            }
          }}

          padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.md};
        }
      }

      .mainResult__header--heading {
        h1 {
          ${({ $brand, theme }) => {
            switch ($brand) {
              case "Uswitch":
                return css`
                  color: ${theme.colors.Mojo.primary.two};
                `
              case "Money":
                return css`
                  color: ${theme.colors.Money.brand.primary.plum[100]};
                `
            }
          }}
        }
      }

      .mainResult__header--headingText {
        margin-bottom: 1rem;
        .bold {
          font-weight: 700;
        }
        ${({ $brand, theme }) => {
          switch ($brand) {
            case "Uswitch":
              return css`
                color: ${theme.colors.Mojo.primary.two};
              `
            case "Money":
              return css`
                color: ${theme.colors.Money.gray[100]};
              `
          }
        }}
      }
    }

    .mainResult__header--right {
      ${({ $brand }) => {
        switch ($brand) {
          case "Uswitch":
            return css`
              background-image: url("${woman}");
            `
          case "Money":
            return css`
              background-image: url("${moneyCompare}");
            `
        }
      }}

      grid-column: 1 / -1;

      ${({ theme }) => theme.breakpoints.md} {
        grid-column: 1 / 6;
      }

      ${({ theme }) => theme.breakpoints.lg} {
        grid-column: 8 / 13;
      }
    }
  }

  .mainResult__modal {
    max-width: 1232px;
    width: 100%;
    display: flex;

    .mainResult__modal--container {
      grid-column: 1 / -1;

      margin-top: -${({ theme }) => theme.spacing["6xl"]};

      gap: ${({ theme }) => theme.spacing.xl};

      display: grid;

      grid-template-columns: repeat(1, minmax(0px, 1fr));

      ${({ theme }) => theme.breakpoints.lg} {
        grid-template-columns: repeat(2, minmax(0px, 1fr));
      }

      background-color: #eff3fe;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom left;
      background-image: url("${wave}");
      border: 5px solid white;
      border-radius: ${({ theme }) => theme.radius.lg};
      ${({ theme }) => theme.shadows.lg}
    }
  }

  .mainResult__partners {
    .mainResult__partners--container {
      grid-column: 1 / -1;

      display: flex;
      flex-direction: column;
      gap: ${({ theme }) => theme.spacing.base};

      padding: ${({ theme }) => theme.spacing["5xl"]} 0;

      .mainResult__partners--text {
        padding: 0 ${({ theme }) => theme.spacing.md};
      }

      .mainResult__partners--logos {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: ${({ theme }) => theme.spacing.xl};

        ${({ theme }) => theme.breakpoints.lg} {
          flex-direction: row;
        }

        justify-content: space-evenly;

        background-color: #201f2d;
        border-radius: ${({ theme }) => theme.radius.md};
        padding: ${({ theme }) => theme.spacing["3xl"]};

        .logos__mojo {
          width: 116px;
        }

        .logos__uswitch {
          width: 120px;
        }

        .logos__confused {
          width: 125px;
        }

        .logos__zoopla {
          width: 115px;
        }

        .logos__money {
          width: 128px;
        }

        .logos__primelocation {
          width: 178px;
        }

        ${({ theme }) => theme.breakpoints.lg} {
          .logos__mojo {
            width: 104px;
          }

          .logos__uswitch {
            width: 112px;
          }

          .logos__confused {
            width: 141px;
          }

          .logos__zoopla {
            width: 115px;
          }

          .logos__money {
            width: 128px;
          }

          .logos__primelocation {
            width: 178px;
          }
        }
      }
    }
  }

  .mainResult__footer {
    background-color: ${({ theme }) => theme.colors.Mojo.neutral[900]};
    color: white;

    ${({ theme }) => theme.breakpoints.md} {
      grid-column: 1 / 6;
    }

    padding-top: ${({ theme }) => theme.spacing["3xl"]};
    padding-bottom: ${({ theme }) => theme.spacing["3xl"]};

    .mainResult__footer--container {
      grid-column: 1 / -1;

      display: flex;
      flex-direction: column;
      gap: ${({ theme }) => theme.spacing.lg};

      .mainResult__footer--links {
        display: flex;
        flex-wrap: wrap;
        gap: ${({ theme }) => theme.spacing.base};

        padding-left: 0;

        li {
          list-style-type: none;
        }

        a {
          color: white;
          text-decoration: none;

          font-weight: 600;
        }

        a:hover {
          text-decoration: underline;
        }
      }
    }
  }
`

export default ResultPage
