import { ReactGAImplementation } from "react-ga4";

/**
 * We extend the main `react-ga4` class to implement the `user_properties` scope.
 */
class CustomGA extends ReactGAImplementation {
  user = (fieldsObject: any) => {
    if (!fieldsObject) {
      console.warn("`fieldsObject` is required in .set()");

      return;
    }

    if (typeof fieldsObject !== "object") {
      console.warn("Expected `fieldsObject` arg to be an Object");

      return;
    }

    if (Object.keys(fieldsObject).length === 0) {
      console.warn("empty `fieldsObject` given to .set()");
    }

    this._gtag("set", "user_properties", fieldsObject);
  };
}

export default new CustomGA();
