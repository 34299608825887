import { transparentize } from "polished";
import { css } from "styled-components";

export const mojoTabsStyles = css`
  & .tabs__list {
    background-color: ${({ theme }) => theme.colors.vivid[0]};
    border-radius: ${({ theme }) => theme.radius.xl};
  }

  & .tabs__trigger {
    border-radius: 0;
    padding: ${({ theme }) => theme.spacing.sm};

    &[data-state="active"] {
      color: ${({ theme }) => theme.colors.primary.one};
      border-radius: ${({ theme }) => theme.radius.xl};
      background-color: ${({ theme }) => theme.colors.vivid[50]};
    }
    &[data-state="inactive"] {
      background-color: transparent;
      color: ${({ theme }) => theme.colors.neutral[500]};
    }
  }
`;
