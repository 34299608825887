"use client";
import React from "react";
import styled from "styled-components";
import Button, { ButtonType } from "../Button";
import { BrandProp, Brands } from "@mojo/types";
import { getBrand } from "../Theme/brand";
import { createPortal } from "react-dom";
import { mojoCookieBannerStyles } from "./styles/mojo";
import { uswitchCookieBannerStyles } from "./styles/uswitch";
import { moneyCookieBannerStyles } from "./styles/money";
import { confusedCookieBannerStyles } from "./styles/confused";
import { readCookie, setCookie } from "./utils/cookies";

export type CookieBannerProps = React.HTMLAttributes<HTMLDivElement> &
  BrandProp & {
    /**
     * Event handler called when the user clicks to accept essential cookies
     */
    onClickEssential?: () => void;
    /**
     * Event handler called when the user clicks to accept all cookies
     */
    onClickAll?: () => void;
  };

/**
 * A branded cookie banner component
 */
const CookieBanner = React.forwardRef<HTMLDivElement, CookieBannerProps>(
  (
    {
      brand = "Mojo",
      onClickEssential = () => {
        /* do nothing */
      },
      onClickAll = () => {
        /* do nothing */
      },
      ...props
    }: CookieBannerProps,
    ref
  ) => {
    const [isOpen, setIsOpen] = React.useState(false);

    // ensures the cookie logic is only ran on the client
    React.useEffect(() => {
      if (
        readCookie("allow-essential") === "true" ||
        readCookie("allow-marketing") === "true"
      ) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
    }, []);

    React.useEffect(() => {
      const cookieBanner = document.querySelector(
        "#cookieBanner"
      ) as HTMLInputElement;
      if (cookieBanner) cookieBanner.focus();
    }, [isOpen]);

    /**
     * Runs when user allows essential cookies only
     */
    const doClickEssential = () => {
      onClickEssential();
      setCookie("allow-essential", "true");
      setIsOpen(false);
    };

    /**
     * Runs when user allows all cookies
     */
    const doClickAll = () => {
      onClickAll();
      setCookie("allow-marketing", "true");
      setIsOpen(false);
    };

    return isOpen
      ? createPortal(
          <Overlay>
            <StyledBanner
              id={"cookieBanner"}
              ref={ref}
              $brand={brand}
              role="region"
              aria-label="Cookie banner"
              tabIndex={0}
              {...props}
            >
              <div
                className="cookieBanner__text"
                role="dialog"
                aria-label="Cookie disclaimer"
              >
                <p>
                  By clicking “Accept all cookies”, you agree to the storing of
                  cookies on your device to enhance site navigation, analyse
                  site usage, and assist in our marketing efforts. Read more on
                  our{" "}
                  <a
                    href="https://mojomortgages.com/cookies/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Cookie Policy page
                  </a>
                  .
                </p>
              </div>
              <div
                className="cookieBanner__buttons"
                role="group"
                aria-label="Cookie buttons"
              >
                <Button
                  brand={brand}
                  className="cookieBanner__button cookieBanner__essentialButton "
                  variant={ButtonType.Secondary}
                  onClick={() => doClickEssential()}
                >
                  Essential cookies only
                </Button>
                <Button
                  brand={brand}
                  className="cookieBanner__button cookieBanner__allButton "
                  variant={ButtonType.Primary}
                  onClick={() => doClickAll()}
                >
                  Accept all cookies
                </Button>
              </div>
            </StyledBanner>
          </Overlay>,
          document.body
        )
      : null;
  }
);

export const CookieBannerBrandVariantStyles = (brand: Brands) =>
  getBrand(brand, {
    Mojo: mojoCookieBannerStyles,
    Uswitch: uswitchCookieBannerStyles,
    Money: moneyCookieBannerStyles,
    Confused: confusedCookieBannerStyles,
  });

const StyledBanner = styled.div<{
  $brand: Brands;
}>`
  width: calc(100% - 16px);
  max-width: 1280px;
  background: white;
  border-radius: ${({ theme }) => theme.radius.md}
    ${({ theme }) => theme.radius.md} 0 0;
  padding: ${({ theme }) => theme.spacing.md};

  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.md};

  /* Stick to bottom */
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;

  @media (max-width: 640px) {
    flex-direction: column;
    .cookieBanner__button {
      width: 100%;
    }
  }

  .cookieBanner__text {
    display: flex;
    align-items: center;
    max-width: 576px;
  }

  .cookieBanner__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: ${({ theme }) => theme.spacing.md};
    @media (max-width: 640px) {
      flex-direction: column;
    }

    text-wrap: nowrap;

    .cookieBanner__button {
      flex: 1;
    }
  }

  &:focus {
    outline: none;
  }

  ${({ $brand }) => CookieBannerBrandVariantStyles($brand)}
`;

const Overlay = styled.div`
  background-color: hsl(0deg 0% 0% / 30%);
  position: fixed;
  inset: 0;
`;

export default CookieBanner;
