import { css } from "styled-components";

export const moneyComboboxButtonStyles = css`
  & .combobox__button {
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.Money.gray[40]};
    color: ${({ theme }) => theme.colors.Money.brand.primary.blueberry[100]};
    border-radius: ${({ theme }) => theme.radius.xxs};

    &:disabled {
      background-color: ${({ theme }) => theme.colors.Money.gray[5]};
      cursor: not-allowed;
    }

    &:focus {
      box-shadow: 0 0 0 2px
        ${({ theme }) => theme.colors.Money.brand.primary.plum[100]};
      outline: none;
    }
  }

  & .combobox__placeholder {
    color: ${({ theme }) => theme.colors.neutral[500]};
  }

  & .combobox__pill {
    background-color: #f1f1f3;
    color: #6d6f73;
    border-radius: ${({ theme }) => theme.radius.lg};
  }
`;

export const moneyComboboxPopoverStyles = css`
  border: 1px solid ${({ theme }) => theme.colors.Money.gray[40]};
  background-color: ${({ theme }) => theme.colors.white};
  overflow: hidden;
  border-radius: ${({ theme }) => theme.radius.xxs};

  & .combobox__commandinput {
    border: none;

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  & [cmdk-item]:not(:first-child),
  & [cmdk-list] {
    border-top: 1px solid ${({ theme }) => theme.colors.Uswitch.gray[40]};
  }

  & .combobox__commanditem[aria-selected="true"] {
    background-color: ${({ theme }) => theme.colors.Money.gray[10]};
  }
`;
