import { Shades } from "./mojo";

interface SecondaryVariants {
  25: string;
  50: string;
  100: string;
}

export interface MoneyColors {
  gray: {
    0: string;
    5: string;
    10: string;
    20: string;
    30: string;
    40: string;
    50: string;
    60: string;
    70: string;
    80: string;
    90: string;
    100: string;
  };
  semantic: {
    warning: string;
  };
  brand: {
    primary: {
      sunshine: {
        25: string;
        50: string;
        100: string;
        125: string;
      };
      blueberry: {
        25: string;
        50: string;
        100: string;
      };
      fuchsia: {
        100: string;
      };
      plum: {
        100: string;
      };
    };
    secondary: {
      burnt: SecondaryVariants;
      jaffa: SecondaryVariants;
      berry: SecondaryVariants;
    };
  };
}

export const moneyColors: MoneyColors = {
  gray: {
    0: "#ffffff",
    5: "#F7F7F9",
    10: "#F1F1F3",
    20: "#DFDFE1",
    30: "#C2C4C8",
    40: "#ADAFB3",
    50: "#A0A2A6",
    60: "#6D6F73",
    70: "#393B3F",
    80: "#24262A",
    90: "#111317",
    100: "#000000",
  },
  semantic: {
    warning: "#D64226",
  },
  brand: {
    primary: {
      sunshine: {
        "25": "#FEEBBE",
        "50": "#FEE19F",
        "100": "#FEC340",
        "125": "#FEAF00",
      },
      blueberry: {
        "25": "#EBECED",
        "50": "#99A2A6",
        "100": "#34454E",
      },
      fuchsia: {
        "100": "#DB4D75",
      },
      plum: {
        "100": "#924A8B",
      },
    },
    secondary: {
      berry: {
        "100": "#A64470",
        "50": "#DBB4C5",
        "25": "#F6ECF0",
      },
      burnt: {
        "100": "#FAA73F",
        "50": "#FCDBB7",
        "25": "#FEF6ED",
      },
      jaffa: {
        "100": "#F4793B",
        "50": "#FAC9B0",
        "25": "#FEF1EC",
      },
    },
  },
};
