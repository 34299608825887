import React from "react"
import Form from "@mojo/blueprint/Form"
import Select from "@mojo/blueprint/Select/Controlled"
import ControlledInput from "@mojo/blueprint/Input/Controlled"
import Button, { ButtonType } from "@mojo/blueprint/Button"
import Typography from "@mojo/blueprint/Typography"
import styled from "styled-components"
import { useFormContext } from "react-hook-form"
import { usePostcodeAutocomplete } from "hooks/data/usePostcodeAutocomplete"
import Loader from "components/atoms/loader"
import { useBrand } from "components/atoms/brandContext"
import Notice from "components/atoms/notice"
import { FactFindStep } from "config/types"

type AddressProps = FactFindStep<any>

/**
 * Renders a form item which allows inputting address
 */
const Address = ({ description }: AddressProps) => {
  const { brand } = useBrand()
  const form = useFormContext()
  const postcode = form.watch("details.address.postcode")
  const postcodeRegex = new RegExp(/^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const postcodeIsValid = React.useMemo(() => postcodeRegex.test(postcode), [postcode])
  const { data, isLoading } = usePostcodeAutocomplete(postcodeIsValid ? postcode : undefined)
  const [inputManually, setInputManually] = React.useState(false)
  const rules = {
    required: {
      value: true,
      message: "This input is required",
    },
  }

  const switchInputModes = () => {
    setInputManually(!inputManually)
    form.resetField("details.address")
  }

  return (
    <StyledNameAndTitle $isLoading={isLoading}>
      {inputManually ? (
        <>
          <div className="heading__container">
            <Typography.H4 as="span">Address</Typography.H4>
            <span className="address__customDescription">
              <Typography.LargeBody as="span">{description}</Typography.LargeBody>
            </span>
          </div>
          <Form.Item name="details.address.number" label="Number/Name" rules={rules}>
            <ControlledInput placeholder="Number/Name" brand={brand} />
          </Form.Item>
          <Form.Item name="details.address.street" label="Street" rules={rules}>
            <ControlledInput placeholder="Street" brand={brand} />
          </Form.Item>
          <Form.Item name="details.address.city" label="City" rules={rules}>
            <ControlledInput placeholder="City" brand={brand} />
          </Form.Item>
          <Form.Item name="details.address.postcode" label="A01 1AB" rules={rules}>
            <ControlledInput placeholder="Postcode" brand={brand} />
          </Form.Item>
          <Notice>
            <div className="addressInput__switchContainer">
              <Typography.Body weight={600}>
                Go back to our automatic postcode lookup
              </Typography.Body>
              <Button
                type="button"
                brand={brand}
                variant={ButtonType.Link}
                className="addressInput__switchButton"
                onClick={() => {
                  switchInputModes()
                }}
              >
                Find address from postcode
              </Button>
            </div>
          </Notice>
        </>
      ) : (
        <>
          <div className="heading__container">
            <Typography.H4 as="span">Address</Typography.H4>
            <span className="address__customDescription">
              <Typography.LargeBody as="span">{description}</Typography.LargeBody>
            </span>
          </div>
          <Form.Item
            name="details.address.postcode"
            label="Postcode"
            rules={{
              pattern: {
                value: postcodeRegex,
                message: "Please type a valid postcode.",
              },
            }}
          >
            <ControlledInput placeholder="A01 1AB" brand={brand} />
          </Form.Item>

          <Form.Item
            name="details.address.foundAddress"
            label="Select your address"
            rules={{
              required: {
                value: true,
                message: "Please use your postcode to search for your address.",
              },
            }}
          >
            <div className="addressInput__selectContianer">
              <Select disabled={isLoading} brand={brand}>
                {data && data.length > 0 ? (
                  <>
                    <option disabled value="">
                      Select an address
                    </option>{" "}
                    {data.map((d) => (
                      <option key={d.id} value={d.address}>
                        {d.address}
                      </option>
                    ))}
                  </>
                ) : (
                  <option disabled value="">
                    {isLoading ? "Loading..." : "No addresses found"}
                  </option>
                )}
              </Select>

              {isLoading && (
                <div className="addressInput__selectLoader">
                  <Loader />
                </div>
              )}
            </div>
          </Form.Item>
          <Notice>
            <div className="addressInput__switchContainer">
              <Typography.Body weight={600}>
                Can’t find it? Use our manual address input instead
              </Typography.Body>
              <Button
                brand={brand}
                type="button"
                variant={ButtonType.Link}
                className="addressInput__switchButton"
                onClick={() => {
                  switchInputModes()
                }}
              >
                Enter address manually
              </Button>
            </div>
          </Notice>
        </>
      )}
    </StyledNameAndTitle>
  )
}

const StyledNameAndTitle = styled.div<{
  $isLoading: boolean
}>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.base};

  .heading__container {
    display: flex;
    flex-direction: column;

    .address__customDescription {
      color: ${({ theme }) => theme.colors.Mojo.neutral[500]};
    }
  }

  .item__label,
  .item__label label,
  .item__labelcontainer {
    margin-bottom: 0 !important;
    font-size: 0 !important;
  }

  .addressInput__selectContianer {
    position: relative;

    .addressInput__selectLoader {
      position: absolute;
      inset: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    select {
      opacity: ${({ $isLoading }) => ($isLoading ? 0.5 : 1)};
    }
  }

  .addressInput__switchContainer {
    .addressInput__switchButton {
      min-height: auto !important;
      color: ${({ theme }) => theme.colors.primary.two}!important;
      text-decoration: underline;

      .button__internal {
        padding: 0 !important;
      }
    }
  }
`

export default Address
