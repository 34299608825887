import React from "react";
import { useController, useFormContext } from "react-hook-form";
import Select, { SelectProps } from "../Select";
import { useFormItem } from "../../Form";

export interface ControlledInputProps extends SelectProps {}

const SelectControlled = ({ children, ...rest }: ControlledInputProps) => {
  const childrenArray = React.Children.toArray(children) as JSX.Element[];

  const form = useFormContext();
  const { name, rules, setValueAs } = useFormItem();

  const { field, fieldState } = useController({
    name: name,
    control: form.control,
    rules,
    defaultValue: rest.defaultValue || "",
  });

  const defaultValue = childrenArray.find(
    (child) => setValueAs(child.props.value) === field.value
  )?.props.value;

  return (
    <Select
      {...rest}
      {...field}
      id={field.name}
      value={defaultValue}
      onChange={(e) => field.onChange(setValueAs(e.target.value))}
      className={`${fieldState.invalid ? "invalid" : ""}`}
    >
      {children}
    </Select>
  );
};

export default SelectControlled;
