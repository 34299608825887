import { css } from "styled-components";

export const mojoChainedListStyles = css`
  .bullet__item::before {
    background: ${(props) => props.theme.colors.vivid[500]};
  }

  .bullet__point::after {
    color: ${(props) => props.theme.colors.white};
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 27px;

  }

  .bullet__point::before {
    background: ${(props) => props.theme.colors.vivid[500]};
  }

  .bullet__title {
    font-weight: 600;
    line-height: 22px;
    color: #000928;
  }
  .bullet__text {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 25.2px;
    color: #6C6F7A;
  }
`;
