import Typography from "@mojo/blueprint/Typography"
import Button from "@mojo/blueprint/Button"
import styled, { css } from "styled-components"
import { aig, aviva, legalAndGeneral, lv, royalLondon, vitality } from "assets/insurers"
import React from "react"
import { useBrand } from "components/atoms/brandContext"
import ResultPage from "components/organisms/result"
import BulletPoint from "components/atoms/bulletpoint"
import { useHistory } from "react-router-dom"
import ComponentShown from "components/atoms/componentShown"
import { OutOfHoursExperiment, OutOfHoursExperimentId } from "../../../Experiments"
import { GetExperimentState } from "@mojo/bucketing"

const MainResultsPage = () => {
  const { brand } = useBrand()
  const history = useHistory()

  const experiment = GetExperimentState<OutOfHoursExperiment>(OutOfHoursExperimentId)

  return (
    <ResultPage
      headerContent={
        <>
          <div className="mainResult__header--heading">
            <Typography.H1>
              {experiment === null || experiment === OutOfHoursExperiment.CallYouSoon
                ? "One of the experts at Mojo will call you as soon as possible"
                : "Book your call with a Mojo Expert"}
            </Typography.H1>
          </div>
          <div className="mainResult__header--headingText">
            <Typography.Body>
              <span className="bold">Opening hours:</span> 9am - 6pm Monday to Friday.
            </Typography.Body>
            <Typography.Body>
              At {brand}, we take life insurance seriously, so we let the experts take over when the
              time is right.
            </Typography.Body>
            <Typography.Body>
              Mojo are our trusted partners and an award winning broker, who will recommend a policy
              based on your personal circumstances
            </Typography.Body>
            <Typography.Body>
              Our service is quick, <span className="mainResult__header--freeHighlight">free</span>{" "}
              and will give you the quotes you were looking for.
            </Typography.Body>

            {experiment === null || experiment === OutOfHoursExperiment.CallYouSoon ? (
              <ComponentShown componentName="Protection Expert Call as soon as possible" />
            ) : (
              <div
                css={css`
                  display: flex;
                  justify-content: center;
                `}
              >
                <ComponentShown componentName="book a call with an expert" />
                <Button
                  brand={brand}
                  onClick={() => {
                    history.push(`${history.location.pathname}/book`)
                  }}
                >
                  Book your call
                </Button>
              </div>
            )}
          </div>
        </>
      }
    >
      <StyledModal>
        <ResultPage.Modal>
          <div className="mainResult__modal--illustration">
            <img
              width={160}
              src="https://hosted-assets.mojomortgages.com/images/mojo/mojo-logo.svg"
            />
            <img
              width={"85%"}
              src="https://hosted-assets.mojomortgages.com/images/illustrations/bike.png"
            />
          </div>

          <div className="mainResult__modal--howItWorks">
            <Typography.H3>How it works</Typography.H3>
            <Typography.Body>Your expert will:</Typography.Body>
            <ol>
              <BulletPoint number={1}>Explore your needs and circumstances.</BulletPoint>
              <BulletPoint number={2}>
                Find you the right insurance and make a recommendation.
              </BulletPoint>
              <BulletPoint number={3}>Handle the application for you.</BulletPoint>
            </ol>
          </div>

          <div className="mainResult__modal--weWorkWith">
            <Typography.H3>Who we work with</Typography.H3>
            <Typography.Body>We compare policies from 15 insurers.</Typography.Body>
            <div className="mainResult__modal--insurerGrid">
              <img src={aig} />
              <img src={aviva} />
              <img src={legalAndGeneral} />
              <img src={lv} />
              <img src={royalLondon} />
              <img src={vitality} />
            </div>
          </div>
        </ResultPage.Modal>
      </StyledModal>
    </ResultPage>
  )
}

const StyledModal = styled.div`
  .mainResult__modal--container {
    padding: ${({ theme }) => theme.spacing.md};

    ${({ theme }) => theme.breakpoints.lg} {
      padding: ${({
        theme: {
          spacing: { xl, "3xl": threeXl },
        },
      }) => `${xl} ${threeXl}`};
    }
  }

  .mainResult__modal--illustration {
    grid-column: 1 / 1;
    grid-row: 1 / 3;

    display: none;

    ${({ theme }) => theme.breakpoints.lg} {
      display: flex;
    }

    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    img {
      max-width: 100%;
    }
  }

  .mainResult__modal--howItWorks {
    ol {
      display: flex;
      flex-direction: column;
      gap: ${({ theme }) => theme.spacing.base};

      padding-left: 0;

      li {
        list-style-type: none;
      }
    }
  }

  .mainResult__modal--weWorkWith {
    .mainResult__modal--insurerGrid {
      margin-top: ${({ theme }) => theme.spacing.lg};

      display: grid;
      grid-template-columns: repeat(3, minmax(0px, 1fr));
      gap: ${({ theme }) => theme.spacing.lg};
    }
  }
`

export default MainResultsPage
