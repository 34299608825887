import { css } from "styled-components";

export const confusedCookieBannerStyles = css`
  a {
    color: ${({ theme }) => theme.colors.black};
    text-decoration: underline;
    transition: color 0.3s ease;

    &:hover { color: purple; }
    &:active { color: purple; }
    &:visited { color: purple; }
  }
`;
