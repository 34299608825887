import Typography from "@mojo/blueprint/Typography"
import styled from "styled-components"
import React from "react"
import ResultPage from "components/organisms/result"
import { InlineWidget, useCalendlyEventListener } from "react-calendly"
import ComponentShown from "components/atoms/componentShown"
import { Event } from "@mojo/analytics"

const BookResultsPage = () => {
  useCalendlyEventListener({
    onEventScheduled: () => {
      Event("Appointment Booked", {
        action: "Appointment Booked",
        event_category: "Functional Interaction",
        event_label: "Appointment Booked",
      })
    },
  })
  return (
    <ResultPage
      headerContent={
        <>
          <ComponentShown componentName="d2c_calendar" />
          <div className="mainResult__header--heading">
            <Typography.H1>Book your call with a Mojo Expert</Typography.H1>
          </div>
          <div className="mainResult__header--headingText">
            <Typography.Body>
              <span className="bold">Opening hours:</span> 9am - 6pm Monday to Friday.
            </Typography.Body>
          </div>
        </>
      }
    >
      <StyledModal>
        <ResultPage.Modal>
          <div className="calendlyContainer">
            <InlineWidget url="https://calendly.com/insurance-booking/mojo-protection-appointment-d2c?month=2023-12" />
          </div>
        </ResultPage.Modal>
      </StyledModal>
    </ResultPage>
  )
}

const StyledModal = styled.div`
  /* .mainResult__modal--container {
    margin-top: -15rem !important;
  } */

  .mainResult__header--right {
    background-image: none !important;
  }

  .calendlyContainer {
    width: 100%;
    grid-column: 1 / -1;

    padding: ${({ theme }) => theme.spacing.md} 0;
  }
`

export default BookResultsPage
