import React from "react"
import { ComponentShownEvent } from "@mojo/analytics"

/**
 * If this component is rendered it sends a component shown event.
 */
const ComponentShown = ({
  componentName,
  params,
}: {
  componentName: string
  params?: { [key: string]: string }
}) => {
  React.useEffect(() => {
    ComponentShownEvent(componentName, params)
  }, [componentName, params])
  return <></>
}

export default ComponentShown
