import { css } from "styled-components";
import CheckIcon from "../assets/mojo/check.svg";

export const mojoCheckboxStyles = css`
  & input {
    &:checked {
      + label {
        font-weight: 500;

        .check__checkmark {
          background: ${(props) => props.theme.colors.primary.two};

          &:after {
            transform: scale(1);
            filter: brightness(0%) invert(100%);
          }
        }
      }
    }

    &.invalid + label {
      box-shadow: 0 0 0 2px ${(props) => props.theme.colors.error[500]};
    }

    &:not(.invalid):focus + label {
      box-shadow: 0 0 0 2px ${(props) => props.theme.colors.primary.one};
    }

    &:disabled + label {
      opacity: 0.2;
    }
  }

  & label {
    padding: 8px;
    line-height: 180%;
    border-radius: ${(props) => props.theme.radius.xs};
    transition: 0.2s;
    color: ${(props) => props.theme.colors.primary.two};

    &:hover {
      background: ${(props) => props.theme.colors.neutral[50]};
      box-shadow: 0 0 0 1px ${(props) => props.theme.colors.neutral[200]};
    }
  }

  & .check__checkmark {
    width: 16.5px;
    height: 16.5px;
    box-shadow: 0 0 0 1.5px rgb(20, 20, 36);
    margin-right: 16px;
    border-radius: 4px;
    position: relative;
    flex-shrink: 0;

    &:after {
      content: "";
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 12px;
      height: 12px;
      transform: scale(0) rotate(45deg);
      transform-origin: center;
      transition: 0.2s;
      background-image: url("${CheckIcon}");
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  & .check__text {
    font-size: 16px;
    font-weight: 500;
  }
`;
