import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import { Theme } from "@mojo/blueprint/Theme"
import { BrowserRouter as Router } from "react-router-dom"
import GlobalStyle from "components/atoms/globalStyle/globalStyle"
import { SWRConfig } from "swr"
import initSentry from "./sentry"
import BrandContext from "components/atoms/brandContext"
import dayjs from "dayjs"

initSentry()

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Theme>
      <BrandContext>
        <SWRConfig
          value={{
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            revalidateIfStale: false,
            shouldRetryOnError: false,
          }}
        >
          <GlobalStyle />
          <Router>
            <App />
          </Router>
        </SWRConfig>
      </BrandContext>
    </Theme>
  </React.StrictMode>
)
