import { css } from "styled-components";

export const moneyTabsStyles = css`
  & .tabs__list {
    gap: ${({ theme }) => theme.spacing.xxs};
  }

  & .tabs__trigger {
    border-radius: ${({ theme }) => theme.radius.xl};
    padding: ${({ theme }) => theme.spacing.sm};

    &[data-state="active"] {
      color: ${({ theme }) => theme.colors.Money.gray[0]};
      background-color: ${({ theme }) =>
        theme.colors.Money.brand.primary.blueberry[100]};
    }
    &[data-state="inactive"] {
      background-color: ${({ theme }) => theme.colors.Money.gray[0]};
      font-weight: 400;
      color: ${({ theme }) => theme.colors.Money.brand.primary.blueberry[100]};
      border: 1px solid ${({ theme }) => theme.colors.Money.gray[40]};
    }
  }
`;
