import { lighten, transparentize } from "polished";
import { css } from "styled-components";

export const mojoButtonStyles = css`
  & {
    border-radius: 10px;
    padding: 0px;
    box-sizing: border-box;
    text-align: center;
    position: relative;
    transition: width 0.7s;
    cursor: pointer;
    user-select: none;
    border: 0;
    outline: 0;
  }

  & .button__internal {
    display: flex;
    align-content: center;
    justify-content: center;
    border-radius: 10px;
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.disabled {
    & .button__internal {
      background: ${(props) => props.theme.colors.neutral[200]} !important;

    }

    background: transparent !important;
    color: ${(props) => props.theme.colors.neutral[500]} !important;
    fill: ${(props) => props.theme.colors.neutral[500]} !important;
    box-shadow: none !important;
  }

  // To handle buttons with a shadow we use some tricks.
  // There's actually an invisible div within the button which we only use to create the raised part of the button. Both have backgrounds, we move the internal one to "press" it.
  &.primary {
    background: ${(props) => props.theme.colors.vivid[700]};
    color: white;
    font-weight: 550;
    margin-bottom: 6px;
    // correcting the transform so top is always aligned with other buttons
    transform: translateY(6px);
    // baseline any space at the bottom, since it gets nullified by the transform

    & .button__internal {
      transition: transform 250ms;
      // this "raises" the button so it can be pressed
      transform: translateY(-6px);
      background: ${(props) => props.theme.colors.primary.one};
    }

    &:active .button__internal {
      // move it back down to its starting position on hover
      transform: translateY(-1px);
    }

    &:hover {
      background: #073cd4;
    }

    &:hover .button__internal {
      background: #1b55f8;
    }

    &:focus {
      box-shadow: 0 0 0 3px ${(props) => props.theme.colors.neutral[50]},
        0 0 0 5px ${(props) => props.theme.colors.primary.two};
    }

    &:focus:not(:focus-visible) {
      box-shadow: none !important;
    }

    &.disabled {
      & .button__internal {
        padding: 1.2rem 2rem !important;
      }
      margin-bottom: 0;
    }
  }

  &.secondary {
    background: transparent;
    color: ${(props) => props.theme.colors.primary.two};
    box-shadow: inset 0 0 0 2px ${(props) => props.theme.colors.primary.two};
    font-weight: 550;
    transition: box-shadow 250ms;

    &:hover {
      background: ${(props) => props.theme.colors.neutral[50]};
      box-shadow: inset 0 0 0 3px ${(props) => props.theme.colors.primary.two};
    }

    &:focus {
      background: ${(props) => props.theme.colors.neutral[50]};
      box-shadow: inset 0 0 0 2px ${(props) => props.theme.colors.primary.two},
        0 0 0 3px ${(props) => props.theme.colors.neutral[50]},
        0 0 0 5px ${(props) => props.theme.colors.primary.two};
    }

    &:focus:not(:focus-visible) {
      box-shadow: inset 0 0 0 2px ${(props) => props.theme.colors.primary.two};
    }
  }

  &.tertiary {
    background: ${(props) => props.theme.colors.primary.three};
    color: white;
    font-weight: 550;

    &:hover {
      background: ${(props) => lighten(0.08, props.theme.colors.primary.three)};
    }
  }

  &.danger {
    background: ${(props) => props.theme.colors.error[500]};
    color: white;
    font-weight: 550;
    transition: box-shadow 250ms;

    &:hover {
      box-shadow: inset 0 0 0 3px ${(props) => props.theme.colors.error[700]};
    }

    &:focus {
      box-shadow: 0 0 0 3px ${(props) => props.theme.colors.neutral[50]},
        0 0 0 5px ${(props) => props.theme.colors.error[700]};
    }

    &:focus:not(:focus-visible) {
      box-shadow: inset 0 0 0 2px ${(props) => props.theme.colors.primary.two};
    }
  }

  &.link {
    background: transparent;
    color: ${(props) => props.theme.colors.primary.one};
    font-weight: 550;

    &:hover {
      color: ${(props) => props.theme.colors.vivid[300]};
    }

    &:disabled {
      color: ${(props) => props.theme.colors.neutral[500]};

      & .button__internal {
        background-color: transparent !important;
      }
    }

    &:focus {
      box-shadow: 0 0 0 3px ${(props) => props.theme.colors.vivid[300]};
    }

    &:focus:not(:focus-visible) {
      box-shadow: none !important;
    }
  }

  &.answer {
    background: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.primary.two};
    box-shadow: inset 0 0 0 1px ${(props) => props.theme.colors.neutral[200]};
    font-weight: 500;

    &.base .button__internal {
      justify-content: space-between;
      padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.base}`};
    }

    &:hover {
      background: ${(props) => props.theme.colors.neutral[50]};
    }

    &:focus {
      box-shadow: 0 0 0 2px ${(props) => props.theme.colors.primary.one};
    }

    &:active {
      box-shadow: 0 0 0 2px ${(props) => props.theme.colors.neutral[50]},
        0 0 0 4px ${(props) => props.theme.colors.primary.one};
      background: ${(props) => props.theme.colors.primary.two};
      color: ${(props) => props.theme.colors.white};
      fill: #fff;
    }
  }

  // Size classes, change paddings and font size depending on prop

  &.base .button__internal {
    font-size: 1rem;
    padding: 1.2rem 2rem;
  }
  &.base.primary {
    & .button__internal {
      // This ensures the button isn't taller than any buttons near it
      // removing 3px to top and bottom makes it the same size as normal buttons when not pressed
      padding: calc(1.2rem - 0px) 2rem calc(1.2rem - 6px) 2rem;
    }
  }
  &.small .button__internal {
    font-size: 0.75rem;
    padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.md}`};
  }
  &.small.primary {
    & .button__internal {
      // This ensures the button isn't taller than any buttons near it
      // removing 3px to top and bottom makes it the same size as normal buttons when not pressed
      padding: ${({ theme }) =>
        `calc(${theme.spacing.sm} - 0px) ${theme.spacing.md} calc(${theme.spacing.sm} - 6px) ${theme.spacing.md}`};
    }
  }
`;
