import Typography from "@mojo/blueprint/Typography"
import { css } from "styled-components"

const BulletPoint = ({
  number,
  children,
}: {
  number: React.ReactNode
  children: React.ReactNode
}) => {
  return (
    <li
      css={css`
        display: flex;
        align-items: center;
        gap: ${({ theme }) => theme.spacing.base};
      `}
    >
      <div
        css={css`
          flex: none;
          display: flex;
          justify-content: center;
          align-self: flex-start;
          margin-top: 4px;
          color: white;
          background-color: ${({ theme }) => theme.colors.Mojo.vivid[500]};
          padding: ${({ theme }) => theme.spacing.xs};
          border-radius: ${({ theme }) => theme.radius.xl};
          aspect-ratio: 1/1;
          width: 32px;
        `}
      >
        {number}
      </div>
      <Typography.Body as="span">{children}</Typography.Body>
    </li>
  )
}

export default BulletPoint
