import Notice from "components/atoms/notice"
import Typography from "@mojo/blueprint/Typography"
import {Config} from "./types"
import NameAndTitle from "components/molecules/nameAndTitle"
import Address from "components/molecules/address"
import FeetAndInches from "components/molecules/feetAndInches"
import StoneAndPounds from "components/molecules/stoneAndPounds"
import Button from "@mojo/blueprint/Button"
import {ProtectionFactFind} from "@mojo/types/src/protection"
import dayjs from "dayjs"
import {Event, User} from "@mojo/analytics"
import {navigateToNextPage} from "utils/navigateToNextPage"
import React from "react";
import {OutOfHoursExperiment, OutOfHoursExperimentId} from "../Experiments";
import {GetExperimentState} from "@mojo/bucketing";

export const lifeInsuranceConfig: Config<ProtectionFactFind> = {
  onSubmit: async (data, brand, history) => {
    Event("Button Clicked", {
      action: "Button Clicked",
      event_category: "Functional Interaction",
      event_label: "Submit",
    })
    const correctedData = data

    if (correctedData.details?.dateOfBirth) {
      correctedData.details.dateOfBirth = dayjs(
        correctedData.details.dateOfBirth,
        "DD/MM/YYYY"
      ).toISOString()
    }

    if (correctedData.receiveInsights && correctedData.receiveInsights.length > 0) {
      // @ts-ignore
      correctedData.receiveInsights = true
    } else {
      // @ts-ignore
      correctedData.receiveInsights = false
    }

    // set the source of the lead, super hacky
    switch (brand) {
      default:
        // @ts-ignore
        correctedData.leadSource = null
        break
      case "Money":
        // @ts-ignore
        correctedData.leadSource = "money"
        break
      case "Uswitch":
        // @ts-ignore
        correctedData.leadSource = "uswitch"
        break
    }

    correctedData.experimentKeys = ["d2c_insurance_short_fact_find_control"];

    const outOfHoursExperiment = GetExperimentState<OutOfHoursExperiment>(OutOfHoursExperimentId);

    if (outOfHoursExperiment !== null) {
      correctedData.experimentKeys.push(outOfHoursExperiment === OutOfHoursExperiment.Calendly ? 'd2c_out_of_hours_variant' : 'd2c_out_of_hours_control');
    }

    try {
      const res = await fetch(`${import.meta.env.VITE_INSURANCE_API_URL}/funnel/create`, {
        method: "POST",
        body: JSON.stringify(correctedData),
        headers: {
          "Content-Type": "application/json",
        },
      })

      if (!res.ok) {
        const json = await res.json()
        throw new Error(JSON.stringify(json.message))
      }

      // response to json so we can access the friendlyId
      const json = (await res.json()) as {
        friendlyId: string
      }

      // then pass it to the user dimension
      User({
        friendlyId: json.friendlyId,
      })
    } catch (err) {
      throw new Error(err as string)
    }

    history.push(`/insurance-discovery/results/main?name=${data.details.firstName}`)
  },
  sections: [
    {
      onSectionStart: () => {
        Event("Journey Started", {
          action: "Journey Started",
          event_category: "Site Interaction",
          event_label: "Journey Started",
        })
        Event("Section Started", {
          action: "Section Started",
          event_category: "Site Interaction",
          event_label: "Insurance Intro",
        })
      },
      steps: [
        {
          identifier: "/",
          name: "content",
          type: "custom",
          render: (
            <>
              <Typography.H4>Let's find the right life insurance policy for you</Typography.H4>
              <Notice>
                Please answer all questions honestly as if you don't your policy may be cancelled or
                your claim rejected.
              </Notice>
              <Typography.Body>
                We require your contact details in order to provide you a quote specific to your
                needs and priorities.
              </Typography.Body>
            </>
          ),
          controls: {
            back: {
              hide: true,
            },
            next: {
              onClick: (_formData, history, basePath) => {
                navigateToNextPage(basePath + "/name", history)
              },
            },
          },
          onQuestionShown: (_step) => {
            Event("Question Shown", {
              action: "Question Shown",
              event_category: "Site Interaction",
              event_label: "Insurance intro",
            })
          },
          onQuestionAnswered: (_step) => {
            Event("Question Answered", {
              action: "Question Answered",
              event_category: "Site Interaction",
              event_label: "Insurance intro",
            })
          },
        },
      ],
    },
    {
      name: "About you",
      onSectionStart: () => {
        Event("Section Started", {
          action: "Section Started",
          event_category: "Site Interaction",
          event_label: "About you",
        })
      },
      onSectionEnd: () => {
        Event("Section Ended", {
          action: "Section Ended",
          event_category: "Site Interaction",
          event_label: "About you",
        })
      },
      steps: [
        {
          identifier: "/name",
          name: "content",
          type: "custom",
          label: "Name",
          render: <NameAndTitle />,
          controls: {
            next: {
              onClick: (_formData, history, basePath) => {
                navigateToNextPage(basePath + "/date-of-birth", history)
              },
            },
          },
        },
        {
          identifier: "/date-of-birth",
          label: "Date of birth",
          name: "details.dateOfBirth",
          type: "masked-input",
          rules: {
            required: {
              value: true,
              message: "This input is required",
            },
            validate: {
              overEighteen: (val: string) => {
                const dob = dayjs(val, "DD/MM/YYYY")
                const eighteenYearsAgo = dayjs().subtract(18, "year")
                const isOverEighteen = dob.isBefore(eighteenYearsAgo)

                return isOverEighteen || "You must be older than 18."
              },
            },
          },
          inputProps: {
            mask: "99/99/9999",
            placeholder: "DD/MM/YYYY",
          },
          controls: {
            next: {
              onClick: (_formData, history, basePath) => {
                navigateToNextPage(basePath + "/address", history)
              },
            },
          },
        },
        {
          identifier: "/address",
          name: "content",
          type: "custom",
          description: "This is to check you are a UK resident",
          render: (props) => <Address {...props} />,
          controls: {
            next: {
              onClick: (_formData, history, basePath) => {
                navigateToNextPage(basePath + "/phone-number", history)
              },
            },
          },
          onQuestionShown: (_step) => {
            Event("Question Shown", {
              action: "Question Shown",
              event_category: "Site Interaction",
              event_label: "Address",
            })
          },
          onQuestionAnswered: (_step) => {
            Event("Question Answered", {
              action: "Question Answered",
              event_category: "Site Interaction",
              event_label: "Address",
            })
          },
        },
        {
          identifier: "/phone-number",
          type: "text-input",
          label: "Phone number",
          name: "details.phoneNumber",
          tip: {
            children: "We’ll only contact you about your application",
          },
          rules: {
            required: {
              value: true,
              message: "This input is required",
            },
            pattern: {
              value: /^(0|\+?44)(\d{9,10})$/,
              message: "Please use a valid phone number.",
            },
          },
          inputProps: {
            type: "tel",
            autoComplete: "tel",
          },
          controls: {
            next: {
              onClick: (_formData, history, basePath) => {
                navigateToNextPage(basePath + "/email", history)
              },
            },
          },
        },
        {
          identifier: "/email",
          type: "text-input",
          label: "Email",
          description: "We require your email to provide you with a quote and send you updates.",
          name: "details.email",
          rules: {
            required: {
              value: true,
              message: "This input is required",
            },
            pattern: {
              value:
                /^(?!\.)[A-Za-z0-9.!#$%&'*+/=?^_`{|}~-]+@[A-Za-z0-9.!#$%&'*+=?^_`{|}~-](?!.*\.\.)[A-Za-z0-9.!#$%&'*+=?^_`{|}~-]{2,}$/,
              message: "Please use a valid email.",
            },
          },
          inputProps: {
            type: "email",
            autoComplete: "email",
          },
          controls: {
            next: {
              onClick: (_formData, history, basePath) => {
                navigateToNextPage(basePath + "/smoker", history)
              },
            },
          },
        },
      ],
    },
    {
      name: "Medical",
      onSectionStart: () => {
        Event("Section Started", {
          action: "Section Started",
          event_category: "Site Interaction",
          event_label: "Medical",
        })
      },
      onSectionEnd: () => {
        Event("Section Ended", {
          action: "Section Ended",
          event_category: "Site Interaction",
          event_label: "Medical",
        })
      },
      steps: [
        {
          identifier: "/smoker",
          type: "radio-button-input",
          asFieldSet: true,
          legend: "Have you smoked in the last 12 months or used nicotine replacement products?",
          description:
            "Examples include but not limited to: Cigarettes, E-cigarettes, Cigars, Pipes, Vapes (Inc 0% nicotine)",
          name: "details.smoker",
          rules: {
            required: {
              value: true,
              message: "This input is required",
            },
          },
          inputProps: [
            {
              value: "yes",
              children: <>Yes</>,
            },
            {
              value: "no",
              children: <>No</>,
            },
          ],
          controls: {
            next: {
              onClick: (_formData, history, basePath) => {
                navigateToNextPage(basePath + "/height", history)
              },
            },
          },
          onQuestionAnswered: (_step, formData) => {
            Event("Question Answered", {
              action: "Question Answered",
              event_category: "Site Interaction",
              event_label:
                "Have you smoked in the last 12 months or used nicotine replacement products?",
              field_value: formData.details.smoker,
            })
          },
        },
        {
          identifier: "/height",
          type: "radio-button-input",
          asFieldSet: true,
          legend: "What’s your height?",
          description: "Select how you measure your height",
          name: "details.height.unit",
          skippable: "Skip question",
          rules: {
            required: {
              value: true,
              message: "This input is required",
            },
          },
          inputProps: [
            {
              value: "centimeters",
              children: <>Centimeters</>,
            },
            {
              value: "feet-inches",
              children: <>Feet/inches</>,
            },
          ],
          extras: [
            {
              identifier: "height-cm",
              type: "text-input",
              name: "details.height.centimeters",
              label: "We need your height without shoes on",
              inputProps: {
                placeholder: "170",
                rightIcon: "cm",
              },
              rules: {
                required: {
                  value: true,
                  message: "This input is required",
                },
              },
              setValueAs: (val) => {
                const parsed = parseFloat(val)
                if (Number.isNaN(parsed)) {
                  return 0
                }
                return parsed
              },
              display: (formData) => {
                if (formData?.["details"]?.["height"]?.["unit"] === "centimeters") {
                  return true
                }
                return false
              },
            },
            {
              identifier: "height-feet",
              type: "custom",
              name: "details.height.feet",
              render: <FeetAndInches />,
              display: (formData) => {
                if (formData?.["details"]?.["height"]?.["unit"] === "feet-inches") {
                  return true
                }
                return false
              },
            },
            // this doesn't render anything, and it's a bit dirty
            // we have it here so `details.height.inches` is reset correctly
            // TODO: maybe find a better way to do this
            {
              identifier: "height-inches",
              type: "custom",
              name: "details.height.inches",
              display: (formData) => {
                if (formData?.["details"]?.["height"]?.["unit"] === "feet-inches") {
                  return true
                }
                return false
              },
            },
          ],
          controls: {
            next: {
              onClick: (_formData, history, basePath) => {
                navigateToNextPage(basePath + "/weight", history)
              },
            },
          },
        },
        {
          identifier: "/weight",
          type: "radio-button-input",
          asFieldSet: true,
          legend: "What’s your weight?",
          description: "Select how you measure your weight",
          name: "details.weight.unit",
          skippable: "Skip question",
          rules: {
            required: {
              value: true,
              message: "This input is required",
            },
          },
          inputProps: [
            {
              value: "kilograms",
              children: <>Kilograms</>,
            },
            {
              value: "stone-pounds",
              children: <>Stone/pounds</>,
            },
          ],
          extras: [
            {
              identifier: "weight-kg",
              type: "text-input",
              name: "details.weight.kilograms",
              label: "We need your weight including wearing clothes",
              inputProps: {
                placeholder: "70",
                rightIcon: "Kg",
              },
              rules: {
                required: {
                  value: true,
                  message: "This input is required",
                },
              },
              setValueAs: (val) => {
                const parsed = parseFloat(val)
                if (Number.isNaN(parsed)) {
                  return 0
                }
                return parsed
              },
              display: (formData) => {
                if (formData?.["details"]?.["weight"]?.["unit"] === "kilograms") {
                  return true
                }
                return false
              },
            },
            {
              identifier: "weight-stone",
              type: "custom",
              name: "details.weight.stone",
              render: <StoneAndPounds />,
              display: (formData) => {
                if (formData?.["details"]?.["weight"]?.["unit"] === "stone-pounds") {
                  return true
                }
                return false
              },
            },
            // this doesn't render anything, and it's a bit dirty
            // we have it here so `details.weight.lbs` is reset correctly
            // TODO: maybe find a better way to do this
            {
              identifier: "weight-lbs",
              type: "custom",
              name: "details.weight.lbs",
              display: (formData) => {
                if (formData?.["details"]?.["weight"]?.["unit"] === "stone-pounds") {
                  return true
                }
                return false
              },
            },
          ],
          controls: {
            next: {
              onClick: (_formData, history, basePath) => {
                navigateToNextPage(basePath + "/insurance-type", history)
              },
            },
          },
        },
      ],
    },
    {
      name: "Policy details",
      onSectionStart: () => {
        Event("Section Started", {
          action: "Section Started",
          event_category: "Site Interaction",
          event_label: "Policy Details",
        })
      },
      onSectionEnd: () => {
        Event("Section Ended", {
          action: "Section Ended",
          event_category: "Site Interaction",
          event_label: "Policy Details",
        })
      },
      steps: [
        {
          identifier: "/insurance-type",
          type: "radio-button-input",
          asFieldSet: true,
          name: "insuranceType",
          rules: {
            required: {
              value: true,
              message: "This input is required",
            },
          },
          legend: "What do you need the insurance to protect?",
          inputProps: [
            {
              value: "mortgage",
              children: <>My mortgage</>,
            },
            {
              value: "family",
              children: <>My family</>,
            },
            {
              value: "family-and-mortgage",
              children: <>Both my family and mortgage</>,
            },
          ],
          controls: {
            next: {
              onClick: (_formData, history, basePath) => {
                navigateToNextPage(basePath + "/cover-amount", history)
              },
            },
          },
          onQuestionAnswered: (_step, formData) => {
            Event("Question Answered", {
              action: "Question Answered",
              event_category: "Site Interaction",
              event_label: "What do you need the insurance to protect?",
              field_value: formData.insuranceType,
            })
          },
        },
        {
          identifier: "/cover-amount",
          type: "masked-input",
          label: "How much cover do you need?",
          name: "coverAmount",
          rules: {
            required: {
              value: true,
              message: "This input is required",
            },
            validate: {
              notOverMax: (val: string) =>
                parseInt(val) <= 1000000 || "Cover amount can't be over £1,000,000",
            },
          },
          setValueAs: (value) => {
            return value.replaceAll(",", "")
          },
          tip: {
            children: (
              <>
                <Typography.Body as="span">
                  Think about how much you would like to leave to your family or how much of your
                  mortgage is outstanding. If you’re not sure our experts can help you decide on the
                  amount of cover you need.
                </Typography.Body>
              </>
            ),
          },
          inputProps: {
            leftIcon: "£",
            mask: "99999999999",
            beforeMaskedStateChange: ({ nextState, previousState }) => {
              const nx = nextState
              let { value } = nx

              // depending on how you're saving the value
              // we ensure we clean it before passing it to format
              value = value.replaceAll(",", "")
              if (value === "") {
                return {
                  ...nx,
                  value: "",
                }
              }

              value = parseInt(value).toLocaleString("en-EN")

              // handle commas being added between numbers
              if (previousState && value.length - previousState.value.length > 1) {
                nx.selection.start = nx.selection.start + 1
                nx.selection.end = nx.selection.end + 1
              }

              return {
                ...nx,
                value,
              }
            },
          },
          skippable: `I'm not sure`,
          controls: {
            next: {
              onClick: (_formData, history, basePath) => {
                navigateToNextPage(basePath + "/policy-length", history)
              },
            },
          },
          onQuestionAnswered: (_step, formData) => {
            Event("Question Answered", {
              action: "Question Answered",
              event_category: "Site Interaction",
              event_label: "How much cover do you need?",
              field_value: formData.coverAmount,
            })
          },
        },
        {
          identifier: "/policy-length",
          type: "stepper-input",
          label: "How many years do you need your policy to last?",
          name: "policyLength",
          rules: {
            required: {
              value: true,
              message: "This input is required",
            },
            validate: {
              notOverMax: (val: string) =>
                parseInt(val) <= 40 || "Policy length has to be less than 40 years.",
              notUnderMin: (val: string) =>
                parseInt(val) >= 20 || "Policy length has to be more than 20 years.",
            },
          },
          setValueAs: (value) => {
            return parseInt(value)
          },
          tip: {
            children: (
              <>
                <Typography.Body as="span">
                  Think about how long your mortgage term is, when you are likely to retire and how
                  long your children will be dependent on you.
                </Typography.Body>
              </>
            ),
          },
          inputProps: {
            minimum: 20,
            defaultValue: 25,
            maximum: 40,
            allowEditing: true,
          },
          skippable: "I'm not sure",
          controls: {
            next: {
              onClick: (_formData, history, basePath) => {
                navigateToNextPage(basePath + "/cover-for", history)
              },
            },
          },
          onQuestionAnswered: (_step, formData) => {
            Event("Question Answered", {
              action: "Question Answered",
              event_category: "Site Interaction",
              event_label: "How many years do you need your policy to last?",
              field_value: formData.policyLength,
            })
          },
        },
        {
          identifier: "/cover-for",
          type: "radio-button-input",
          asFieldSet: true,
          legend: "Who is the cover for?",
          name: "coverFor",
          rules: {
            required: {
              value: true,
              message: "This input is required",
            },
          },
          inputProps: [
            {
              value: "just-you",
              children: <>Just you</>,
            },
            {
              value: "you-and-someone-else",
              children: <>You and your partner</>,
            },
          ],
          controls: {
            next: {
              onClick: (_formData, history, basePath) => {
                navigateToNextPage(basePath + "/benefits", history)
              },
            },
          },
          onQuestionAnswered: (_step, formData) => {
            Event("Question Answered", {
              action: "Question Answered",
              event_category: "Site Interaction",
              event_label: "Who is the cover for?",
              field_value: formData.coverFor,
            })
          },
        },
        {
          identifier: "/benefits",
          type: "radio-button-input",
          asFieldSet: true,
          legend: "What type of benefit would you like?",
          name: "benefits",
          rules: {
            required: {
              value: true,
              message: "This input is required",
            },
          },
          inputProps: (_formData) => [
            {
              value: "level",
              children: (
                <Typography.Body
                  as="span"
                  weight={600}
                  style={{
                    lineHeight: "inherit",
                  }}
                >
                  Level
                </Typography.Body>
              ),
              subText: (
                <Typography.SmallBody
                  color="neutral"
                  as="span"
                  style={{
                    lineHeight: "inherit",
                  }}
                >
                  Level term means the amount of cover remains the same over the term of the policy.
                </Typography.SmallBody>
              ),
            },
            {
              value: "decreasing",
              children: (
                <Typography.Body
                  as="span"
                  weight={600}
                  style={{
                    lineHeight: "inherit",
                  }}
                >
                  Decreasing
                </Typography.Body>
              ),
              subText: (
                <Typography.SmallBody
                  color="neutral"
                  as="span"
                  style={{
                    lineHeight: "inherit",
                  }}
                >
                  Decreasing term means the amount of cover decreases over the term of the policy,
                  for example in line with the outstanding balance on a mortgage.
                </Typography.SmallBody>
              ),
            },
          ],
          controls: {
            next: {
              onClick: (_formData, history, basePath) => {
                navigateToNextPage(basePath + "/confirm", history)
              },
            },
          },
          onQuestionAnswered: (_step, formData) => {
            Event("Question Answered", {
              action: "Question Answered",
              event_category: "Site Interaction",
              event_label: "What type of benefit would you like?",
              field_value: formData.benefits,
            })
          },
        },
      ],
    },
    {
      steps: [
        {
          identifier: "/confirm",
          name: "termsOfService",
          type: "checkbox-input",
          label: "Let's find the right life insurance policy for you",
          rules: {
            required: {
              value: true,
              message: "This input is required",
            },
          },
          inputProps: [
            {
              value: "confirmed",
              children: (
                <>
                  I agree to Mojo's{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://hosted-assets.mojomortgages.com/documents/Terms%20of%20our%20Advisory%20Services.pdf"
                  >
                    Terms of Our Advisory Services
                  </a>{" "}
                  and{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://mojomortgages.com/privacy-policy/"
                  >
                    Privacy Policy
                  </a>
                </>
              ),
            },
          ],
          extras: [
            {
              identifier: "health-processing",
              name: "healthProcessing",
              type: "checkbox-input",
              hideLabel: true,
              rules: {
                required: {
                  value: true,
                  message: "This input is required",
                },
              },
              inputProps: [
                {
                  value: "yes",
                  children: (
                    <>
                      I consent to Mojo processing my health information to provide me with life
                      insurance quotes
                    </>
                  ),
                },
              ],
            },
            {
              identifier: "insights",
              name: "receiveInsights",
              type: "checkbox-input",
              hideLabel: true,
              inputProps: [
                {
                  value: "yes",
                  children: (
                    <>
                      Tick here to receive Mojo insights about related products and services.
                      Unsubscribe anytime.
                    </>
                  ),
                },
              ],
            },
          ],
          controls: {
            next: {
              render: (formData, brand, ffContext) => (
                <Button brand={brand} isLoading={ffContext.isMutating} type="submit">
                  Submit
                </Button>
              ),
            },
          },
        },
      ],
    },
  ],
}
