import React from "react";

type TemplateContextProps = {
  navHeight: number;
  mainHeight: number;
  setNavHeight: React.Dispatch<
    React.SetStateAction<TemplateContextProps["navHeight"]>
  >;
  setMainHeight: React.Dispatch<
    React.SetStateAction<TemplateContextProps["mainHeight"]>
  >;
};

export const TemplateContext = React.createContext({} as TemplateContextProps);
