export interface Size {
  /**
   * 4px / 0.25rem
   */
  xxs: string;
  /**
   * 8px / 0.5rem
   */
  xs: string;
  /**
   * 12px / 0.75rem
   */
  sm: string;
  /**
   * 16px / 1rem
   */
  base: string;
  /**
   * 24px / 1.5rem
   */
  md: string;
  /**
   * 32px / 2rem
   */
  lg: string;
  /**
   * 40px / 2.5rem
   */
  xl: string;
}

export const size: Size = {
  xxs: "0.25rem",
  xs: "0.5rem",
  sm: "0.75rem",
  base: "1rem",
  md: "1.5rem",
  lg: "2rem",
  xl: "2.5rem",
};
