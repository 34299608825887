import { css } from "styled-components";
import CheckIcon from "../assets/money/check-inactive.svg";
import CheckIconHover from "../assets/money/check-hover.svg";
import CheckIconActive from "../assets/money/check-active.svg";

export const moneyCheckboxStyles = css`
  & {
    position: relative;
  }

  & input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;

    &:checked {
      + label {
        background: ${(props) => props.theme.colors.white};
        box-shadow: 0 0 0 3px
          ${({ theme }) => theme.colors.Money.brand.primary.plum[100]};
        font-weight: 500;

        .check__checkmark {
          &:after {
            background-image: url("${CheckIconActive}");
          }
        }
      }

      &:focus + label {
        box-shadow: 0 0 0 3px
            ${({ theme }) => theme.colors.Money.brand.primary.plum[100]},
          0 0 0 5px #fea500;
      }
    }

    &:focus + label {
      box-shadow: 0 0 0 2px #fea500;
    }

    &:disabled + label {
      background-color: ${(props) => props.theme.colors.white};
      opacity: 0.5;
    }
  }

  & label {
    display: flex;
    position: relative;
    padding: 8px;
    line-height: 180%;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.Money.gray[60]};
    height: 100%;
    border-radius: ${(props) => props.theme.radius.xxs};

    &:hover {
      box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.Money.gray[60]};
      color: ${({ theme }) => theme.colors.black};

      & .check__checkmark {
        &:after {
          background-image: url("${CheckIconHover}");
        }
      }
    }
  }

  & .check__checkmark {
    width: 22px;
    height: 22px;
    margin-right: 8px;
    position: relative;
    flex-shrink: 0;

    &:after {
      content: "";
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background-image: url("${CheckIcon}");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  & .check__text {
    font-size: 16px;
    font-weight: 500;
  }
`;
