import { css } from "styled-components";
import CheckIcon from "../assets/uswitch/check-inactive.svg";
import CheckIconActive from "../assets/uswitch/check-active.svg";

export const uswitchCheckButtonStyles = css`
  & input {
    &:checked {
      + label {
        background: ${(props) => props.theme.colors.white};
        box-shadow: 0 0 0 2px rgb(20, 20, 36);
        font-weight: 500;

        .check__checkmark {
          &:after {
            background-image: url("${CheckIconActive}");
          }
        }
      }

      &:focus + label {
        box-shadow: 0 0 0 2px rgb(20, 20, 36), 0 0 0 4px #fea500;
      }
    }

    &:focus + label {
      box-shadow: 0 0 0 2px #fea500;
    }

    &:disabled + label {
      background-color: ${(props) => props.theme.colors.white};
      opacity: 0.5;
    }
  }

  & label {
    padding: 16px;
    background: ${(props) => props.theme.colors.white};
    box-shadow: 0 0 0 1px rgb(208, 208, 211);
    color: rgb(67, 67, 80);

    &:hover {
      background: rgb(243, 243, 244);
    }
  }

  & .check__checkmark {
    width: 22px;
    height: 22px;
    margin-right: 8px;
    position: relative;
    flex-shrink: 0;

    &:after {
      content: "";
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background-image: url("${CheckIcon}");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  & .check__text {
    font-size: 16px;
    font-weight: 500;
  }
`;
