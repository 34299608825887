import { css } from "styled-components";

export const mojoComboboxButtonStyles = css`
  & .combobox__button {
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.neutral[200]};
    border-radius: ${({ theme }) => theme.radius.xs};
    color: ${({ theme }) => theme.colors.primary.two};

    &:disabled {
      background-color: ${({ theme }) => theme.colors.neutral[50]};
      cursor: not-allowed;
    }

    &:focus {
      box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primary.one};
      outline: none;
    }
  }

  & .combobox__placeholder {
    color: ${({ theme }) => theme.colors.neutral[500]};
  }

  & .combobox__pill {
    background-color: ${({ theme }) => theme.colors.neutral[50]};
    color: ${({ theme }) => theme.colors.primary.two};
    border-radius: ${({ theme }) => theme.radius.lg};
  }
`;

export const mojoComboboxPopoverStyles = css`
  border: 1px solid ${({ theme }) => theme.colors.neutral[200]};
  background-color: ${({ theme }) => theme.colors.white};

  border-radius: ${({ theme }) => theme.radius.xs};
  overflow: hidden;

  & .combobox__commandinput {
    border: none;

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  & [cmdk-item]:not(:first-child),
  & [cmdk-list] {
    border-top: 1px solid ${({ theme }) => theme.colors.neutral[200]};
  }

  & .combobox__commanditem[aria-selected="true"] {
    color: ${({ theme }) => theme.colors.primary.two};
    background-color: ${({ theme }) => theme.colors.neutral[50]};
  }
`;
