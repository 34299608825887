import { css } from "styled-components";

// TODO: finish styling once we have designs
export const moneyBannerStyles = css`
& {
  background: ${(props) => props.theme.colors.Money.gray[0]};
  padding:
    ${(props) => props.theme.spacing.md}
    ${(props) => props.theme.spacing.md}
    ${(props) => props.theme.spacing.md}
    ${(props) => props.theme.spacing.base};
  border-radius: ${(props) => props.theme.radius.base};
  border: 3px solid ${(props) => props.theme.colors.Money.brand.primary.sunshine[100]};
  border-left: 9px solid ${(props) => props.theme.colors.Money.brand.primary.sunshine[100]};

  .banner__leftIcon {
    color: ${(props) => props.theme.colors.Money.brand.primary.sunshine[100]};
  }
}
`;