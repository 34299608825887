import { useController, useFormContext } from "react-hook-form";
import { useFormItem } from "../../Form";
import Stepper, { StepperProps } from "../Stepper";

export interface ControlledInputProps extends StepperProps {}

const StepperControlled = ({ ...rest }: ControlledInputProps) => {
  const form = useFormContext();
  const { name, rules, setValueAs } = useFormItem();

  const { field, fieldState } = useController({
    name: name,
    control: form.control,
    rules,
    defaultValue: parseInt(rest.defaultValue as string) || rest.minimum,
  });

  return (
    <Stepper
      id={field.name}
      {...rest}
      {...field}
      value={parseInt(field.value)}
      onDecrement={(value) => {
        field.onChange(setValueAs(value));
      }}
      onIncrement={(value) => {
        field.onChange(setValueAs(value));
      }}
      onChange={(e) => {
        field.onChange(setValueAs(e.target.value));
      }}
      className={`${fieldState.invalid ? "invalid" : ""}`}
    />
  );
};

export default StepperControlled;
