import React from "react"
import styled from "styled-components"

export type MojoTipProps = {
  children: React.ReactNode
}

/**
 * Renders a mojo-styled tip element with some useful information
 */
const MojoTip = ({ children }: MojoTipProps) => {
  return (
    <StyledMojoTip>
      {/* <div className="mojoTip__icon">
        <Lightbulb width={24} height={24} />
      </div> */}
      <div className="mojoTip__content">{children}</div>
    </StyledMojoTip>
  )
}

const StyledMojoTip = styled.div`
  background: #fbbb5b;
  padding: ${({ theme }) => theme.spacing.md};
  border-radius: ${({ theme }) => theme.spacing.xs};
  margin-bottom: ${({ theme }) => theme.spacing.lg};

  display: flex;
  gap: ${({ theme }) => theme.spacing.sm};

  .mojoTip__icon {
    color: ${({ theme }) => theme.colors.Mojo.primary.one};
  }

  .mojoTip__content {
    display: flex;
    flex-direction: column;
  }
`

export default MojoTip
