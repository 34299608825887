import { css } from "styled-components";

// TODO: should we have a Link component in Typography
export const mojoCookieBannerStyles = css`
  a {
    color: blue;
    text-decoration: underline;
    transition: color 0.3s ease;

    &:hover { color: purple; }
    &:active { color: purple; }
    &:visited { color: purple; }
  }
`;
