import styled from "styled-components"

const Circle = styled.div<{
  $size: number
  $loaderThickness: number
}>`
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  display: block;

  &::before {
    color: red;
    border-top-color: ${({ theme }) => theme.colors.Mojo.neutral[0]};
    border-right-color: ${({ theme }) => theme.colors.Mojo.neutral[800]};
  }

  ::after {
    /* color: red; */
    border-bottom-color: ${({ theme }) => theme.colors.Mojo.neutral[100]};
    border-left-color: ${({ theme }) => theme.colors.Mojo.neutral[800]};
  }

  ::before {
    content: "";
    position: absolute;
    width: ${({ $size }) => $size}px;
    height: ${({ $size }) => $size}px;
    border-width: ${({ $loaderThickness }) => $loaderThickness}px;
    border-style: solid;
    border-radius: 50%;
    animation: rotate 1.3s infinite linear;
  }

  ::after {
    content: "";
    position: absolute;
    width: ${({ $size }) => $size}px;
    height: ${({ $size }) => $size}px;
    border-width: ${({ $loaderThickness }) => $loaderThickness}px;
    border-style: solid;
    border-radius: 50%;
    animation: rotate 1.3s infinite linear;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`

const Loader = ({ size = 28 }) => {
  const loaderThickness = (0.5 * size) / 3

  return <Circle $size={size} $loaderThickness={loaderThickness} />
}

export default Loader
