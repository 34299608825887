import { Size, size } from "./size";

export interface Shadows {
  /**
   * box-shadow: 0px 4px 4px 0px rgba(0,9,40,0.08);
   */
  base: string;
  /**
   * box-shadow: 0px 8px 8px 0px rgba(0,9,40,0.08);
   */
  md: string;
  /**
   * box-shadow: 0px 16px 16px 0px rgba(0,9,40,0.08);
   */
  lg: string;
}

export const shadows: Shadows = {
  base: "box-shadow: 0px 4px 4px 0px rgba(0,9,40,0.08);",
  md: "box-shadow: 0px 8px 8px 0px rgba(0,9,40,0.08);",
  lg: "box-shadow: 0px 16px 16px 0px rgba(0,9,40,0.08);",
};
