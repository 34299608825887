import { User } from "@mojo/analytics"
import { ProtectionFactFind } from "@mojo/types/src/protection"
import { axiosInstance } from "@mojo/utils"
import { useBrand } from "components/atoms/brandContext"
import useSWRMutation from "swr/mutation"

const post = (url: string, body: ProtectionFactFind) =>
  axiosInstance.post<{ friendlyId: string }>(url, body)

export const usePostLifeInsuranceDataShort = () => {
  const { brand } = useBrand()

  let leadSource: null | string = null

  // set the source of the lead, super hacky
  switch (brand) {
    default:
      leadSource = null
      break
    case "Money":
      leadSource = "money"
      break
    case "Uswitch":
      leadSource = "uswitch"
      break
  }

  return useSWRMutation<
    any,
    any,
    any,
    Pick<ProtectionFactFind, "details">["details"]["phoneNumber"]
  >(
    `${import.meta.env.VITE_INSURANCE_API_URL}/funnel/create/short`,
    async (
      url: string,
      { arg }: { arg: Pick<ProtectionFactFind, "details">["details"]["phoneNumber"] }
    ) => {
      const res = await post(url, {
        experimentKeys: ["d2c_insurance_short_fact_find_variant"],
        details: {
          address: null,
          dateOfBirth: null,
          email: null,
          firstName: null,
          height: null,
          lastName: null,
          phoneNumber: arg,
          smoker: null,
          title: null,
          weight: null,
        },
        benefits: null,
        coverFor: null,
        insuranceType: null,
        receiveInsights: null,
        analytics: {
          utm_term: new URLSearchParams(window.location.search).get("utm_term") ?? undefined,
        },
        coverAmount: undefined,
        policyLength: undefined,
        leadSource,
      } as ProtectionFactFind)

      // send generated friendlyId to analytics
      User({
        friendlyId: res.data.friendlyId,
      })
    }
  )
}
