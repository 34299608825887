import React from "react";
import CheckButton, { CheckProps } from "../CheckButton";

export type CheckboxProps = CheckProps;

/**
 * A styled checkbox component.
 */
const Checkbox = React.forwardRef<
  HTMLInputElement & {
    Controlled: JSX.Element;
  },
  CheckProps
>((props: CheckProps, ref: any) => {
  return <CheckButton {...props} ref={ref} asCheckButton={false} />;
});

export default Checkbox;
