import React from "react";
import styled from "styled-components";

import Back from "../assets/images/icons/mojo/Back.svg";
import Cross from "../assets/images/icons/mojo/Cross.svg";
import Locked from "../assets/images/icons/mojo/Locked.svg";
import Menu from "../assets/images/icons/mojo/Menu.svg";
import Shield from "../assets/images/icons/mojo/Shield.svg";
import QuestionMark from "../assets/images/icons/mojo/question-mark.svg";
import ChevronDown from "../assets/images/icons/mojo/chevron-down.svg";
import ChevronUp from "../assets/images/icons/mojo/chevron-up.svg";
import ChevronLeft from "../assets/images/icons/mojo/chevron-left.svg";
import ChevronRight from "../assets/images/icons/mojo/chevron-right.svg";

const images: {
  [key: string]: string;
} = {
  Back,
  Cross,
  Locked,
  Menu,
  Shield,
  QuestionMark,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
};

export enum IconType {
  Back,
  Cross,
  Locked,
  Menu,
  Shield,
  QuestionMark,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
}

interface Props {
  type: IconType;
  onClick?: () => void;
}

export interface IconComponentProps {
  type: IconType;
  onClick?: () => void;
  active?: boolean;
  fillDefault?: string;
  fillHover?: string;
  width?: number;
  height?: number;
}

const IconComponent = styled.div<{
  $width?: number;
  $height?: number;
  $icon?: string;
  $fillDefault?: string;
  $fillHover?: string;
  $active?: boolean;
}>`
  display: inline-block;
  width: ${({ $width }) => $width}px;
  height: ${({ $height }) => $height}px;
  mask: url(${({ $icon }) => $icon}) no-repeat center / contain;
  -webkit-mask: url(${({ $icon }) => $icon}) no-repeat center / contain;
  background-color: ${({ $fillDefault, $active, $fillHover }) =>
    $active ? $fillHover : $fillDefault};
  &:hover {
    background-color: ${({ $fillHover }) => $fillHover};
  }
`;

export function IconStyleable({
  type,
  onClick,
  fillDefault,
  fillHover,
  width,
  height,
  active,
}: IconComponentProps) {
  const icon = IconType[type];
  return (
    <IconComponent
      onClick={onClick && onClick}
      $active={active}
      $icon={images[icon]}
      $fillDefault={fillDefault}
      $fillHover={fillHover}
      $width={width}
      $height={height}
    />
  );
}