import React from "react";
import styled, { createGlobalStyle, css } from "styled-components";
import { TemplateContext } from "./context";
import { BasicNav } from "./Nav/Basic";
import { Grid } from "./Grid";
import { Main } from "./Main";
import { BrandProp, Brands } from "@mojo/types";
import { getBrand } from "../Theme";

export type TemplateProps = {
  children: React.ReactNode;
  variant?: "gray" | "orange" | "yellow-wave" | "gray-wave";
} & React.HTMLAttributes<HTMLDivElement> &
  BrandProp;

/**
 * Template wraps all other templating components. It sets context,
 * changes background colors and puts things in the correct order.
 */
const Template = ({
  children,
  brand = "Mojo",
  variant = "gray",
  ...props
}: TemplateProps) => {
  const [navHeight, setNavHeight] = React.useState(0);
  const [mainHeight, setMainHeight] = React.useState(0);

  return (
    <TemplateContext.Provider
      value={{ navHeight, setNavHeight, mainHeight, setMainHeight }}
    >
      <StyledGlobalStyle $brand={brand} $variant={variant} />
      <StyledTemplate $variant={variant} {...props}>
        {children}
      </StyledTemplate>
    </TemplateContext.Provider>
  );
};

const StyledTemplate = styled.div<{
  $variant: Required<TemplateProps["variant"]>;
}>`
  display: flex;
  flex-direction: column;
`;

const BrandWhiteBackgroundVariantStyles = (brand: Brands) =>
  getBrand(brand, {
    Mojo: css`
      background-color: #f5f7fb;
    `,
    Uswitch: css`
      background-color: ${({ theme }) => theme.colors.white};
    `,
    Money: css`
      background-color: ${({ theme }) => theme.colors.white};
    `,
  });

const handleBackground = (
  variant: Required<TemplateProps["variant"]>,
  $brand: Brands
) => {
  switch (variant) {
    default:
    case "gray":
      return css`
        ${BrandWhiteBackgroundVariantStyles($brand)};
      `;
    case "orange":
      return css`
        background-color: #ffe2d0;
      `;
    case "gray-wave":
      return css``;
    case "yellow-wave":
      return css``;
  }
};

const StyledGlobalStyle = createGlobalStyle<{
  $variant: Required<TemplateProps["variant"]>;
  $brand: Brands;
}>`
  body {
    ${({ $variant, $brand }) => handleBackground($variant, $brand)}
  }
`;

export default Template;
export { BasicNav, Grid, Main };
