import React, { createContext } from "react";
import { AccordionProps } from "./Accordion";

type state = {
  open: boolean;
  id: string;
};

export interface AccordionContextProps {
  contextState: [state[], React.Dispatch<React.SetStateAction<state[]>>];
  onOpen?: AccordionProps["onOpen"];
}

export const AccordionContext = createContext({} as AccordionContextProps);

export const useAccordion = () => {
  const {
    contextState: [state, setState],
    onOpen,
  } = React.useContext(AccordionContext);

  /**
   * Returns whether an accordion item is open or closed
   * @param id the id of the accordion item
   * @returns {boolean}
   */
  const isOpen = (id: string) => {
    const item = state.find((s) => s.id === id);
    return item?.open || false;
  };

  /**
   * Opens the specified accordion item and closes the other ones
   * @param id the id of the accordion item
   */
  const openAccordionItem = (id: string) => {
    const o = { id, open: true };
    setState(state.map((s) => (s.id === id ? o : { ...s, open: false })));
  };

  /**
   * Closes the specified accordion item
   * @param id the id of the accordion item
   */
  const closeAccordionItem = (id: string) => {
    const o = { id, open: false };
    setState(state.map((s) => (s.id === id ? o : { ...s, open: false })));
  };

  return {
    state,
    isOpen,
    openAccordionItem,
    closeAccordionItem,
    onOpen,
  };
};
