import { css } from "styled-components";

export const uswitchComboboxButtonStyles = css`
  & .combobox__button {
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0 0 0 1px #a1a1a7;
    color: ${({ theme }) => theme.colors.Uswitch.gray[80]};

    &:disabled {
      background-color: ${({ theme }) => theme.colors.Uswitch.gray[5]};
      cursor: not-allowed;
    }

    &:focus {
      box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.Uswitch.gray[100]},
        0 0 0 4px #fec340;
      outline: none;
    }
  }

  & .combobox__placeholder {
    color: ${({ theme }) => theme.colors.neutral[500]};
  }

  & .combobox__pill {
    background-color: #f3f3f4;
    color: #72727c;
    border-radius: ${({ theme }) => theme.radius.xxs};
  }
`;

export const uswitchComboboxPopoverStyles = css`
  border: 1px solid #a1a1a7;
  background-color: ${({ theme }) => theme.colors.white};
  overflow: hidden;

  & .combobox__commandinput {
    border: none;

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  & [cmdk-item]:not(:first-child),
  & [cmdk-list] {
    border-top: 1px solid ${({ theme }) => theme.colors.Uswitch.gray[20]};
  }

  & .combobox__commanditem[aria-selected="true"] {
    background-color: ${({ theme }) => theme.colors.Uswitch.gray[10]};
  }
`;
