import { css } from "styled-components";

export const moneyCookieBannerStyles = css`
  a {
    color: ${({ theme }) => theme.colors.black};
    text-decoration: underline;
    transition: color 0.3s ease;
  }

  a:hover {
    color: purple;
  }

  a:active {
    color: purple;
  }

  a:visited {
    color: purple;
  }
`;
