import { css } from "styled-components";

export const uswitchStepButtonStyles = css`
  & {
    color: ${(props) => props.theme.colors.Uswitch.gray[0]};
    background: ${(props) => props.theme.colors.Uswitch.gray[100]};
    &:disabled {
      background: ${(props) => props.theme.colors.Uswitch.gray[70]};
      cursor: not-allowed;
    }
    &:focus {
      outline: 2px solid ${(props) => props.theme.colors.Uswitch.brand.orange.primary};
    }
  }
`
