import { BrandProp, Brands } from "@mojo/types";
import styled from "styled-components";
import { getBrand } from "../Theme";
import { confusedChainedListStyles } from "./styles/confused";
import { mojoChainedListStyles } from "./styles/mojo";
import { moneyChainedListStyles } from "./styles/money";
import { uswitchChainedListStyles } from "./styles/uswitch";
import React from "react";

export const BrandVariantStyles = (brand: Brands) =>
  getBrand(brand, {
    Mojo: mojoChainedListStyles,
    Uswitch: uswitchChainedListStyles,
    Money: moneyChainedListStyles,
    Confused: confusedChainedListStyles,
  });

const List = styled.ul<{
  $brand: Brands;
}>`
  list-style: none;

  ${({ $brand }) => BrandVariantStyles($brand)};
`;

const Bullet = styled.span<{
  content: string;
}>`
  &:after {
    left: -36px;
    top: -2px;
    margin: auto;
    position: absolute;
    content: "${({ content }) => content}";
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 27px;
    font-weight: 600;
  }

  &:before {
    left: -36px;
    top: -2px;
    margin: auto;
    position: absolute;
    content: "";
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
`;

const ListItem = styled.li`
  position: relative;
  text-align: left;
  margin-bottom: 0;

  &:before {
    display: block;
    content: "";
    width: 2px;
    height: calc(100% + 20px);
    position: absolute;
    left: -25px;
    top: 20px;
    border-radius: 20px;
  }

  &:last-child:before {
    display: none;
  }
`;

const Title = styled.h2`
  font-size: 16px;
  font-weight: 550;
  line-height: 24px;
  margin: 0;
`;

const Text = styled.p`
  font-size: 12px;
  line-height: 18px;
  min-height: 3em;
  margin: 0 0 2rem;
`;

type ChainedListData = {
  /**
   * The title used for each item in the list
   */
  title: string;
  /**
   * The text contained for each item in the list
   */
  description: string;
};

export type ChainedListProps = {
  /**
   * Data used to populate chained list
   */
  data: ChainedListData[];
} & BrandProp &
  React.HTMLAttributes<HTMLUListElement>;

/**
 * A list with each bullet point from each item chained together in a line, and each bullet point numbered
 */
export const ChainedList = ({
  brand = "Mojo",
  data,
  ...rest
}: ChainedListProps) => {
  return (
    <List $brand={brand} {...rest}>
      {data.map(({ title, description }, i) => {
        return (
          <ListItem key={i} className="bullet__item">
            <Bullet content={`${i + 1}`} className="bullet__point" />
            <div className="bullet__content">
              <Title className="bullet__title">{title}</Title>
              <Text className="bullet__text">{description}</Text>
            </div>
          </ListItem>
        );
      })}
    </List>
  );
};

export default ChainedList;
